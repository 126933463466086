import React, { Component } from 'react';
import { Grid } from 'semantic-ui-react';
import { ProductionRequest } from 'store/ProductionRequest';
import PlannedWeekTag from './PlannedWeekTag';
import styled from 'styled-components';
import { formatDuration, SHORT_DATETIME_FORMAT } from '../../helpers';
import { Moment } from 'moment';
import { t } from '../../i18n';
import { Caption1 } from '../PerformanceStyles';

interface PlannedOnInformationProps {
    productionRequest: ProductionRequest;
}

const PlannedOnInformationContainer = styled.div`
    width: 240px;
`;

const RightAlignTextBox = styled.span`
    right:14px;
    position: absolute;
`;


export default class PlannedOnInformation extends Component<PlannedOnInformationProps> {
    render() {
        const { productionRequest } = this.props;

        // @ts-ignore
        const actualStartedAt: Moment | null = productionRequest.startedAt;
        const plannedStartedAt: Moment | null = productionRequest.startAt;


        const erpPlannedEndDate = productionRequest.erpPlannedEndDate;

        return (
            <PlannedOnInformationContainer>
                <Grid centered columns={2}>
                    <Grid.Column width = {5}>
                        <PlannedWeekTag
                            productionRequest={productionRequest}
                        />
                    </Grid.Column>
                    <Grid.Column width={10}>
                                <Caption1>
                                Start:
                                <RightAlignTextBox data-test-start-at>
                                    {// @ts-ignore
                                    plannedStartedAt?.format(SHORT_DATETIME_FORMAT) || '-'
                                    }
                                </RightAlignTextBox>
                                </Caption1>
                                <Caption1>
                                    Actual:
                                <RightAlignTextBox>
                                    {// @ts-ignore
                                    actualStartedAt?.format(SHORT_DATETIME_FORMAT) || t('productionRequest.notStartedYet')
                                    }
                                </RightAlignTextBox>
                                </Caption1>
                                <Caption1>
                                {t('productionRequest.erpEnd')}:
                                <RightAlignTextBox>
                                    {// @ts-ignore
                                    erpPlannedEndDate?.format(SHORT_DATETIME_FORMAT) || '-'
                                    }
                                </RightAlignTextBox>
                                </Caption1>
                                <Caption1>
                                Duration:
                                <RightAlignTextBox data-test-total-process-time>
                                    {formatDuration(productionRequest.totalProcessTime, { unit: 'minute' })}
                                </RightAlignTextBox>
                                </Caption1>
                    </Grid.Column>
                </Grid>
            </PlannedOnInformationContainer>
        );
    }
}
