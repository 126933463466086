import { observer } from 'mobx-react'
import TargetLimitedNumberInput from './LimitedNumberInput'
import Decimal from 'decimal.js'
import { humanReadable } from 'helpers/decimal'


@observer
export default class TargetLimitedDecimalInput extends TargetLimitedNumberInput {
  static defaultProps = {
    ...TargetLimitedNumberInput.defaultProps,
    allowDecimal: true,
  };

  fromModel(value) {
    return humanReadable(value.toString())
  }

  toModel(value) {
    // By default go with a 0, which is not 100% correct but does allow all
    // other calculations to assume that model value is a Decimal.
    return Decimal(value === '' ? 0 : value)
  }

  fromTarget(value) {
    return humanReadable(value.toString())
  }

  toTarget(value) {
    const capMax = this.getCapMax()
    const capMin = this.getCapMin()

    value = this.normalizeValue(value);
    value = Decimal(value === '' ? 0 : value);

    // replace value is out of range
    if (Decimal(value).lt(Decimal(capMin))) {
        // too small
        value = capMin
    } else if (Decimal(value).gt(Decimal(capMax))) {
        // too big
        value = capMax
    }

    return Decimal(value === '' ? 0 : value)
  }

}
