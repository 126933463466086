import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { action, observable } from 'mobx'
import { observer } from 'mobx-react'
import { Button, Form, Modal } from 'semantic-ui-react'
import { TargetTextInput, TargetSelect, TargetCheckbox } from 'spider/semantic-ui/Target'
import RightDivider from 'spider/component/RightDivider'
import styled from 'styled-components'

// components
import PrinterSelector from 'component/PrinterHelpers/PrinterSelector'
// end components

// helpers
import { showSaveNotification, showNotification } from 'helpers/notification'
import { isFeatureFlagEnabled } from 'helpers/featureFlags'
import { print, getPrinters, getPrinterType } from 'helpers/print'
import getGlobalValue from 'helpers/getGlobalValue'
// end helpers

// stores
import { Batch } from 'store/Batch'
import { StorageLocationStore } from 'store/StorageLocation'
// end stores

// 'Rework Batch' button on Batch Overview
@observer
export default class BatchReworkModal extends Component {
  static propTypes = {
    target: PropTypes.instanceOf(Batch).isRequired,
    trigger: PropTypes.node,
  }

  @observable open = false

  @observable reason = ''
  @observable storageLocation = this.props.target?.storageLocation?.id
  @observable createNewBatch = false
  @observable printLabel = false
  @observable print = null

  constructor(...args) {
    super(...args)

    this.onOpen = this.onOpen.bind(this)
    this.onClose = this.onClose.bind(this)
    this.onChangeReason = this.onChangeReason.bind(this)
    this.onChangeStorageLocation = this.onChangeStorageLocation.bind(this)
    this.onChangeCreateNewBatch = this.onChangeCreateNewBatch.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.onSubmitPrint = this.onSubmitPrint.bind(this)
    this.postRework = this.postRework.bind(this)
  }

  getInstructions = async (batch) => {
    const now = new Date()
    const date = `${now.getFullYear()}-${now.getMonth()}-${now.getDate()}`
    const time = `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`
    const articleType = batch?.batchType?.articleType

    const productionRequest = batch.productionRequest
    const exactShopOrder = productionRequest?.productionOrder?.exactShopOrder
    const exactGlobeProductionOrder = productionRequest?.productionOrder?.exactGlobeProductionOrder
    const erpOrderID = (!exactShopOrder?.isNew && exactShopOrder?.number) || (!exactGlobeProductionOrder?.isNew && exactGlobeProductionOrder?.productionOrderNumber)

    const template = await getGlobalValue('rework_label')
    const instructions = (
      template
        .replace(/\{\{batch_size\}\}/g, batch.quantity)
        .replace(/\{\{serial_number\}\}/g, batch.serialNumber)
        .replace(/\{\{date\}\}/g, date)
        .replace(/\{\{time\}\}/g, time)
        .replace(/\{\{article_type_code\}\}/g, articleType?.code)
        .replace(/\{\{article_type_name\}\}/g, articleType?.name)

        .replace(/\{\{erp_order_id\}\}/g, erpOrderID)
        .replace(/\{\{quantity\}\}/g, productionRequest?.quantity)
        .replace(/\{\{order_id\}\}/g, productionRequest?.productionOrder?.id)
        .replace(/\{\{[A-z]*\}\}/g, '')
    )

    return instructions
  }

  async onOpen() {
    this.open = true
    const [printerType, allPrinters, instructions] = await Promise.all([
      getGlobalValue('printer_model'),
      getPrinters(),
      this.getInstructions(this.props.target),
    ])

    const printers = allPrinters.filter((printer) => getPrinterType(printer) === printerType)
    const printer = printers.length === 1 ? printers[0] : null

    this.print = { printerType, instructions, printers, printer }
  }

  onClose() {
    this.open = false
    this.print = null
  }

  onChangeReason(value) {
    this.reason = value
  }

  onChangeStorageLocation(value) {
    this.storageLocation = value
  }

  onChangeCreateNewBatch(value) {
    this.createNewBatch = value
  }

  postRework() {
    const batch = this.props.target

    return batch.api.post(
      `${batch.url}rework/`, {
        reason: this.reason,
        storage_location: this.storageLocation,
        create_new_batch: this.createNewBatch,
      }
    ).then((res) => {
      return batch.fetch().then(showSaveNotification)
    }).catch((error) => {
      let messages = []
      if (error.response.status === 418) {
        // BinderRequestError
        messages.push(error.response.data.message)
      } else if (error.response.status === 400) {
        // BinderValidationError
        // eslint-disable-next-line
        for (const validation_error of error.response.data.errors) {
          messages.push(validation_error.message)
        }
      }
      // eslint-disable-next-line
      for (const message of messages) {
        showNotification({
          message: message,
          dismissAfter: 3000,
        })
      }
    })
  }

  @action async onSubmit() {
    await this.postRework().then(() => {
      this.onClose()
    })
  }

  @action async onSubmitPrint() {
    await this.postRework().then(() => {
      print(this.print.printer, this.print.instructions, { copies: this._copies })
      .then(() => {
        this.onClose()
      })
    })
  }

  @observable storageLocationStore = new StorageLocationStore({
    relations: ['warehouse', 'articleTypeWarehouses.articleType'],
    params: {
      '.warehouse': this.props.target.storageLocation?.warehouse?.id,
      ...!isFeatureFlagEnabled('flexible_storage_locations') && {
        where: `article_type_warehouses(article_type=${this.props.target.batchType?.articleType?.id})`
      }
    }
  })

  render() {
    const { trigger } = this.props

    const ObjectCode = styled.span`
      color: #777;
      font-family: monospace, monospace;
      margin-right: 1em;
    `

    return (
      <Modal closeIcon data-test-batch-rework-modal
        onClose={this.onClose}
        onOpen={this.onOpen}
        open={this.open}
        trigger={trigger}
      >
        <Modal.Header>{t('performanceResetModal.title')}</Modal.Header>
        <Modal.Content>
          <Form onSubmit={this.onSubmit}>
            <TargetTextInput
              autoFocus
              data-test-rework-reason
              label={t('performanceResetModal.reason')}
              name="reason"
              value={this.reason}
              onChange={this.onChangeReason}
            />
            <TargetSelect remote
              label={t('batch.field.storageLocation.label')}
              store={this.storageLocationStore}
              value={this.storageLocation}
              name="storageLocation"
              toOption={storageLocation => ({
                value: storageLocation.id,
                text: <span><ObjectCode>{storageLocation.code}</ObjectCode>{storageLocation.name}</span>
              })}
              onChange={this.onChangeStorageLocation}
            />
            <TargetCheckbox nolabel
              rightLabel
              label={t('performanceResetModal.createNewBatch')}
              name="createNewBatch"
              value={this.createNewBatch}
              onChange={this.onChangeCreateNewBatch}
            />
            <PrinterSelector print={this.print}/>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <RightDivider />
          <Button
            primary
            data-test-confirm-rework-button
            icon="check"
            labelPosition="left"
            content={t('performanceResetModal.resetButton')}
            onClick={this.onSubmit}
          />
          <Button
            primary
            data-test-confirm-rework-print-button
            icon="check"
            labelPosition="left"
            disabled={!this.createNewBatch || !this.print?.printer || this.print?.printer?.length === 0}
            content={t('performanceResetModal.printButton')}
            onClick={this.onSubmitPrint}
          />
        </Modal.Actions>
      </Modal>
    )
  }
}
