import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { action, computed, observable } from 'mobx';
import { observer } from 'mobx-react'
import { Button, Checkbox, Icon, Table, Confirm } from 'semantic-ui-react'
import styled from 'styled-components';
import { ErrorLabel } from 'spider/semantic-ui/Target'
import Decimal from 'decimal.js'
import Batches from 'container/Perform/Step/Form/Batches'
import { SerialNumber } from '../TargetInfoModal/TargetDescription'
import { FullWidthTable } from './FormStep'

// helpers
import { humanReadable } from '../../helpers/decimal';
import { isMyWorkstation, isUnknownWorkstation } from './helpers'
// end helpers

// stores
import { Step } from 'store/Step';
import { BillOfMaterialVersion } from 'store/BillOfMaterialVersion';
import { ProductionRequest } from 'store/ProductionRequest'
// end stores

const BatchInfo = styled.div`
  display: flex;
  padding: 5px;
`

const getScannedBatches = (materialPlanItem) => {
  // NOTE: this function only returns batches that are saved!
  const batches = []
  // eslint-disable-next-line
  for (const detailMaterialtask of materialPlanItem.details.models) {
    if (detailMaterialtask.detail.components.length > 0) {
      // Actual step performance
      // eslint-disable-next-line
      for (const component of detailMaterialtask.detail.components.models) {
        if (component.batchUsings.at(0).usedBatch.batchType.articleType.id === materialPlanItem.articleType.id) {
          batches.push(component.batchUsings.at(0).usedBatch)
        }
      }
    } else {
      // Intermediate save
      batches.push(...detailMaterialtask.batches)
    }
  }
  return batches
}


/**
 * Contains the actual table of the material plan to be executed
 */
@observer
export class MaterialPlanMaterialsTable extends Component {
  static propTypes = {
    productionRequest: PropTypes.instanceOf(ProductionRequest),
    materialPlan: PropTypes.instanceOf(BillOfMaterialVersion).isRequired,
    step: PropTypes.instanceOf(Step).isRequired,
    onChange: PropTypes.func.isRequired,
    required: PropTypes.number.isRequired,
    quantityTodo: PropTypes.number.isRequired,
    value: PropTypes.object.isRequired,
    targetProps: PropTypes.object.isRequired,
    onConfirm: PropTypes.func.isRequired,
    getErrors: PropTypes.func.isRequired,
    generalErrors: PropTypes.array,
    batchSize: PropTypes.number,
  }

  static defaultProps = {
    generalErrors: [],
    getErrors: (bomItemId) => [],
    batchSize: 1,
  }

  @observable showDeletionConfirm = null
  @observable scannedUnsavedBatches = {}

  constructor(...args) {
    super(...args)

    const { productionRequest } = this.props;
    this.renderMaterialPlan = this.renderMaterialPlan.bind(this)

    // this.props.productionRequest has no component batches, so we have this local productionRequest to be able to view those
    this.productionRequestWithBatches = new ProductionRequest(
      { id: parseInt(productionRequest.id) },
      {
        relations: [
          'productionOrder.billOfMaterialVersion.items.articleType.batchTypes',
          'batches.batchUsings.usedBatch.batchType.articleType',
          'batches.batchType.articleType',
        ]
      }
    )
    this.productionRequestWithBatches.setFetchParams({
      where: (
        'production_order.bill_of_material_version.items(article_type.batch_types.type:in=make,buy,on_the_fly,stock_count),'
      ),
    })
  }

  componentDidMount() {
    this.productionRequestWithBatches.fetch()
  }

  @computed get batchRequirements() {
    const { materialPlan, batchSize, productionRequest } = this.props;
    const batchRequirements = {}

    // eslint-disable-next-line
    for (const item of materialPlan.items.models) {
      if (item.requiredQuantity) {
        batchRequirements[item.id] = Decimal(item.requiredQuantity / productionRequest.quantity)
      } else {
        batchRequirements[item.id] = Decimal(item.quantityBatch ?? item.quantity)
      }

      batchRequirements[item.id] = Decimal(batchRequirements[item.id] * batchSize)
    }

    return batchRequirements
  }

  batchesScanOnChange(value, materialPlanItem) {
    const { onChange } = this.props;
    onChange(materialPlanItem, value, null, null)
    this.scannedUnsavedBatches[materialPlanItem.id] = value
  }

  getIntermediateQuantity(item) {
    // collect all batches that are "intermediate" SAVED but not yet CONFIRMED
    return item.details.models.reduce((total, itemDetail) => total.add(
      itemDetail.batches.reduce((subTotal, batch) => subTotal.add(this.getQuantityUsed(batch)), Decimal(0))
    ), Decimal(0))
  }

  @computed get batchCollected() {
    const { materialPlan } = this.props;
    const batchCollected = {}

    // eslint-disable-next-line
    for (const item of materialPlan.items.models) {
      let unsavedSum
      if (this.scannedUnsavedBatches[item.id]) {
        unsavedSum = this.scannedUnsavedBatches[item.id].reduce((total, { usage }) => total.add(usage ? usage : 0), Decimal(0))
      } else {
        unsavedSum = Decimal(0)
      }

      // collect all batches that are "intermediate" SAVED but not yet CONFIRMED
      const intermediateSum = this.getIntermediateQuantity(item)

      // collect all batches CONFIRMED to this line, and sum the batch usages
      const savedSum = this.productionRequestWithBatches.batches.models
        .filter(batch => batch.batchType.articleType.id === item.articleType.id).reduce(
          (total, batch) => total.add(
            batch.batchUsings.models.reduce((subTotal, { quantity }) => subTotal.add(quantity), Decimal(0))
          ), Decimal(0)
        )

      batchCollected[item.id] = {
        unsavedSum: unsavedSum,
        intermediateSum: intermediateSum,
        savedSum: savedSum,
        totalSum: unsavedSum.add(savedSum).add(intermediateSum)
      }
    }

    return batchCollected
  }


  renderBatchesScan(materialPlanItem, quantity) {
    const { productionRequest, value, onConfirm, getErrors, targetProps } = this.props;

    const quantityToCollect = Decimal(0).add(quantity).sub(this.batchCollected[materialPlanItem.id]['intermediateSum'])

    return (
      <Batches data-test-batches-item={materialPlanItem.id}
        {...targetProps}
        articleType={materialPlanItem.articleType}
        quantity={quantityToCollect > 0 ? quantityToCollect : 0}
        value={value}
        errors={getErrors(materialPlanItem.id.toString())}
        onChange={(value) => this.batchesScanOnChange(value, materialPlanItem)}
        onConfirm={(value) => onConfirm(materialPlanItem)}
        allowStorageLocationSelection={true}
        currentWarehouse={productionRequest.productionOrder.warehouse}
      />
    )
  }

  renderPerformMaterial(materialPlanItem, itemChecked, quantity) {
    const scannedBatches = getScannedBatches(materialPlanItem)
    if (scannedBatches.length > 0) {
      return this.renderScannedBatches(materialPlanItem, scannedBatches)
    }

    if (!materialPlanItem.backflush) {
      return this.renderBatchesScan(materialPlanItem, quantity)
    }

    return (
      <Checkbox toggle data-test-materials-item={materialPlanItem.id}
        checked={itemChecked}
        onChange={(e, { checked }) => materialPlanItem.backflush = false}
      />
    )
  }

  getRequiredByBatch(item) {
    const { productionRequest, batchSize } = this.props;
    return Decimal(
      (item.requiredQuantity ?
        (item.requiredQuantity / productionRequest.quantity) :
        (item.quantityBatch ?? item.quantity)
      ) * batchSize,
    )
  }

  getSerialNumber(batch) {
    if (batch.id) {
      // Once a batch is intermediate_saved we can get it by id
      return batch.serialNumber
    }
    return batch['serial_number']
  }

  getQuantityUsed(batch) {
    if (batch.id) {
      // Once a batch is intermediate_saved we can get it by id
      return batch.batchUseds.models.reduce((total, { quantity }) => total.add(quantity), Decimal(0))
    }
    return batch['usage'] ? batch['usage'] : 0
  }

  getStorageLocation(articleTypeStorageLocations, batch) {
    if (batch.id) {
      // Once a batch is intermediate_saved we can get it by id
      const ats = articleTypeStorageLocations.find(asl => asl.storageLocation.id === batch.storageLocation.id)
      const stockSuffix = ats ? ` (${ats.stock})` : ''
      return batch.storageLocation.code + stockSuffix
    }
    return batch['storage_location']
  }

  async onRemoveMaterialDetails(materialPlanItem) {
    const detailMaterialtask = materialPlanItem.details.find(detailMaterial => {
      if (detailMaterial.detail.components.length > 0) {
        // Actual step performance
        // eslint-disable-next-line
        for (const component of detailMaterial.detail.components.models) {
          if (component.batchUsings.at(0).usedBatch.batchType.articleType.id === materialPlanItem.articleType.id) {
            return detailMaterial
          }
        }
        return null
      }
      // Intermediate save
      return detailMaterial
    })

    if (detailMaterialtask) {
      await detailMaterialtask.delete()
    }
  }

  renderScannedBatches(materialPlanItem, scannedBatches) {
    const requiredQuantityForBatch =humanReadable(this.getRequiredByBatch(materialPlanItem))

    return (
      <>
        {scannedBatches.map(batch => {
          const serialNumber = this.getSerialNumber(batch)
          const quantityUsed = humanReadable(this.getQuantityUsed(batch))
          return (
            <BatchInfo data-test-scanned-batches>
              <SerialNumber data-test-serial-number={serialNumber}>
                {quantityUsed} | {serialNumber} | {this.getStorageLocation(materialPlanItem.articleType.storageLocations, batch)}
              </SerialNumber>
              <label>&nbsp;</label>
              <Icon data-test-delete-material-details-scanned-batch={materialPlanItem.id}
                name="delete"
                size="large"
                style={{ cursor: 'pointer' }}
                onClick={() => this.showDeletionConfirm = materialPlanItem.id}
              />
              <Confirm
                open={this.showDeletionConfirm === materialPlanItem.id}
                header={t('formStepField.field.materialPlan.confirmDeletion.header', { description: materialPlanItem.description })}
                content={t('formStepField.field.materialPlan.confirmDeletion.content')}
                confirmButton={t('formStepField.field.materialPlan.confirmDeletion.confirmButton')}
                onCancel={() => this.showDeletionConfirm = null}
                onConfirm={action(async () => {
                  this.showDeletionConfirm = null
                  this.onRemoveMaterialDetails(materialPlanItem)
                })}
              />
            </BatchInfo>
          )
        })}
        {this.renderBatchesScan(materialPlanItem, requiredQuantityForBatch)}
      </>
    )
  }


  getRequiredQuantityForWholeOrder(item, required) {
    let requiredQuantity = Decimal(0)
    requiredQuantity = Decimal(item.requiredQuantity ?? ((item.quantityBatch ?? item.quantity) * required))

    return humanReadable(requiredQuantity)
  }

  renderMaterialPlan(materialPlanItem) {
    const { required, value, productionRequest } = this.props;

    const requiredQuantityForWholeOrder = this.getRequiredQuantityForWholeOrder(materialPlanItem, required)

    const requiredQuantityForBatch = this.getRequiredByBatch(materialPlanItem)
    const issued = productionRequest.materialIssues
      .filter(materialIssue => materialIssue.articleType.id === materialPlanItem.articleType.id)
      .reduce((sum, issue) => sum.plus(Decimal(issue.quantity)), Decimal(0)).plus(
        this.getIntermediateQuantity(materialPlanItem) //Intermediate saved batches are here
      )

    const itemChecked = value[materialPlanItem.id] || false
    return (
      <Table.Row material-plan-item={materialPlanItem.id}>
        <Table.Cell collapsing>{materialPlanItem.number}</Table.Cell>
        <Table.Cell collapsing>
          {materialPlanItem.description}
        </Table.Cell>
        <Table.Cell collapsing>
          {materialPlanItem.articleType.code && materialPlanItem.articleType.getLink()}
          {materialPlanItem.description === materialPlanItem.articleType.name ? '' : ` ${materialPlanItem.articleType.name}`}
        </Table.Cell>
        <Table.Cell collapsing>
          <Icon size="big" name={materialPlanItem.backflush ? 'check square' : 'square outline'} />
        </Table.Cell>
        <Table.Cell collapsing data-test-required-quantity={materialPlanItem.id} colSpan={0}>
          {requiredQuantityForWholeOrder}
        </Table.Cell>
        <Table.Cell collapsing data-test-batch-required-quantity={materialPlanItem.id} colSpan={0}>
          <Button data-test-batch-quantity-minus={materialPlanItem.id}
            icon='minus'
            onClick={action(() => {
              if (this.batchRequirements[materialPlanItem.id].sub(Decimal(1)).gt(Decimal(0))) {
                this.batchRequirements[materialPlanItem.id] = this.batchRequirements[materialPlanItem.id].sub(Decimal(1))
              }
            })}
          />
          {humanReadable(this.batchRequirements[materialPlanItem.id])}
          <Button data-test-batch-quantity-plus={materialPlanItem.id}
            icon='plus'
            onClick={() => {
              if (this.batchRequirements[materialPlanItem.id].plus(Decimal(1)).lte(requiredQuantityForBatch)) {
                this.batchRequirements[materialPlanItem.id] = this.batchRequirements[materialPlanItem.id].plus(Decimal(1))
              }
            }}
          />
        </Table.Cell>
        <Table.Cell collapsing data-test-issued-quantity={materialPlanItem.id} colSpan={0}>
          {humanReadable(issued)}
        </Table.Cell>
        <Table.Cell data-test-item-perform={materialPlanItem.id}>
            {this.renderPerformMaterial(materialPlanItem, itemChecked, this.batchRequirements[materialPlanItem.id])}
        </Table.Cell>
        <Table.Cell data-test-done={materialPlanItem.id}>
          {`${this.batchCollected[materialPlanItem.id]['totalSum']}/${humanReadable(this.batchRequirements[materialPlanItem.id])}`}
        </Table.Cell>
      </Table.Row>
    )
  }

  state = { toggled: false }

  handleClick = (e) => {
    this.setState(prevState => ({
      toggled: !prevState.toggled
    }));
    console.log(this.state.toggled)
  }

  render() {
    const { materialPlan, step, generalErrors } = this.props;
    const hasUnlinkedItems = materialPlan.items.filter(item => item.type === 'material' && isUnknownWorkstation(item)).length !== 0
    const hasLinkedItems = materialPlan.items.filter(item => item.type === 'material' && isMyWorkstation(step.workStation.code, item)).length !== 0
    let table = []

    //Table for Material Plan Materials
    const tableHeader = (
      <Table.Row>
        <Table.HeaderCell>{t('formStepField.field.materialPlan.number.label')}</Table.HeaderCell>
        <Table.HeaderCell>{t('formStepField.field.materialPlan.description.label')}</Table.HeaderCell>
        <Table.HeaderCell>{t('formStepField.field.articleType.label')}</Table.HeaderCell>
        <Table.HeaderCell>{t('formStepField.field.materialPlan.backflush.label')}</Table.HeaderCell>
        <Table.HeaderCell colSpan={0}>{t('formStepField.field.materialPlan.requiredQuantity.label')}</Table.HeaderCell>
        <Table.HeaderCell colSpan={0}>{t('formStepField.field.materialPlan.requiredForThisBatch.label')}</Table.HeaderCell>
        <Table.HeaderCell colSpan={0}>{t('formStepField.field.materialPlan.issued.label')}</Table.HeaderCell>
        <Table.HeaderCell>{t('formStepField.field.materialPlan.checklist.label')}</Table.HeaderCell>
        <Table.HeaderCell>{t('formStepField.field.materialPlan.finished.label')}</Table.HeaderCell>
      </Table.Row>
    )

    table.push(
      <>
        { // if there are any linked items, render the header as well
        hasLinkedItems ?
          <Table.Header>{tableHeader}</Table.Header>
          : //else, display a message saying there are no linked tasks/materials
          <Table.Row>
            <Table.Cell colSpan='8'>
              {t('formStepField.field.materialPlan.noMaterials') }
            </Table.Cell>
          </Table.Row>
        }
        <tbody data-material-plan-table={'material'}>
          {materialPlan.items
            .filter(item => item.type === 'material' && isMyWorkstation(step.workStation.code, item))
            .map(this.renderMaterialPlan)}
        </tbody>
        {generalErrors.length > 0 && (
          <ErrorLabel>
            {generalErrors.map(({ message }, i) => <div key={i}>{message}</div>)}
          </ErrorLabel>
        )}
      </>
    )

    // Extra accordion in case there are any tasks/materials without any linked workstations.
    // Since we don't have a place for these items, we display them on all workstations in an
    // unobstructive ways
    if (hasUnlinkedItems) {
      table.push(
        <>
          <Table.Row>
            <Table.Cell onClick={this.handleClick} colSpan='8' test-accordion-unlinked-items={'material'}>
              {!this.state.toggled && <Icon name='chevron right' />}
              {this.state.toggled && <Icon name='chevron down' />}
              {t('formStepField.field.materialPlan.noWorkstationLinked')}
            </Table.Cell>
          </Table.Row>
          {!hasLinkedItems && this.state.toggled ? tableHeader : null}
          {this.state.toggled && <tbody data-material-plan-unlinked-items-table={'material'}>
            {materialPlan.items
              .filter(item => item.type === 'material' && isUnknownWorkstation(item))
              .map(this.renderMaterialPlan)}
          </tbody>}
        </>
      )
    }

    return (
      <FullWidthTable padded basic='very'>
        {table}
      </FullWidthTable>
    )
  }
}

