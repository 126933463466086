import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react'
import { observable, runInAction, action } from 'mobx'
import { Button, Modal, Icon } from 'semantic-ui-react'
import FormFields from './Form/FormFields';
import RightDivider from 'spider/component/RightDivider'
import PerformStep from './Step'
import { DateTime } from 'luxon';
import Decimal from 'decimal.js'


// helpers
import sortSteps from 'helpers/sortSteps'
import { getCounts } from 'helpers/counts'
import { Container } from './helpers'
import { showSaveNotification, showErrorNotification } from 'helpers/notification'
import { print, getPrinters, getPrinterType } from 'helpers/print'
import getGlobalValue from 'helpers/getGlobalValue'
// end helpers

// stores
import { Batch } from 'store/Batch';
import { Warehouse } from 'store/Warehouse';
import { Operator } from 'store/Operator'
import { StorageLocation } from 'store/StorageLocation';
import { DetailMaterialTask, DetailMaterialTaskStore } from 'store/DetailMaterialTask'
// end stores

@observer
export default class PerformFormStep extends PerformStep {
    static propTypes = {
        ...PerformStep.propTypes,
        batch: PropTypes.instanceOf(Batch).isRequired,
        batchSize: PropTypes.number.isRequired,
        warehouses: PropTypes.arrayOf(PropTypes.instanceOf(Warehouse)).isRequired,
        currentWarehouse: PropTypes.instanceOf(Warehouse),
        storageLocations: PropTypes.arrayOf(PropTypes.instanceOf(StorageLocation)).isRequired,
        predeterminedQuantity: PropTypes.number.isRequired,
        operator: PropTypes.instanceOf(Operator),
    }

    @observable allTasksDone = null
    @observable allMaterialsDone = null
    @observable showTaskCheckModal = null;
    @observable confirmedTasksMaterialCheck = null
    @observable savedTasks = []
    @observable savedMaterials = []
    @observable variableQuantity = null
    @observable saveIsLoading = false

    constructor(...args) {
        super(...args)

        this.setFormData = this.setFormData.bind(this)
        this.focus = this.focus.bind(this)
        this.onChangeMaterialTask = this.onChangeMaterialTask.bind(this)
        this.onPrintProgress = this.onPrintProgress.bind(this)

        const { step } = this.props
        this.initFormData(step.formStep.form)
    }

    initFormData(form) {
        const { predeterminedQuantity, productionRequest, step } = this.props

        this.data = {}
        this.data.form_data = {}
        this.data.form_data['task_progress'] = {}

        this.subBatchesDefault = {}

        // eslint-disable-next-line
        for (const field of form.fields.models) {
            if (field.type === 'quantity') {
                // We preload the value of the quantity field to the max quantity of the batch(es) that are at that step
                // This is needed for the material plan since we save intermediate values that depend on the quantity field.
                // If we then reset the quantity field to 1, the task progress will show impossible info like 20/1 tasks done.
                const steps = sortSteps(productionRequest.processVersion.steps)
                this.stepCounts = getCounts({
                    steps: steps,
                    quantity: productionRequest.quantity,
                    batches: productionRequest.batches,
                    superrequestAtSubprocesses: productionRequest.superrequestAtSubprocesses,
                    subrequestsFinished: productionRequest.subrequestsFinished,
                })
                // Quantity at the current step. Key names of Counts are a bit confusing, but oh well..
                this.data.form_data[field.id] = this.stepCounts[`pre_${step.id}`] || predeterminedQuantity
            }

            if (field.type === 'sub_batches') {
                const subBatchesData = {}
                productionRequest.batches.filter(batch => batch.subProductionRequestBatchLinks.length > 0).forEach(batch => {
                    subBatchesData[batch.id.toString()] = batch.quantity
                })
                this.data.form_data[field.id] = subBatchesData
                this.subBatchesDefault = { ...subBatchesData }
            }

            if (field.type === 'material_plan_material' || field.type === 'material_plan_task') {
                this.data.form_data[field.id] = {}
            }
            if (field.type === 'best_before_date') {
                this.data.form_data[field.id] = DateTime.local().plus({ days: field.bestBeforePeriod }).toFormat('yyyy-LL-dd')
            }
        }
    }

    componentDidMount() {
        super.componentDidMount()

        const { step } = this.props
        this.focus(step.formStep.form, 0)
    }


    focus(form, index, subindex = 0) {
        const { productionRequest } = this.props

        const billOfMaterialVersion = productionRequest.productionOrder.billOfMaterialVersion

        for (let i = index; i < form.fields.length; i++) {
            const field = form.fields.at(i)
            if (field.type === 'sub_batches') {
                const fieldNode = this[`field_${i}_${subindex}`]
                if (fieldNode) {
                    fieldNode.inputElement.focus()
                    return
                } else {
                    continue
                }
            }

            const fieldData = field.type === 'bom'
                ? billOfMaterialVersion.items.models.slice(subindex).map((item, i) => [this.data.form_data[field.id] && this.data.form_data[field.id][item.articleType.id], subindex + i])
                : [[this.data.form_data[field.id], 0]]
            // eslint-disable-next-line
            for (const [value, j] of fieldData) {
                if (value === undefined) {
                    const fieldNode = this[`field_${i}${j !== 0 ? `_${j}` : ''}`]
                    if (!fieldNode) {
                        return
                    }
                    if (field.type === 'check') {
                        fieldNode.inputRef.current.focus()
                    } else if (['choice', 'warehouse'].includes(field.type)) {
                        fieldNode.handleFocus()
                    } else if (field.type === 'image') {
                        // Nothing to focus
                    } else if (field.type === 'quantity' || field.type === 'sub_batches') {
                        fieldNode.inputElement.focus()
                    } else if (field.type === 'best_before_date') {
                        // Nothing to focus
                    } else {
                        fieldNode.focus()
                    }
                    return
                }
            }
        }
    }

    @action setFormData(key, value) {
        this.data.form_data[key] = value
        this.errors = this.errors.filter(({ path }) => !(path.length >= 2 && path[0] === 'form_data' && path[1] === key))
    }


    async onSubmit(quantity) {
        const { step, onPerform } = this.props

        this.errors = []
        try {
            return await onPerform({ ...this.data, quantity }, this.startedAt)
        } catch (err) {
            if (err.response && err.response.status === 400 && err.response.data.code === 'ValidationError') {
                const errors = err.response.data.errors
                runInAction(() => {
                    this.errors = errors
                    let formErrors = false
                    const form = step.formStep.form
                    // eslint-disable-next-line
                    for (const { path, code } of this.errors) {
                        if (path.length >= 2 && path[0] === 'form_data') {
                            if (path[1] === 'task_progress') {
                                // ignore task progress data
                                return
                            }
                            const field = form.fields.get(parseInt(path[1]))
                            if (
                                field.type === 'measure' &&
                                this.data.form_data[path[1]] &&
                                path.length >= 3 &&
                                path[2] === 'value'
                            ) {
                                this.data.form_data[path[1]].value = null
                                this.data.form_data[path[1]].reason = null
                            } else if (
                                field.type === 'measure' &&
                                this.data.form_data[path[1]] &&
                                path.length >= 3 &&
                                path[2] === 'reason'
                            ) {
                                const { value } = this.data.form_data[path[1]]
                                if (value === null || (value >= field.measureMin && value <= field.measureMax)) {
                                    this.data.form_data[path[1]].reason = null
                                } else {
                                    this.data.form_data[path[1]].reason = ''
                                }
                            } else if (
                                (field.type === 'material_plan_material' || field.type === 'material_plan_task' || field.type === 'bom') &&
                                path.length >= 3
                            ) {
                                if (code === 'task_not_finished') {
                                    formErrors = true
                                } else if (this.data.form_data[path[1]]) {
                                    delete this.data.form_data[path[1]][path[2]]
                                }
                            } else if (field.type === 'sub_batches' && path.length >= 3) {
                                this.data.form_data[path[1]][path[2]] = this.subBatchesDefault[path[2]]
                            } else {
                                delete this.data.form_data[path[1]]
                            }
                            formErrors = true
                        } else if (path.length >= 1 && path[0] === 'load_carrier') {
                            this.data.load_carrier = null
                            formErrors = true
                        } else if (path.length >= 1 && path[0] === 'serial_number') {
                            this.data.serial_number = ''
                            formErrors = true
                        }
                    }
                    if (formErrors) {
                        this.focus(step.formStep.form, 0)
                    }
                })
            }
            throw err
        }
    }

    @action
    async onConfirm(print, toMain = false) {
        try {
            if ((this.allTasksDone !== null && !this.allTasksDone) || (this.allMaterialsDone !== null && !this.allMaterialsDone)) {
                this.confirmedTasksMaterialCheck = await new Promise((resolve, reject) => (this.showTaskCheckModal = { resolve, reject }))
            } else {
                this.confirmedTasksMaterialCheck = true
            }
        } finally {
            this.showTaskCheckModal = null
            this.allTasksDone = null
            this.allMaterialsDone = null
        }

        if (this.confirmedTasksMaterialCheck) {
            super.onConfirm(false, toMain)
        }
    }

    @action
    async onChangeMaterialTask(key, type, item, value, itemsFinished, taskQuantityDone) {
        const checkedItem = (this.data.form_data[key] || {}).length > 0 && this.data.form_data[key][item.id] !== undefined && this.data.form_data[key][item.id]
        if (value !== null && value !== undefined) {
            this.setFormData(key, { ...this.data.form_data[key] || {}, [item.id]: value })
        }
        this.errors = this.errors.filter(({ path }) => !(path.length >= 3 && path[0] === 'form_data' && path[1] === key && path[2] === item.id.toString()))

        if (taskQuantityDone) {
            item.taskProgress = taskQuantityDone
            this.data.form_data['task_progress'][item.id] = taskQuantityDone
        }

        if (type === 'material'){
            //this makes sure not to add duplicates to the list
            this.savedMaterials.remove(item)
            this.savedMaterials.push(item)
        }
        if (type === 'task'){
            //this makes sure not to add duplicates to the list
            this.savedTasks.remove(item)
            this.savedTasks.push(item)
        }

        if (type === 'task') {
            this.allTasksDone = itemsFinished
        } else {
            this.allMaterialsDone = itemsFinished
        }

        // Do an update to the details of the performance
        if (this.confirmedTasksMaterialCheck !== null && !this.confirmedTasksMaterialCheck && !checkedItem && value) {
            this.intermediateSaveItem(item, value)
        }
    }

    renderFormFields() {
        const { step, batch, batchSize, warehouses, storageLocations, currentWarehouse, productionRequest, stats, operator } = this.props
        return (
            <FormFields
                productionRequest={productionRequest}
                step={step}
                batch={batch}
                form={step.formStep.form}
                batchSize={batchSize}
                warehouses={warehouses}
                currentWarehouse={currentWarehouse}
                storageLocations={storageLocations}
                quantityTodo={stats.quantityTodo}
                errors={this.errors}
                setErrors={(errors) => this.errors = errors}
                data={this.data}
                setFormData={this.setFormData}
                onFocus={(index, subindex) => this.focus(step.formStep.form, index, subindex)}
                onAddRef={(key, ref) => this[key] = ref}
                onChangeMaterialTask={this.onChangeMaterialTask}
                onChangeQuantity={(key, quantity) => {
                    this.variableQuantity = quantity
                    this.setFormData(key, quantity)
                }}
                operator={operator}
            />
        )
    }

    renderContent() {
        return (
            <Container>
                {this.renderFormFields()}
                {this.showTaskCheckModal && (
                    <Modal open closeIcon data-test-unifined-tasks-material-modal size="small" onClose={() => this.showTaskCheckModal.reject()}>
                        <Modal.Header>
                            <Icon style={{ color: '#EBBB12', marginRight: '0.5em' }} name={'warning sign'} />
                            {t('workStation.production.performModal.tasksCheck.header')}
                        </Modal.Header>
                        <Modal.Content>{t('workStation.production.performModal.tasksCheck.content')}</Modal.Content>
                        <Modal.Actions>
                            <RightDivider />
                            <Button data-test-go-back-perform-modal
                                compact
                                icon="delete"
                                labelPosition="left"
                                content={t('workStation.production.performModal.tasksCheck.closeButton')}
                                onClick={() => this.showTaskCheckModal.resolve(false)}
                            />
                            <Button data-test-confirm-unifinished-exit
                                compact
                                icon="check"
                                labelPosition="left"
                                content={t('workStation.production.performModal.tasksCheck.confirmButton')}
                                onClick={() => this.showTaskCheckModal.resolve(true)}
                            />
                        </Modal.Actions>
                    </Modal>
                )}
            </Container>
        )
    }

    async intermediateSaveItem(item, value) {
        const { step, batchSize } = this.props

        let detailMaterialTask
        const detailMaterialTasks = new DetailMaterialTaskStore({ params: { '.step': step.id, '.bill_of_material_item': item.id } })
        await detailMaterialTasks.fetch()
        // If a batch is finished, the detail is "done" and should not be considered for the intermediate save
        let unfinishedDetails = detailMaterialTasks.filter((detail) => detail.value !== true)
        // If there aren't any details, we make a new one
        if (unfinishedDetails.length === 0) {
            detailMaterialTask = new DetailMaterialTask({}, { relations: ['step', 'billOfMaterialItem'] })
            detailMaterialTask.step = step
            detailMaterialTask.billOfMaterialItem = item
        }
        // If there is at least one detail, we edit the first one
        else {
            detailMaterialTask = unfinishedDetails.at(0)
        }
        if (item.type === 'material' && value) {
            const requiredQuantityForBatch = Decimal(item.requiredQuantity ?? ((item.quantityBatch ?? item.quantity) * (this.variableQuantity || batchSize)))
            detailMaterialTask.batches = { batch_size: requiredQuantityForBatch, batches: value }
        }
        if (item.type === 'task' && item.taskProgress !== null) {
            detailMaterialTask.quantityFinished = item.taskProgress
        }
        try {
            // Here the backend will also try to clean any duplicate details
            await detailMaterialTask.save()
        } catch (error) {
            if (error.response && error.response.status === 400 && error.response.data.code === 'ValidationError') {
                runInAction(() => {
                    const messages = []
                    const errors = error.response.data.errors['detail_material_task']['null']['batches']
                    // eslint-disable-next-line
                    for (const validation_error of errors) {
                        messages.push({
                            ...validation_error,
                            path: [],
                        })
                    }
                    this.errors = messages
                })
            }
        }
        // Throwing the error here informs the user that something went wrong in the backend.
        // The detailMaterialTask.save() starts a cleanup that should fix the duplicate details.
        // If it's still not fixed, the error will show up.
        await detailMaterialTasks.fetch()
        unfinishedDetails = detailMaterialTasks.filter((detail) => detail.value !== true)
        if (unfinishedDetails.length > 1){
            showErrorNotification('There is more than 1 active detail on item ' + item.description)
            throw new Error('too_many_details')
        }
    }

    async saveItems(fields, type) {
        // eslint-disable-next-line
        for (const field of fields) {
            const items = type === 'task' ? this.savedTasks : this.savedMaterials
            // eslint-disable-next-line
            for (const item of items) {
                const value = this.data.form_data[field.id][item.id]
                if (!value && !item.taskProgress) return
                await this.intermediateSaveItem(item, value)
            }
        }
    }

    async onPrintProgress(selectedPrinter) {
        const { productionRequest } = this.props
        const [printerType, allPrinters, template] = await Promise.all([
            getGlobalValue('printer_model'),
            getPrinters(),
            getGlobalValue('sub_batches_print_label')
        ])
        const instructions = (
            template
                .replace(/\{\{order_id\}\}/g, productionRequest.productionOrder?.id)
                .replace(/\{\{article_type_code\}\}/g, productionRequest.articleType?.code)
        )

        if (selectedPrinter === null) {
            const printers = allPrinters.filter((printer) => getPrinterType(printer) === printerType)
            const printer = printers.length > 0 ? printers[0] : null
            print(printer, instructions)
            return
        }
        else {
            print(selectedPrinter, instructions)
            return
        }
    }

    async saveBoth(taskFields, materialFields) {
        this.saveIsLoading = true
        await this.saveItems(taskFields, 'task')
        await this.saveItems(materialFields, 'material')
    }

    async saveThenPrint(taskFields, selectedPrinter) {
        this.saveIsLoading = true
        await this.saveItems(taskFields, 'task')
        // eslint-disable-next-line
        for (const field of taskFields) {
            await this.onPrintProgress(selectedPrinter)
        }
    }

    renderMaterialsTasksButton(form, selectedPrinter) {

        const materialFields = form.fields.filter(field => field.type === 'material_plan_material')
        const taskFields = form.fields.filter(field => field.type === 'material_plan_task')
        if (taskFields.length === 0 && materialFields.length === 0) {
            return null
        }

        return (
            <>
                <Button primary data-test-save-progress-button
                    icon="save"
                    labelPosition="left"
                    content={t('workStation.production.performModal.formStep.saveProgress')}
                    disabled={this.savedTasks.length === 0 && this.savedMaterials.length === 0}
                    onClick={async () => {
                        try {
                            await this.saveBoth(taskFields, materialFields).then(() => {
                                showSaveNotification()
                            }).finally(() => {
                                this.saveIsLoading = false
                            })
                        }
                        catch (e) {
                            if (e.message !== 'too_many_details'){
                                throw e
                            }
                        }
                    }}
                    loading={this.saveIsLoading}
                />
                <Button primary data-test-print-progress-button
                    icon="print"
                    labelPosition="left"
                    disabled={this.savedTasks.length === 0 || selectedPrinter === null}
                    content={t('workStation.production.performModal.formStep.printProgress')}
                    onClick={async () => {
                        try {
                            await this.saveThenPrint(taskFields, selectedPrinter).then(() => {
                                showSaveNotification()
                            }).finally(() => {
                                this.saveIsLoading = false
                            })
                        }
                        catch (e) {
                            if (e.message !== 'too_many_details')
                                throw e
                        }
                    }}
                    loading={this.saveIsLoading}
                />
                <RightDivider />
            </>
        )
    }

    renderButtons() {
        const { step } = this.props
        return (
            <>
                {this.renderMaterialsTasksButton(step.formStep.form)}
                {super.renderButtons()}
            </>
        )
    }
}
