import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { computed, observable, action } from 'mobx'
import { observer } from 'mobx-react'
import { Form, Segment, Button, Icon } from 'semantic-ui-react'
import styled from 'styled-components'
import { TargetDatePicker, TargetTimeRangePicker, TargetSelect } from 'spider/semantic-ui/Target'
import RightDivider from 'spider/component/RightDivider'
import moment from 'moment'

// components
import TargetDuration from 'component/TargetDuration'
// end components

// helpers
import { DATE_FORMAT } from 'helpers'
// end helpers

// stores
import { WorkSchedule, WorkScheduleStore } from 'store/WorkSchedule'
import { WorkEmployer, WorkEmployerStore } from 'store/WorkEmployer'
import { WorkSlot } from 'store/WorkSlot'
import { GlobalValue } from 'store/GlobalValue'
// end stores

const WorkScheduleContainer = styled(Segment)`
  position: relative;
  padding: 0.75rem !important;
`

export const WorkSchedulesContainer = styled(Segment)`
  margin-top: 0 !important;
  background-color: #f4f5f6 !important;
  padding: 0.75rem !important;
`

const SmallFieldGroup = styled(Form.Group)`
  > .field {
      min-width: 0 !important;
  }
`

const CornerIcon = styled(Icon)`
  position: absolute;
  top: 0.5rem;
  right: ${({ offset = 0 }) => offset * 1.5 + 0.5}rem;
  margin: 0 !important;

  width: 1.5rem !important;
  height: 1.5rem !important;
  line-height: 1.5rem !important;

  cursor: pointer;
  opacity: 0.5 !important;
  &:hover {
    opacity: 0.75 !important;
  }

  transition: opacity 300ms ease;
`

export const WorkSlotContainer = styled.div`
  display: flex;
  > .field {
    margin-bottom: 0 !important;
  }
  > .ui.buttons,
  > .field {
    font-size: 1em !important;
    margin-right: 0.75em !important;
  }
  > .ui.button {
    margin-right: 0 !important;
  }

  margin-bottom: 0.5rem;
  &:last-child {
    margin-bottom: 0;
  }
`

const WorkScheduleDescription = styled.div`
  line-height: 1rem;
  ${({ open }) =>
    open
      ? `
        margin-bottom: 1rem;
    `
      : ''}
`

export const EmptyContainer = styled.div`
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  font-style: italic;
  font-size: 1.25em;
  margin-bottom: 1rem;
`

export const ButtonContainer = styled.div`
  text-align: ${({ align = 'center' }) => align};
  > .ui.button {
    margin: 0 !important;
  }
`

@observer
class WorkScheduleSegment extends Component {
  static propTypes = {
    workSchedule: PropTypes.instanceOf(WorkSchedule).isRequired,
    onDelete: PropTypes.func,
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
  }

  static defaultProps = {
    disabled: false,
  }

  constructor(...args) {
    super(...args)

    this.renderWorkSlot = this.renderWorkSlot.bind(this)
    this.addWorkSlot = this.addWorkSlot.bind(this)
  }

  renderWorkSlot(workSlot) {
    const { workSchedule, disabled } = this.props

    return (
      <WorkSlotContainer key={workSlot.cid}>
        <Button.Group>
          {WorkSlot.DAYS.map((day) => (
            <Button
              key={day}
              className="icon"
              content={t(`workSlot.field.${day}.label`)}
              active={workSlot[day]}
              onClick={() => workSlot.setInput(day, !workSlot[day])}
              disabled={disabled}
            />
          ))}
        </Button.Group>
        <TargetTimeRangePicker
          noLabel
          noPopup
          target={workSlot}
          startName="startTime"
          endName="endTime"
          disabled={disabled}
        />
        {!disabled && (
          <React.Fragment>
            <RightDivider />
            <Button icon="trash alternate" onClick={() => workSchedule.workSlots.remove(workSlot)} />
          </React.Fragment>
        )}
      </WorkSlotContainer>
    )
  }

  addWorkSlot() {
    const { workSchedule } = this.props
    workSchedule.workSlots.add({})
  }

  @observable workEmployerStore = new WorkEmployerStore()

  render() {
    const { workSchedule, open, setOpen, onDelete, disabled } = this.props

    return (
      <WorkScheduleContainer data-test-work-schedule={workSchedule.id}>
        <CornerIcon name={`chevron ${open ? 'up' : 'down'}`} onClick={() => setOpen(!open)} />
        {onDelete && <CornerIcon data-test-remove-work-schedule offset={1} name="trash alternate" onClick={onDelete} />}
        <WorkScheduleDescription open={open}>
          {t('operator.edit.workScheduleDescription', {
            startDate: workSchedule.startDate ? workSchedule.startDate.format(DATE_FORMAT) : '-',
          })}
        </WorkScheduleDescription>
        {open && (
          <React.Fragment>
            <Form.Group widths="equal">
              <TargetDatePicker target={workSchedule} name="startDate" disabled={disabled} />
              <TargetSelect
                remote
                clearable
                allowAdditions
                target={workSchedule}
                name="employer"
                placeholder={t('workSchedule.field.employer.placeholder')}
                store={this.workEmployerStore}
                toOption={(employer) => ({
                  value: employer.id,
                  text: employer.name,
                })}
                searchKey=".name:icontains"
                onAddItem={async (e, { value }) => {
                  const employer = new WorkEmployer({ name: value })
                  await employer.save()
                  workSchedule.setInput('employer', employer)
                }}
              />
            </Form.Group>
            <SmallFieldGroup widths="equal">
              <TargetDuration noSeconds target={workSchedule} name="allowedLegalLeaveMinutes" disabled={disabled} />
              <TargetDuration noSeconds target={workSchedule} name="allowedExtraLeaveMinutes" disabled={disabled} />
              <TargetDuration noSeconds target={workSchedule} name="allowedRwhMinutes" disabled={disabled} />
            </SmallFieldGroup>
            <Form.Field>
              <label>{t('workSchedule.field.workSlots.label')}</label>
              {workSchedule.workSlots.length === 0 ? (
                <EmptyContainer>{t('workSchedule.field.workSlots.empty')}</EmptyContainer>
              ) : (
                  workSchedule.workSlots.map(this.renderWorkSlot)
                )}
              {!disabled && (
                <ButtonContainer align="right">
                  <Button data-test-add-work-times icon="add" onClick={this.addWorkSlot} />
                </ButtonContainer>
              )}
            </Form.Field>
          </React.Fragment>
        )}
      </WorkScheduleContainer>
    )
  }
}

@observer
export default class WorkSchedules extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    workSchedules: PropTypes.instanceOf(WorkScheduleStore).isRequired,
    disabled: PropTypes.bool,
  }

  static defaultProps = {
    disabled: false,
  }

  @observable openWorkScheduleInitial = true
  @observable openWorkScheduleOverride = null

  @computed get openWorkSchedule() {
    const { workSchedules } = this.props
    if (this.openWorkScheduleInitial) {
      const today = moment().endOf('day')
      return (
        // Currently active schedule, we reverse and then take the
        // first schedule with a start date before the current day
        workSchedules.models
          .slice()
          .reverse()
          .find((workSchedule) => workSchedule.startDate.isSameOrBefore(today)) ||
        // If there is no active work schedule we will take the first
        // work schedule to become active, since all schedules have yet
        // to start this will thus always be the first
        (workSchedules.length > 0 && workSchedules.at(0)) ||
        // We only get here if there are no work schedules, so just
        // return null
        null
      )
    } else {
      return this.openWorkScheduleOverride
    }
  }

  set openWorkSchedule(workSchedule) {
    this.openWorkScheduleInitial = false
    this.openWorkScheduleOverride = workSchedule
  }

  constructor(...args) {
    super(...args)

    this.renderWorkSchedule = this.renderWorkSchedule.bind(this)
    this.addWorkSchedule = this.addWorkSchedule.bind(this)
  }

  renderWorkSchedule(workSchedule, i, workSchedules) {
    const { disabled } = this.props

    return (
      <WorkScheduleSegment
        key={workSchedule.cid}
        workSchedule={workSchedule}
        open={workSchedule === this.openWorkSchedule}
        setOpen={(open) => (this.openWorkSchedule = open ? workSchedule : null)}
        onDelete={
          workSchedule.startDate.isSameOrAfter(moment().startOf('day'))
            ? () => this.props.workSchedules.remove(workSchedule)
            : undefined
        }
        disabled={disabled}
      />
    )
  }

  @action async addWorkSchedule() {
    const { workSchedules } = this.props

    const operatingHours = new GlobalValue({ key: 'operating_hours' })
    const vacationMinutes = new GlobalValue({ key: 'legal_vacation_minutes' })
    const rwhMinutes = new GlobalValue({ key: 'rwh_minutes' })
    const extraVacationMinutes = new GlobalValue({ key: 'extra_vacation_minutes' })

    await Promise.all([
      operatingHours.fetch(),
      vacationMinutes.fetch(),
      rwhMinutes.fetch(),
      extraVacationMinutes.fetch(),
    ])

    this.openWorkSchedule = workSchedules.add({
      startDate: moment().startOf('day'),
      allowedLegalLeaveMinutes: vacationMinutes.value,
      allowedExtraLeaveMinutes: extraVacationMinutes.value,
      allowedRwhMinutes: rwhMinutes.value,
      workSlots: operatingHours.value,
    })
  }

  render() {
    const { label, workSchedules, disabled, ...props } = this.props

    return (
      <Form.Field {...props}>
        {label && <label>{label}</label>}
        <WorkSchedulesContainer>
          {workSchedules.length === 0 ? (
            <EmptyContainer>{t('operator.field.workSchedules.empty')}</EmptyContainer>
          ) : (
              workSchedules.models.slice().reverse().map(this.renderWorkSchedule)
            )}
          {!disabled && (
            <ButtonContainer>
              <Button
                data-test-add-work-schedule
                primary
                compact
                labelPosition="left"
                icon="add"
                content={t('form.addButton')}
                onClick={this.addWorkSchedule}
              />
            </ButtonContainer>
          )}
        </WorkSchedulesContainer>
      </Form.Field>
    )
  }
}
