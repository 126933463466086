import React from 'react';
import Decimal from 'decimal.js';
import { humanReadable } from '../../helpers/decimal';
import { theme } from '../../styles';
import styled from 'styled-components';
import { Batch } from '../../store/Batch';
import { ProductionRequest } from '../../store/ProductionRequest';
import { t } from 'i18n';


const ClickableSpan = styled.span`
  cursor: pointer;
`

const Version = styled.span`
  margin-left: 0.25em;
  border-left: 0.1em solid rgba(0, 0, 0, 0.4);
  padding-left: 0.25em;
  color: rgba(0, 0, 0, 0.4);
`

export const SerialNumber = styled.code`
  color: ${theme.primaryColor};
  padding-left: 0.5em;
`

export const Quantity = styled.code`
  color: rgba(0, 0, 0, 0.5);
  padding-left: 0.5em;
`

const Label = styled.span`
  background-color: ${({ color }) => color};
  color: #fff;
  font-size: 0.6em;
  font-weight: bold;
  margin-left: 0.8333em;
  padding: 0.3333em 0.5em;
  border-radius: 0.3333em;
  position: relative;
  bottom: 0.25em;
`

export function targetDescription(target, onClick, isTitle = false): JSX.Element {
  const { articleType } = target.batchType

  const name = articleType.name
  const serialNumber = target.serialNumber

  let node = (
    <React.Fragment key={target.cid}>
      {isTitle && t('targetInfoModal.title')}<br />
      {name}
      {!new Decimal(target.quantity).equals(1) && ( // TODO: add decimal to batch model.
        <Quantity data-test-quantity>({humanReadable(target.quantity)})</Quantity>
      )}
      <SerialNumber data-test-serial-number>{serialNumber}</SerialNumber>
      {target.variableQuantity && (
        <Label data-test-variable-quantity color={theme.primaryColor}>{t('targetInfoModal.variableQuantity')}</Label>
      )}
      {target.scrapReason !== null && (
        <Label color="#db2828">{t('targetInfoModal.scrapped', { reason: target.scrapReason })}</Label>
      )}
    </React.Fragment>
  )
  if (onClick) {
    node = (
      <ClickableSpan onClick={() => onClick(target)} key={target.cid} data-test-target-subassembly={serialNumber}>
        {node}
      </ClickableSpan>
    )
  }
  return node
}

export function targetSubDescription(batch: Batch): JSX.Element {
  // @ts-ignore
  const productionRequest: ProductionRequest = batch.productionRequest;
  // @ts-ignore
  const processVersion = batch && batch.productionRequest.processVersion

  // @ts-ignore
  const articleType = batch.batchType.articleType

  const relatedTags = (
    <>
      {productionRequest.getLink()}
      {!articleType.isNew && articleType.getLink()}
      {batch.getRelatedLinks()}
    </>
  )

  return (
    <div>
      <span style={{ fontSize: '0.6em', opacity: 0.6, margin: '-0.5em 0' }}>
        {processVersion.batchType.description}
        <Version>{processVersion.version}</Version>
      </span>
      {relatedTags}
    </div>
  )
}
