import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observable, action, computed, reaction, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { omit } from 'lodash'
import {
  TargetTextInput,
  TargetSelect,
  TargetCheckbox,
  TargetNumberInput,
  FormLabel,
  InfoIcon,
  TargetDatePicker,
  TargetRadioButtons,
} from 'spider/semantic-ui/Target'
import { AddButton, SaveButton, IconButton, ApplyButton } from 'spider/semantic-ui/Button'
import { Header, Modal, Form, Button, Icon, Popup, Message } from 'semantic-ui-react'
import { Body, ContentContainer, Sidebar } from 're-cy-cle'
import { Link } from 'react-router-dom'
import RightDivider from 'spider/component/RightDivider'
import Scrollbars from 'react-custom-scrollbars'
import {
  PrintStepEdit,
  CarrierStepEdit,
  FormStepEdit,
  SplitStepEdit,
  MultiplierStepEdit,
  SubprocessesStepEdit,
  flattenSteps,
  ByproductStepEdit,
  NestStepEdit
} from 'container/Process/Edit'
import FormGroup from 'spider/semantic-ui/FormGroup'
import styled from 'styled-components'
import { theme } from 'styles'
import { mix } from 'polished'
import ExportStepEdit from '../Process/Step/Export';
import ImportStepEdit from '../Process/Step/Import';
import StepProcessTiming from '../../feature/OEE/Components/StepProcessTiming/StepProcessTiming';

// components
import SmallContent from 'component/SmallContent'
import Toolbar from 'component/Toolbar'
import TargetSerialNumberFormat from 'component/TargetSerialNumberFormat'
import WorkStationSelect from 'component/WorkStationSelect';
import Sections from 'component/Sections'
import Tabs from 'component/Tabs'
import Steps, { stepIcon } from 'component/Steps'
import { EmptyMessageContainer } from 'component/AdminOverview'
import Tooltip, { openCss } from 'component/Tooltip'
import { Metavalues, Metafields, MetafieldsProvider } from 'component/Metafields'
// end components

// helpers
import { shortNumber, DATETIME_FORMAT } from 'helpers'
import sortSteps from 'helpers/sortSteps'
import { isFeatureFlagEnabled } from 'helpers/featureFlags'
// end helpers

// stores
import { ArticleType } from 'store/ArticleType'
import { CopyButton, PasteButton } from 'container/ArticleType/CopyPaste'
import { ProductionRequest } from 'store/ProductionRequest'
import { BatchType, BatchTypeStore, LOCAL_TYPES, TYPE_MAKE } from 'store/BatchType'
import { ProcessVersion } from 'store/ProcessVersion'
import { GlobalValue } from 'store/GlobalValue'
import { FactoryStore } from 'store/Factory'
import { CapabilityStore } from 'store/Capability'
// end stores

const FORM_RELS = [
  'fields.articleType',
  'fields.dataSource',
  'fields.metafield',
  'fields.scanConstraints.leftMetafield',
  'fields.scanConstraints.rightMetafield',
]

export function getRequiredProcessVersionRelations() {
  return [
    'factory',
    'steps.workStation.productionLineVersion.workStations',
    'steps.workStation.productionLineVersion.productionLine.versions.workStations',
    'steps.capabilities',
    'steps.nextStep',
    'steps.sections.parts.textPart',
    'steps.sections.parts.tablePart',
    'steps.sections.parts.imagePart',
    'steps.sections.parts.metaPart.metafield',
    ...FORM_RELS.flatMap((rel) => [
      `steps.formStep.form.${rel}`,
      `steps.splitStep.form.${rel}`,
    ]),
    'steps.printStep',
    'steps.multiplierStep',
    'steps.subprocessesStep',
    'steps.carrierStep',
    'steps.byproductStep',
    'steps.exportStep.integration',
    'steps.importStep.integration',
    'steps.nestStep.nestType',
    'batchType.articleType',
    'batchType.targets',
    'updatedBy',
    'finalizedBy',
  ]
}

const STEP_EDIT = {
  print: PrintStepEdit,
  form: FormStepEdit,
  multiplier: MultiplierStepEdit,
  subprocesses: SubprocessesStepEdit,
  split: SplitStepEdit,
  carrier: CarrierStepEdit,
  byproduct: ByproductStepEdit,
  export: ExportStepEdit,
  import: ImportStepEdit,
  nest: NestStepEdit
}



const NewBatchGroup = styled(FormGroup)`
    border-color: #ff9040;
    > label:first-child {
        color: #ff9040;
    }
`

const FullEmptyMessageContainer = styled(EmptyMessageContainer)`
  margin: 0 !important;
  padding: 25px;
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: rgba(0, 0, 0, 0.2);
`

const SmallForm = styled(Form)`
  max-width: 1100px !important;
  margin: 0 auto;
`

const BatchSizeSwitch = styled.span`
  color: ${theme.accentColor};
  i.icon {
    margin: 0 !important;
  }
`

const BatchSize = styled.span`
  position: relative;
  &:hover > ${Tooltip} {
    ${openCss}
    font-weight: normal;
  }
`

const SmallTargetDatePicker = styled(TargetDatePicker)`
  > .ui.input > input {
    width: 100% !important;
  }
`

export const DraftBanner = styled.span`
  color: rgba(34, 36, 38, 0.45);
  font-size: 1.1rem;
  padding: 0.25rem 0.375rem;
  font-weight: bold;
  background-color: #f8f0d0;
  border-radius: 0.375rem;
  align-self: flex-start;
  margin-left: 0.5rem;
  position: relative;
  top: -0.1rem;
`

export const StepContainer = styled.div`
  display: flex;
  padding: ${() => isFeatureFlagEnabled('blue_skies') ? 50 : 25}px 0 25px;
  text-align: center;
  flex: 0 0 auto;
  background-color: #e0e0e2;
  position: relative;
  overflow-y: hidden;
`

const InfoContainer = styled.div`
  width: 100%;
  height: 100%;
  box-shadow: inset 0 0.1rem 0.3rem rgba(0, 0, 0, 0.025);
  background-color: #f9fafa;
`

const InfoInnerContainer = styled.div`
  padding: 25px 25px 6rem;
`

const InfoHeader = styled.div`
  text-align: center;
  margin: 1.5rem 0 0.5rem;
  font-size: 1.25rem;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.5);
  &:first-child {
    margin-top: 0;
  }
`

const StepContent = styled.div`
  padding: 25px;
`

const ModeToggle = styled.div`
  display: flex;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  margin: 1rem 0;
  background-color: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 1.5rem;
  box-shadow: 0 0.1rem 0.3rem rgba(0, 0, 0, 0.1);
  padding: 0 0.5rem;
  z-index: 100;
`

const ModeToggleOption = styled.div`
  width: 2.5rem;
  height: 3rem;
  text-align: center;
  line-height: 3rem;
  cursor: pointer;
  > i.icon {
    font-size: 1.25rem;
    color: ${({ active }) => (active ? theme.primaryColor : 'rgba(0, 0, 0, 0.5)')};
  }
`

const BatchTypeContainer = styled.div`
  margin: 0 -25px 0.5em;
  padding: 0.75em 25px 0.25em 19px;
  position: relative;
  &:last-child {
    margin-bottom: 0;
  }
  .field > label {
    font-size: 0.8em !important;
    color: rgba(0, 0, 0, 0.5) !important;
    margin: -0.5em 0 0 !important;
  }
  > .field {
    margin-bottom: 0.5em !important;
  }
  > .field:last_child {
    margin-bottom: 0 !important;
  }
  border-left: 6px solid ${({ error }) => mix(error ? 0.1 : 0, '#db2828', '#c0c1c2')};
  background-color: ${({ error }) => mix(error ? 0.1 : 0, '#db2828', '#e0e1e2')};
  ${({ active, error }) =>
    active &&
    `
        border-left-color: ${error ? '#db2828' : theme.primaryColor};
        &:after {
            content: '';
            position: absolute;
            right: 0;
            top: calc(50% - 0.75em);
            width: 0;
            height: 0;
            border-top: 0.75em solid transparent;
            border-right: 0.75em solid #fff;
            border-bottom: 0.75em solid transparent;
            z-index: 1;
        }
    `}
`

const BatchTypeDescriptionGroup = styled(Form.Group)`
  margin: 0 -0.25em !important;
  > .field {
    padding: 0 0.25em !important;
    margin: 0 !important;
    > .input > input {
      padding: 0 !important;
      border: unset !important;
      background-color: ${({ active }) => (active ? '#fff' : 'transparent')} !important;
      font-weight: bold;
      font-size: 1.1em !important;
      margin-bottom: 0.5em !important;
    }
  }
  > i.icon {
    color: rgba(0, 0, 0, 0.33);
    margin: 4px !important;
    line-height: 1;
    &:hover {
      color: rgba(0, 0, 0, 0.67);
    }
    transition: color 300ms ease;
  }
`

@observer
export class ProcessVersionEdit extends Component {
  static propTypes = {
    header: PropTypes.node.isRequired,
    view: PropTypes.string.isRequired,
    baseUrl: PropTypes.string.isRequired,
    batchType: PropTypes.instanceOf(BatchType).isRequired,
    processVersion: PropTypes.instanceOf(ProcessVersion).isRequired,
    machineEnabled: PropTypes.bool,
  }

  constructor(...args) {
    super(...args)

    this.renderStepForm = this.renderStepForm.bind(this)
    this.selectStep = this.selectStep.bind(this)
    this.renderTop = this.renderTop.bind(this)
    this.renderSteps = this.renderSteps.bind(this)

    this.renderStepInfo = this.renderStepInfo.bind(this)
    this.renderInstructions = this.renderInstructions.bind(this)
  }

  @observable workingDays = null

  componentDidMount() {
    this.updateStepReaction = reaction(
      () => `${this.props.batchType.id},${this.props.processVersion.id}`,
      () => this.selectStep(null),
    )
    if (isFeatureFlagEnabled('blue_skies')) {
      const globalValue = new GlobalValue({ key: 'working_days' })
      globalValue.fetch().then(() => this.workingDays = globalValue.value)
    }
  }

  componentWillUnmount() {
    this.updateStepReaction()
  }

  render() {
    const { batchType, processVersion, view, header, baseUrl } = this.props

    if (['on_the_fly', 'on_the_fly_template'].includes(batchType.type)) {
      // On the fly settings screen
      return (
        <SmallContent>
          <Header as="h1">{t('batchType.onTheFly.title')}</Header>
          <p>{t('batchType.onTheFly.description')}</p>
          <Form>
            <TargetSerialNumberFormat allowAnything
              target={batchType}
              name="onTheFlySerialNumberFormat"
            />
            <TargetRadioButtons info
              target={batchType}
              name="onTheFlyType"
              options={BatchType.ON_THE_FLY_TYPES.map((value) => ({
                value,
                text: t(`batchType.field.onTheFlyType.value.${value}`),
                tooltip: t(`batchType.field.onTheFlyType.tooltip.${value}`),
              }))}
            />
            {batchType.onTheFlyType === 'fixed' && (
              <TargetNumberInput noLabel
                target={batchType}
                name="onTheFlySize"
              />
            )}
            <TargetRadioButtons info
              target={batchType}
              name="onTheFlySource"
              options={BatchType.ON_THE_FLY_SOURCES.map((value) => ({
                value,
                text: t(`batchType.field.onTheFlySource.value.${value}`),
                tooltip: t(`batchType.field.onTheFlySource.tooltip.${value}`),
              }))}
            />
          </Form>
        </SmallContent>
      )
    } else {
      return (
        <Tabs
          maxWidth="100%"
          header={header}
          tab={view}
          baseUrl={baseUrl}
          afterHeader={processVersion.draft && <DraftBanner>{t('process.edit.draft')}</DraftBanner>}
          tabs={[
            {
              key: 'steps',
              label: t('process.edit.tabs.steps'),
              icon: 'ellipsis horizontal',
              render: this.renderSteps,
              noPadding: true,
              bgColor: '#E0E0E2',
            },
            {
              key: 'instructions',
              label: t('process.edit.tabs.instructions'),
              icon: 'info',
              render: this.renderInstructions,
              noPadding: true,
              bgColor: '#F9FAFA',
              'data-test-instruction-tab': true,
            },
          ]}
        />
      )
    }
  }

  // Steps

  @observable capabilityStore = new CapabilityStore()

  renderStepForm(step) {
    const { batchType, processVersion, machineEnabled } = this.props

    const StepDetails = STEP_EDIT[step.type]
    const batchSizes = this.stepBatchSizes[step.cid]
    const batchSizeBefore = batchSizes ? batchSizes.before : (Object.values(this.stepBatchSizes).find(({ syncTo }) => syncTo.includes(step)) || { after: null }).after
    const variable = this.stepVariables[step.cid]

    const errors = []

    if (step.type === 'split' && variable.before) {
      errors.push(t('articleType.edit.splitWithVariableQuantity'))
    }
    // eslint-disable-next-line
    for (const { code, message } of (step.actuallyUsefulErrors.type ?? [])) {
      if (['grow_at_start', 'multiplier_at_start'].includes(code)) {
        errors.push(message)
      }
    }

    let batchSizeFormContent = null
    if (batchSizeBefore === null) {
      batchSizeFormContent = t('process.edit.batchSize.value.productionRequest')
    } else if (batchSizeBefore === 1) {
      batchSizeFormContent = t('process.edit.batchSize.value.unit')
    } else {
      batchSizeFormContent = t('process.edit.batchSize.value.batch', { count: batchSizeBefore })
    }

    return (
      <SmallForm data-test-step-form>
        {errors.length > 0 && (
          <Message visible error>{errors.map((error, i) => <div key={i}>{error}</div>)}</Message>
        )}
        {step.type !== 'multipier' && (
          <TargetTextInput target={step} name="label" disabled={!processVersion.draft} />
        )}
        <Form.Field>
          <FormLabel>
            {t('process.edit.batchSize.label')}
            {isFeatureFlagEnabled('blue_skies') && (
              <>
                <RightDivider />
                <Popup
                  trigger={<InfoIcon name="info circle" />}
                  content={t('splitStep.field.newBatchQuantity.info')}
                />
              </>
            )}
          </FormLabel>
          {batchSizeFormContent}
        </Form.Field>
        {batchSizes && (
          <NewBatchGroup
            label={t('process.edit.newBatch.label')}
            info={t('process.edit.newBatch.info')}
            bgColor="#FFF"
          >
            <TargetSerialNumberFormat
              allowAnything={step.type === 'split' && step.splitStep.type === 'provided'}
              target={step}
              name="newBatchSerialNumberFormat"
              disabled={!processVersion.draft}
              onChange={action((value) => {
                step.setInput('newBatchSerialNumberFormat', value)
                // eslint-disable-next-line
                for (const step of batchSizes.syncTo) {
                  step.setInput('newBatchSerialNumberFormat', value)
                }
              })}
            />
            {step.type === 'split' && (
              <Form.Group>
                <TargetNumberInput
                  info={isFeatureFlagEnabled('blue_skies')}
                  target={step.splitStep}
                  name="newBatchQuantity"
                  disabled={!processVersion.draft || step.splitStep.newBatchVariableUseOrderSize}
                  width={8}
                />
                <TargetCheckbox info
                  target={step.splitStep}
                  name="newBatchVariableQuantity"
                  disabled={!processVersion.draft}
                  onChange={action((value) => {
                    step.splitStep.setInput('newBatchVariableQuantity', value);
                    if (!value && (step.splitStep.newBatchVariableQuantityPredetermined || step.splitStep.newBatchVariableUseOrderSize)) {
                      step.splitStep.setInput('newBatchVariableQuantityPredetermined', false);
                      step.splitStep.setInput('newBatchVariableUseOrderSize', false);
                    }
                  })}
                  width={4}
                />
                <TargetCheckbox info
                  target={step.splitStep}
                  name="newBatchVariableQuantityPredetermined"
                  disabled={!processVersion.draft || !step.splitStep.newBatchVariableQuantity}
                  width={4}
                />
                <TargetCheckbox info
                  target={step.splitStep}
                  name="newBatchVariableUseOrderSize"
                  disabled={!processVersion.draft || !step.splitStep.newBatchVariableQuantity}
                  width={4}
                />
              </Form.Group>
            )}
          </NewBatchGroup>
        )}
        {!['multiplier', 'subprocesses', 'grow'].includes(step.type) && (
          <>
            <WorkStationSelect search
              factory={processVersion.factory}
              target={step}
              name="workStation"
              defaultProductionLineVersion={this.defaultProductionLineVersion}
              disabled={!processVersion.draft}
            />
            <TargetSelect
              multiple
              remote
              target={step}
              name="capabilities"
              store={this.capabilityStore}
              toOption={(capability) => ({
                value: capability.id,
                text: capability.name,
              })}
              searchKey=".name:icontains"
              disabled={!processVersion.draft}
            />
            <StepProcessTiming
              step={step}
              disabled={!processVersion.draft}
            />
          </>
        )}
        <StepDetails
          step={step}
          steps={this.steps}
          disabled={!processVersion.draft}
          machineEnabled={machineEnabled}
          batchType={batchType}
          processVersion={processVersion}
          variable={variable}
        />
      </SmallForm>
    )
  }

  @computed get defaultProductionLineVersion() {
    const { processVersion } = this.props
    let productionLineVersion = null
    // eslint-disable-next-line
    for (const step of processVersion.steps.models) {
      if (!step.workStation.productionLineVersion.isNew) {
        if (productionLineVersion === null) {
          productionLineVersion = step.workStation.productionLineVersion
        } else if (productionLineVersion.id !== step.workStation.productionLineVersion.id) {
          return null
        }
      }
    }
    return productionLineVersion
  }

  @observable step = null

  selectStep(step) {
    if (this.step === step) {
      this.step = null
    } else {
      this.step = step
    }
  }

  @computed get stepBatchSizes() {
    return getStepBatchSizes(this.steps, null)
  }

  @computed get stepVariables() {
    return getStepVariables(this.steps)
  }

  renderBatchSize(batchSize) {
    let content = null
    let tooltip = null
    if (batchSize === null) {
      tooltip = t('process.edit.batchSize.value.productionRequest')
      content = <Icon name="clipboard list" />
    } else if (batchSize === 1) {
      tooltip = t('process.edit.batchSize.value.unit')
      content = <Icon name="cube" />
    } else {
      tooltip = t('process.edit.batchSize.value.batch', { count: batchSize })
      content = <React.Fragment>
        <Icon name="cubes" />
        <sub>{shortNumber(batchSize)}</sub>
      </React.Fragment>
    }

    return (
      <BatchSize>
        {content}
        <Tooltip>{tooltip}</Tooltip>
      </BatchSize>
    )
  }

  renderTop(step) {
    const batchSizes = this.stepBatchSizes[step.cid]

    if (batchSizes) {
      const { before, after } = batchSizes
      return (
        <BatchSizeSwitch>
          {this.renderBatchSize(before)}
          {after !== before && (
            <>
              <Icon name="chevron right" />
              {this.renderBatchSize(after)}
            </>
          )}
        </BatchSizeSwitch>
      )
    } else {
      return null
    }
  }

  @computed get hasErrors() {
    const { processVersion } = this.props
    return (
      processVersion.steps
        .filter((step) => (
          // Split on variable batch
          (step.type === 'split' && this.stepVariables[step.cid].before) ||
          // Quantity field on non variable batch
          (
            ['split', 'form'].includes(step.type) &&
            !(
              step.type === 'split'
                ? step.splitStep.newBatchVariableQuantity
                : this.stepVariables[step.cid].before
            ) &&
            step[`${step.type}Step`].form.fields.models.some((field) => field.type === 'quantity')
          )
        ))
        .map((step) => step.cid)
    )
  }

  @computed get addButtonErrors() {
    const { processVersion } = this.props
    const addButtonErrors = {}

    // eslint-disable-next-line
    for (const step of processVersion.steps.models) {
      const nextStep = processVersion.steps.find((s) => s.getInternalId() === step.nextStep.getInternalId())
      if (this.stepVariables[step.cid].after && !nextStep) {
        addButtonErrors[`${step.cid},null`] = [t('articleType.edit.endWithVariableQuantity')]
      }
    }

    return addButtonErrors
  }

  renderSteps() {
    const { batchType, processVersion } = this.props

    let excludeStepTypes = ['subprocesses', 'carrier']
    if (['transfer', 'receive_order'].includes(batchType.type)) {
      excludeStepTypes = ['multiplier', 'split', 'carrier']
    } else if (batchType.type === 'transfer') {
      excludeStepTypes = ['multiplier', 'split', 'subprocesses', 'carrier']
    } else if (batchType.type === 'pick_order') {
      excludeStepTypes = ['multiplier', 'split']
    }

    return (
      <React.Fragment>
        <StepContainer>
          <Steps
            renderTop={this.renderTop}
            editable={processVersion.draft}
            selected={this.step}
            onSelect={this.selectStep}
            steps={processVersion.steps}
            color={theme.primaryColor}
            batchType={batchType}
            batchSize={batchType.quantity}
            excludeStepTypes={excludeStepTypes}
            hasErrors={this.hasErrors}
            addButtonErrors={this.addButtonErrors}
          />
        </StepContainer>
        <StepContent>
          {this.step && processVersion.steps.models.includes(this.step) ? (
            this.renderStepForm(this.step)
          ) : (
            <EmptyMessageContainer>{t('process.edit.noStepSelected')}</EmptyMessageContainer>
          )}
        </StepContent>
      </React.Fragment>
    )
  }

  // Instructions

  @observable layoutEdit = false

  renderStepInfo(step, i) {
    const { processVersion } = this.props
    let isContentEmpty = step.sections.length === 0
    return (
      <React.Fragment key={step.cid}>
        {step.type !== 'multiplier' && (
          <React.Fragment>
            <InfoHeader>
              <Icon name={stepIcon(step)} />
              {step.label}
              {!isContentEmpty && (
                <Button
                  as="a"
                  style={{ marginLeft: '10px', marginTop: '10px' }}
                  href={`${step.api.baseUrl.substring(0, step.api.baseUrl.length - 1)}${step.url}print_instruction/`}
                  target="_blank"
                >
                  Print
                </Button>
              )}
            </InfoHeader>
            <Sections
              editable
              sections={step.sections}
              layoutEdit={this.layoutEdit && processVersion.draft}
              onMoveUp={
                i === 0
                  ? undefined
                  : action((section) => {
                    step.sections.remove(section)
                    this.flatSteps[i - 1].sections.models.push(section)
                    this.flatSteps[i - 1].sections.markChanged()
                  })
              }
              onMoveDown={
                i === this.flatSteps.length - 1
                  ? undefined
                  : action((section) => {
                    step.sections.remove(section)
                    this.flatSteps[i + 1].sections.models.splice(0, 0, section)
                    this.flatSteps[i + 1].sections.markChanged()
                  })
              }
              disabled={!processVersion.draft}
            />
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }

  @computed get steps() {
    const { processVersion } = this.props
    return sortSteps(processVersion.steps.models)
  }

  @computed get flatSteps() {
    return flattenSteps(this.steps)
  }

  renderInstructions() {
    const { processVersion } = this.props;
    return (
      <InfoContainer id={'info-container'}>
        <Scrollbars>
          <InfoInnerContainer>{this.flatSteps.map(this.renderStepInfo)}</InfoInnerContainer>
        </Scrollbars>
        {processVersion.draft && (
          <ModeToggle>
            <ModeToggleOption
              data-test-instruction-edit-content-button
              active={!this.layoutEdit}
              onClick={() => (this.layoutEdit = false)}
            >
              <Icon name="pen" />
            </ModeToggleOption>
            <ModeToggleOption
              data-test-instruction-edit-layout-button
              active={this.layoutEdit}
              onClick={() => (this.layoutEdit = true)}
            >
              <Icon name="th-large" />
            </ModeToggleOption>
          </ModeToggle>
        )}
      </InfoContainer>
    )
  }
}

@observer
class BatchTypeSourceSelect extends Component {
  static propTypes = {
    batchType: PropTypes.instanceOf(BatchType).isRequired,
  }

  sourceStore = new BatchTypeStore()

  constructor(...args) {
    super(...args)

    const { batchType } = this.props
    this.sourceStore.params['.type'] = batchType.type + '_template'
  }

  componentDidMount() {
    this.typeReaction = reaction(
      () => this.props.batchType.type,
      action((type) => {
        this.sourceStore.clear()
        this.sourceStore.params['.type'] = type + '_template'
        this.sourceStore.fetch()
      }),
    )
  }

  componentWillUnmount() {
    this.typeReaction()
  }

  render() {
    const { batchType, ...props } = this.props
    return (
      <TargetSelect remote clearable
        target={batchType}
        name="source"
        store={this.sourceStore}
        toOption={(batchType) => ({
          value: batchType.id,
          text: batchType.description,
        })}
        {...props}
      />
    )
  }
}

@observer
class BatchTypesTable extends Component {
  static propTypes = {
    batchTypeStore: PropTypes.instanceOf(BatchTypeStore).isRequired,
    selectedBatchType: PropTypes.instanceOf(BatchType),
    onSelect: PropTypes.func.isRequired,
    types: PropTypes.array.isRequired,
    selectedVersion: PropTypes.instanceOf(ProcessVersion).isRequired,
  }

  @observable bomFieldsModal = null

  factoryStore = new FactoryStore()

  render() {
    const { types, batchTypeStore, selectedBatchType, selectedVersion, onSelect } = this.props

    return (
      <React.Fragment>
        {batchTypeStore
          .filter((batchType) => LOCAL_TYPES.includes(batchType.type) && !batchType.deleted)
          .map((batchType, i) => (
            <React.Fragment key={batchType.cid}>
              <BatchTypeContainer
                error={batchType.hasErrors}
                active={batchType === selectedBatchType}
                data-test-batch-type={batchType.id || batchType.cid}
                data-test-error={batchType.hasErrors}
              >
                <BatchTypeDescriptionGroup active={batchType === selectedBatchType} widths="equal">
                  <TargetTextInput
                    noLabel
                    fluid
                    target={batchType}
                    name="description"
                    mapErrors={(errors) => [...errors, ...(batchType.backendValidationErrors.__all__ || [])]}
                    placeholder={t('batchType.field.description.label')}
                    onFocus={batchType === selectedBatchType ? undefined : () => onSelect(batchType)}
                  />
                  {batchType.default && (
                    <Popup
                      position="top center"
                      trigger={<Icon name="star" />}
                      content={t('batchType.field.default.label')}
                    />
                  )}
                  <IconButton
                    data-test-edit-batch-type
                    name="edit"
                    active={batchType === selectedBatchType}
                    onClick={() => onSelect(batchType === selectedBatchType ? null : batchType)}
                  />
                  <IconButton
                    data-test-delete-batch-type
                    name="trash"
                    onClick={() => batchTypeStore.remove(batchType)}
                  />
                </BatchTypeDescriptionGroup>
                {batchType === selectedBatchType && (
                  <>
                    <TargetSelect fluid
                      target={batchType}
                      name="type"
                      disabled={selectedVersion.finalized || selectedVersion.version > 1}
                      options={types.map((type) => ({
                        value: type,
                        text: t(`batchType.field.type.value.${type}`),
                      }))}
                      onChange={async (type) => {
                        const bomFields = []
                        if (batchType.type === 'make' && type !== 'make') {
                          // eslint-disable-next-line
                          for (const step of selectedVersion.steps.models) {
                            // eslint-disable-next-line
                            for (const form of [step.formStep.form, step.splitStep.form]) {
                              // eslint-disable-next-line
                              for (const field of form.fields.models) {
                                if (field.type === 'bom') {
                                  bomFields.push({ form, field })
                                }
                              }
                            }
                          }
                        }

                        if (bomFields.length > 0) {
                          await new Promise((resolve, reject) => this.bomFieldsModal = { resolve, reject })
                        }

                        runInAction(() => {
                          batchType.setInput('type', type)
                          // eslint-disable-next-line
                          for (const { form, field } of bomFields) {
                            form.fields.remove(field)
                          }
                        })
                      }}
                      data-test-batch-type-select
                    />
                    <TargetCheckbox
                      target={batchType}
                      name="default"
                      onChange={action((value) => {
                        if (value) {
                          // eslint-disable-next-line
                          for (const batchType_ of batchTypeStore.models) {
                            if (batchType_.type === batchType.type) {
                              batchType_.setInput('default', batchType_ === batchType)
                            }
                          }
                        } else {
                          batchType.setInput('default', false)
                        }
                      })}
                    />
                    <BatchTypeSourceSelect fluid batchType={batchType} />
                    {batchType.type !== 'on_the_fly' && <TargetSelect remote
                      target={selectedVersion}
                      name="factory"
                      store={this.factoryStore}
                      toOption={(factory) => ({
                        value: factory.id,
                        text: factory.name,
                      })}
                      searchKey=".name:icontains"
                      disabled={selectedVersion.finalized || selectedVersion.version > 1}
                    />}
                  </>
                )}
              </BatchTypeContainer>
            </React.Fragment>
          ))}
        <Modal closeIcon
          size="small"
          open={this.bomFieldsModal !== null}
          onClose={() => {
            const { reject } = this.bomFieldsModal
            this.bomFieldsModal = null
            reject()
          }}
        >
          <Modal.Header>{t('articleType.edit.bomFieldsModal.title')}</Modal.Header>
          <Modal.Content>{t('articleType.edit.bomFieldsModal.content')}</Modal.Content>
          <Modal.Actions>
            <RightDivider />
            <ApplyButton compact primary onClick={() => {
              const { resolve } = this.bomFieldsModal
              this.bomFieldsModal = null
              resolve()
            }} />
          </Modal.Actions>
        </Modal>
      </React.Fragment>
    )
  }
}

function getStepBatchSizesBase({ branches, steps }, final, productionRequestQuantity) {
  const batchSizes = {}

  let before = null

  // eslint-disable-next-line
  for (const branch of branches) {
    const res = getStepBatchSizesBase(branch, false, productionRequestQuantity)
    Object.assign(batchSizes, res.batchSizes)
    if (res.after !== null && (before === null || res.after < before)) {
      before = res.after
    }
  }

  let lastBatchSizes = null
  for (let i = 0; i < steps.length; i++) {
    const step = steps[i]
    // Check if step creates a new batch
    if (
      step.type === 'split' || // Batch
      i === (['subprocesses', 'multiplier'].includes(steps[0].type) ? 1 : 0) || // Merge or first
      (i > 0 && steps[i - 1].type === 'multiplier') // Multiplier
    ) {
      let after = before
      if (step.type === 'split' && step.splitStep.newBatchVariableUseOrderSize) {
        after = productionRequestQuantity
      } else if (step.type === 'split') {
        after = (step.splitStep.newBatchQuantity || 1)
      }

      lastBatchSizes = { before, after, final: false, syncTo: [], useOrderSize: step.type === 'split' && step.splitStep.newBatchVariableUseOrderSize }
      batchSizes[step.cid] = lastBatchSizes
      before = after
    } else if (lastBatchSizes) {
      lastBatchSizes.syncTo.push(step)
    }
  }

  if (final && lastBatchSizes) {
    lastBatchSizes.final = true
  }

  return { batchSizes, after: before }
}

export function getStepBatchSizes(steps, productionRequestQuantity) {
  const { batchSizes } = getStepBatchSizesBase(steps, true, productionRequestQuantity)
  return batchSizes
}

function getStepVariablesBase({ branches, steps }) {
  const stepVariables = {}

  let before = false
  // eslint-disable-next-line
  for (const branch of branches) {
    const { stepVariables: subStepVariables, after } = getStepVariablesBase(branch)
    Object.assign(stepVariables, subStepVariables)
    if (after) {
      before = true
    }
  }

  // eslint-disable-next-line
  for (const step of steps) {
    let after = before
    if (step.type === 'split') {
      after = step.splitStep.newBatchVariableQuantity && !step.splitStep.newBatchVariableQuantityPredetermined
    }
    if (
      after &&
      (step.type === 'split' || step.type === 'form') &&
      step[`${step.type}Step`].form.fields.models.some((field) => field.type === 'quantity')
    ) {
      after = false
    }
    stepVariables[step.cid] = { before, after }
    before = after
  }

  return { stepVariables, after: before }
}

function getStepVariables(steps) {
  const { stepVariables } = getStepVariablesBase(steps)
  return stepVariables
}

export function getFakeProductionRequest(articleType) {
  const productionRequest = new ProductionRequest({}, {
    relations: ['articleType', ...articleType.__activeRelations.map((rel) => `articleType.${rel}`)],
  })
  productionRequest.articleType = articleType
  return productionRequest
}

@observer
export default class ArticleTypeEdit extends Component {
  static propTypes = {
    articleType: PropTypes.instanceOf(ArticleType).isRequired,
    batchType: PropTypes.instanceOf(BatchType),
    version: PropTypes.instanceOf(ProcessVersion),

    selectBatchType: PropTypes.func.isRequired,
    addNewBatchType: PropTypes.func.isRequired,

    onClose: PropTypes.func.isRequired,
    afterave: PropTypes.func.isRequired,
    machineEnabled: PropTypes.bool,

    view: PropTypes.string.isRequired,
    baseUrl: PropTypes.string.isRequired,
    afterSave: PropTypes.func
  }

  static defaultProps = {
    machineEnabled: false,
  }

  constructor(...args) {
    super(...args)
    this.save = this.save.bind(this)
    this.finalize = this.finalize.bind(this)
    this.nextVersion = this.nextVersion.bind(this)
  }

  @observable productionRequest = getFakeProductionRequest(this.props.articleType)

  componentDidMount() {
    this.articleTypeReaction = reaction(
      () => this.props.articleType,
      (articleType) => this.productionRequest = getFakeProductionRequest(articleType),
    )
  }

  componentWillUnmount() {
    this.articleTypeReaction()
  }

  async save() {
    const { articleType, batchType, selectBatchType, afterSave } = this.props

    const newTemplate = batchType && batchType.__changes.includes('source')

    if (articleType.isNew) {
      // Save in 2 steps, first article without batch types, then with batch
      // types. This is because a batch type component is auto created, and
      // when you multiput articles with batch types directly, that auto
      // created batch type will be missing from the article.batch_types
      // array posted to the server. This means that the binder thinks it
      // should be removed...
      const basicArticleType = new ArticleType(articleType.toJS(), {
        relations: [
          'batchTypes',
          ...articleType.metavalues.__activeRelations.map((rel) => `metavalues.${rel}`),
        ]
      })
      try {
        await basicArticleType.save({
          onlyChanges: true,
          mapData: (data) => omit(data, 'batch_types'),
          relations: ['metavalues'],
        })
      } catch (e) {
        articleType.__backendValidationErrors = basicArticleType.__backendValidationErrors
        throw e
      } finally {
        articleType.metavalues = basicArticleType.metavalues
      }
      await basicArticleType.fetch()

      runInAction(() => {
        articleType.id = basicArticleType.id
        // eslint-disable-next-line
        for (const batchType of basicArticleType.batchTypes.models) {
          articleType.batchTypes.add(batchType.toJS())
        }
      })
    }

    // We temporarily overwrite toBackendAll so that we can do some extra
    // processing
    const toBackendAll = articleType.toBackendAll
    articleType.toBackendAll = (...args) => {
      const data = toBackendAll.call(articleType, ...args)
      // Collect which steps have changed
      const changedSteps = {}
      // eslint-disable-next-line
      for (const stepData of data.relations.step || []) {
        changedSteps[stepData.id] = stepData
      }
      // Iterate over all process versions
      // eslint-disable-next-line
      for (const batchType of articleType.batchTypes.models) {
        // eslint-disable-next-line
        for (const processVersion of batchType.processVersions.models) {
          // Skip if no steps changed
          if (!processVersion.steps.models.some((step) => changedSteps[step.getInternalId()])) {
            continue
          }
          // Get batch sizes per step
          const stepBatchSizes = getStepBatchSizes(sortSteps(processVersion.steps.models), null)
          // eslint-disable-next-line
          for (const step of processVersion.steps.models) {
            // SKip if no batch sizes
            const batchSizes = stepBatchSizes[step.cid]
            if (!batchSizes) {
              continue
            }
            // Make sure step is included in data
            const stepId = step.getInternalId()
            let stepData = changedSteps[stepId]
            if (stepData === undefined) {
              stepData = { id: stepId }
              data.relations.step.push(stepData)
            }
          }
        }
      }
      return data
    }

    // Just a promise without await first so that the finally block runs
    // immediately after the request is initiated
    let promise
    try {
      promise = articleType.save({
        onlyChanges: true,
        relations: [
          'metafields',
          'metavalues',
          'batchTypes.processVersions.steps.sections.parts.textPart',
          'batchTypes.processVersions.steps.sections.parts.tablePart',
          'batchTypes.processVersions.steps.sections.parts.imagePart',
          'batchTypes.processVersions.steps.sections.parts.metaPart',
          'batchTypes.processVersions.steps.formStep.form.fields.scanConstraints',
          'batchTypes.processVersions.steps.splitStep.form.fields.scanConstraints',
          'batchTypes.processVersions.steps.printStep',
          'batchTypes.processVersions.steps.multiplierStep',
          'batchTypes.processVersions.steps.subprocessesStep',
          'batchTypes.processVersions.steps.byproductStep',
          'batchTypes.processVersions.steps.nestStep',
          'batchTypes.processVersions.steps.exportStep',
          'batchTypes.processVersions.steps.importStep',
          'batchTypes.processVersions.steps.capabilities', // See ticket in github: https://github.com/CodeYellowBV/mobx-spine/issues/86
          ...isFeatureFlagEnabled('carrier_integration') ? [
            'batchTypes.processVersions.steps.carrierStep',
          ] : [],
        ],
      })
    } finally {
      articleType.toBackendAll = toBackendAll
    }

    const res = await promise
    afterSave()

    if (newTemplate) {
      selectBatchType(batchType)
      await batchType.fetch()
    }

    return res
  }

  async finalize() {
    const { version, afterSave } = this.props

    const step = this.step && this.step.id

    await version.finalize()
    await version.fetch()
    this.step = step && version.steps.get(step)
    afterSave()
  }

  async nextVersion() {
    const { version, afterSave, articleType } = this.props

    let step = this.step && this.step.id

    const res = await version.nextVersion()
    step = step && res.meta.step_id_mapping[step]

    // A new process version is created by the backend, and now
    // we do a complete fetch to get that version. The selected
    // batchType is no longer the same one as the fetched one, so
    // we need to update the selected batch type.
    //byproductStep
    // TODO: don't do full refetch, which also most likely will fix
    // the outdated this.selectedBatchType.
    await articleType.fetch()
    const { batchType, selectBatchType } = this.props
    selectBatchType(batchType, null)

    this.step = step && version.steps.get(step)
    afterSave()
  }

  render() {
    const { articleType, batchType, version, addNewBatchType, selectBatchType, view, baseUrl } = this.props

    let popupContent = ''

    if (batchType) {
      if (!version.finalizedAt && !version.updatedAt) {
        popupContent = t('process.edit.noUpdateInfo')
      } else if (version.finalizedAt) {
        popupContent = (
            <>
              <div>{t('process.edit.finalized.label', { date: version.finalizedAt.format(DATETIME_FORMAT) })}</div>
              {!version.finalizedBy.isNew && (
                  <div>{t('process.edit.finalized.by', { user: version.finalizedBy.fullName })}</div>)
              }
            </>
        )
      } else {
        popupContent = (
            <>
              {version.updatedAt && (
                  <div>{t('process.edit.updated.label', { date: version.updatedAt.format(DATETIME_FORMAT) })}</div>)
              }
              {!version.updatedBy.isNew && (
                  <div>{t('process.edit.updated.by', { user: version.updatedBy.fullName })}</div>)
              }
            </>
        )
      }
    }

    return (
      <Body>
        <ContentContainer>
          <Sidebar>
            <Form>
              {!articleType.exactItem.isNew && (
                <TargetTextInput readOnly target={articleType.exactItem} name="code" />
              )}

              {articleType.exactItem.isNew && isFeatureFlagEnabled('blue_skies') && (
                <TargetTextInput required target={articleType} name="code" />
              )}
              <TargetTextInput
                required
                target={articleType.exactItem.isNew ? articleType : articleType.exactItem}
                name="name"
                disabled={!articleType.exactItem.isNew}
              />
              {(articleType.startDate || articleType.endDate) && (
                <Form.Group widths="equal">
                  <SmallTargetDatePicker disabled target={articleType} name="startDate" />
                  <SmallTargetDatePicker disabled target={articleType} name="endDate" />
                </Form.Group>
              )}
              <TargetRadioButtons
                target={articleType}
                name="myTrackBatchUsage"
                options={[
                  { text: t('form.no'), value: false },
                  { text: t('form.yes'), value: true },
                ]}
                subLabel={(
                  <Popup hoverable
                    position='bottom right'
                    trigger={!articleType.classification.isNew && (
                      <TargetCheckbox
                        noLabel rightLabel
                        name="followClassification"
                        label={t('articleType.field.trackBatchUsage.followClassificationCheckbox')}
                        value={articleType.myTrackBatchUsage === null}
                        onChange={checked => {
                          articleType.setInput('myTrackBatchUsage', checked ? null : true)
                        }}
                      />
                    )}
                    content={
                      <>
                        <p>{t('articleType.field.trackBatchUsage.followClassificationHelpLine1', { classification: articleType.classification })} "<b>{articleType.classification.name}</b>".</p>
                        <p>{t('articleType.field.trackBatchUsage.followClassificationHelpLine2', { classification: articleType.classification })} "<b>{t(`form.${articleType.classification.trackBatchUsage ? 'yes' : 'no'}`)}".</b></p>
                      </>
                    }
                  />
                )}
              />
              <Metavalues model={articleType} />
              <Metafields model={articleType} />
              <Form.Field>
                <label>{t('articleType.field.batchTypes.label')}</label>
                <BatchTypesTable
                  batchTypeStore={articleType.batchTypes}
                  selectedBatchType={batchType}
                  selectedVersion={version}
                  onSelect={selectBatchType}
                  types={articleType.isMake ? LOCAL_TYPES : LOCAL_TYPES.filter((type) => type !== TYPE_MAKE)}
                />
                <AddButton
                  fluid
                  primary
                  data-test-add-batch-type
                  disabled={articleType.isLoading}
                  icon="add"
                  onClick={addNewBatchType}
                />
              </Form.Field>
            </Form>
          </Sidebar>
          {!batchType ? (
            <FullEmptyMessageContainer>{t('process.edit.noBatchTypeSelected')}</FullEmptyMessageContainer>
          ) : (
            <MetafieldsProvider includeParentLevels model={this.productionRequest}>
              <ProcessVersionEdit
                header={t(`process.${batchType.isNew ? 'create' : 'edit'}.title`)}
                view={view}
                baseUrl={baseUrl + '/'}
                batchType={batchType}
                processVersion={version}
              />
            </MetafieldsProvider>
          )}
        </ContentContainer>
        <Toolbar>
          {batchType && batchType.type !== 'on_the_fly' && !version.isLoading && (
            <>
              <CopyButton compact processVersion={version} />
              {version.draft && <PasteButton compact processVersion={version} />}
            </>
          )}
          <RightDivider />
          {batchType && (
            <React.Fragment>
              <Button.Group compact>
                <Button
                  icon="chevron left"
                  disabled={version.isLoading || version.version === 1}
                  as={Link}
                  to={`/assets/article-type/${articleType.id}/batch-type/${batchType.id}/version/${version.version - 1
                    }/edit/${view}`}
                />
                <Popup
                  trigger={
                    <Button
                      loading={version.isLoading}
                      content={t('process.edit.version', { version: version.version })}
                    />
                  }
                  content={popupContent}
                />
                <Button
                  icon="chevron right"
                  disabled={version.isLoading || version.latest}
                  as={Link}
                  to={
                    version.version + 1 === version.latestVersion
                      ? `/assets/article-type/${articleType.id}/batch-type/${batchType.id}/edit/${view}`
                      : `/assets/article-type/${articleType.id}/batch-type/${batchType.id}/version/${version.version + 1
                      }/edit/${view}`
                  }
                />
              </Button.Group>
              {this.props.view === 'instructions' && (
                <Button
                  as="a"
                  href={`${version.api.baseUrl.substring(0, version.api.baseUrl.length - 1)}${version.url
                    }print_instructions/`}
                  primary
                  compact
                  loading={version.isLoading}
                  disabled={version.hasUserChanges}
                  icon="check"
                  labelPosition="left"
                  content={'Print All Instructions'}
                  target="_blank"
                />
              )}
              {!version.isLoading && version.draft && (
                <Button
                  primary
                  compact
                  data-test-finalize-button
                  loading={version.isLoading}
                  disabled={version.isNew || version.hasUserChanges}
                  onClick={this.finalize}
                  icon="check"
                  labelPosition="left"
                  content={t('process.edit.finalizeButton')}
                />
              )}
              {!version.isLoading && version.latest && !version.draft && batchType.source.isNew && (
                <Button
                  primary
                  compact
                  data-test-next-version-button
                  loading={version.isLoading}
                  disabled={version.hasUserChanges}
                  onClick={this.nextVersion}
                  icon="add"
                  labelPosition="left"
                  content={t('process.edit.nextVersionButton')}
                />
              )}
            </React.Fragment>
          )}
          <SaveButton primary compact data-test-save-button loading={articleType.isLoading} onClick={this.save} />
        </Toolbar>
      </Body>
    )
  }
}
