import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Popup, Table, Loader } from 'semantic-ui-react';
import { ProductionRequest } from 'store/ProductionRequest';
import { BillOfMaterialVersion } from 'store/BillOfMaterialVersion';
import { multiplyDecimals } from 'helpers/decimal'

/**
 * Get the warehouseStorage location in the same warehouse as the productionRequest for the materialPlanItem
 */
export function getWarehouseStorageLocations(productionRequest, materialPlanItem) {
  return materialPlanItem.articleType.storageLocations.filter(st => st.warehouse.id === productionRequest.productionOrder.warehouse.id)
}

/**
 * Do this seperately, to get the data parsed seperately, which makes the inital load 100x faster,
 */
@observer
class MaterialPlanPopUpContent extends Component {
  static propTypes = {
    productionRequest: PropTypes.instanceOf(ProductionRequest)
  }

  constructor(...args) {
    super(...args)

    this.renderMaterialPlanItem = this.renderMaterialPlanItem.bind(this)
  }

  getRequiredQuantity(productionRequest, item) {
    if (item.requiredQuantity !== null) {
      return <Table.Cell collapsing>{+parseFloat(item.requiredQuantity).toFixed(4)}</Table.Cell>
    }

    return (
      <Table.Cell collapsing>
        {multiplyDecimals(item.quantityBatch ?? item.quantity, this.props.productionRequest.requiredQuantity)}
        {' '}({+parseFloat(item.quantityBatch ?? item.quantity).toFixed(4)} x {productionRequest.requiredQuantity})
      </Table.Cell>
    )
  }

  renderMaterialPlanItem(item) {
    const { productionRequest } = this.props

    if (item.handledByErp) {
      // If the item is handled by the ERP, show a grayed out line
      return (
        <Table.Row disabled>
          <Table.Cell collapsing>{item.articleType.getLink()} {item.articleType.name}</Table.Cell>
          {this.getRequiredQuantity(productionRequest, item)}
          <Table.Cell collapsing><i>Handled by ERP</i></Table.Cell>
          <Table.Cell collapsing>-</Table.Cell>
          <Table.Cell collapsing>-</Table.Cell>
        </Table.Row>
      )
    }

    if (getWarehouseStorageLocations(productionRequest, item).length === 0) {
      return (
        <Table.Row warning>
          <Table.Cell collapsing>{item.articleType.getLink()} {item.articleType.name}</Table.Cell>
          {this.getRequiredQuantity(productionRequest, item)}
          <Table.Cell collapsing><i>No stock found for this item</i></Table.Cell>
          <Table.Cell collapsing>-</Table.Cell>
          <Table.Cell collapsing>-</Table.Cell>
        </Table.Row>
      )
    }

    return getWarehouseStorageLocations(productionRequest, item).map((itemStorageLocation) =>
      <Table.Row warning={itemStorageLocation.stock === 0}>
        <Table.Cell collapsing>{item.articleType.getLink()} {item.articleType.name}</Table.Cell>
        {this.getRequiredQuantity(productionRequest, item)}
        <Table.Cell collapsing>{itemStorageLocation.warehouse.name}</Table.Cell>
        <Table.Cell
          collapsing>{itemStorageLocation.storageLocation.code === '' ? '-' : itemStorageLocation.storageLocation.code}</Table.Cell>
        <Table.Cell collapsing>{+parseFloat(itemStorageLocation.stock).toFixed(4)}</Table.Cell>
      </Table.Row>
    )
  }

  @observable materialPlan = new BillOfMaterialVersion(null, {
    relations: [
      'items.articleType.storageLocations.storageLocation',
      'items.articleType.storageLocations.warehouse',
    ]
  });


  componentDidMount() {
    const { productionRequest } = this.props;
    this.materialPlan.id = productionRequest?.productionOrder?.materialPlan?.id

    // Only fetch if the production request has a material plan. Otherwise we get a crash
    if (this.materialPlan.id) {
      this.materialPlan.fetch()
    }
  }

  render() {
    if (this.materialPlan.isLoading) {
      return (<Loader data-test-loading={true} />);
    }

    const materials = this.materialPlan.items.filter(item => item.type === 'material' && !item.isByproduct).sort((a, b) => a.number - b.number)

    return (
      <div style={{ overflow: 'auto', maxHeight: 500 }} data-test-loading={false} >
        {materials.length === 0 ?
          t('materialPlan.noMaterials')
          : (
            <Table compact style={{ width: '500px' }}>
              <Table.Header>
                <Table.HeaderCell>{t('materialPlan.item')}</Table.HeaderCell>
                <Table.HeaderCell>{t('materialPlan.quantityRequired')}</Table.HeaderCell>
                <Table.HeaderCell>{t('materialPlan.warehouse')}</Table.HeaderCell>
                <Table.HeaderCell>{t('materialPlan.storageLocationCode')}</Table.HeaderCell>
                <Table.HeaderCell>{t('materialPlan.stock')}</Table.HeaderCell>
              </Table.Header>
              <Table.Body>
                {materials.map(this.renderMaterialPlanItem)}
              </Table.Body>
            </Table>
          )}
      </div>
    )
  }

}

/**
 * Popup which shows the materials fo the BOM list
 */
@observer
export default class MaterialPlanPopUp extends Component {
  static propTypes = {
    trigger: PropTypes.node,
    productionRequest: PropTypes.instanceOf(ProductionRequest)
  }


  render() {
    const { trigger, productionRequest } = this.props;

    return (
      <Popup flowing hoverable
        data-test-material-plan-popup
        trigger={trigger}
        content={<MaterialPlanPopUpContent productionRequest={productionRequest} />}
      />
    )
  }
}
