import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observable, computed, action } from 'mobx'
import { observer } from 'mobx-react'
import { ProductionLineStore } from 'store/ProductionLine'
import { ProductionRequest } from 'store/ProductionRequest'
import { ProcessVersion } from 'store/ProcessVersion'
import { ProductionRequestStore } from 'store/ProductionRequest'
import { USER_DEFINED_TYPES } from 'store/BatchType'
import { Body, Content } from 're-cy-cle'
import Toolbar from 'component/Toolbar'
import { Table, Icon, Popup } from 'semantic-ui-react'
import { WeekPicker } from 'daycy'
import styled from 'styled-components'
import { stepIcon, getHeight, SmallRatio } from 'component/Steps'
import sortSteps from 'helpers/sortSteps'
import { getCounts, findStep } from 'helpers/counts'
import { getStepBatchSizes } from 'container/ArticleType/Edit'
import { mix } from 'polished'
import { theme, workstationColors } from 'styles'
import moment from 'moment'
import ScrollModal from 'spider/semantic-ui/ScrollModal'
import TargetInfoModal from 'component/TargetInfoModal'
import { lighten } from 'polished'
import { TargetSelect, serializeWheres } from 'spider/semantic-ui/Target'
import RightDivider from 'spider/component/RightDivider'
import { COLORS } from 'spider/semantic-ui/colors'
import { getSections } from 'container/ProductionRequest/ProductionPerform'
import { PaginationControls, EmptyMessageContainer, StyledTableRow } from 'component/AdminOverview'
import { Step } from 'store/Step'
import { humanReadable } from 'helpers/decimal'
import Decimal from 'decimal.js'


function getStepColor(step) {
  if (!step.workStation.isNew) {
    return COLORS[step.workStation.productionLineVersion.productionLine.color]
  }

  return null
}


const ToolbarIcon = styled(Icon)`
  font-size: 1.25em !important;
  opacity: 0.5 !important;
`

const WideWeekPicker = styled(WeekPicker)`
  width: 20em !important;
`

const Fill = styled.div`
  flex: 1 0 auto;
  width: ${({ weight = 1 }) => weight * 4}em;
`

export function mixedColor(productionLine) {
  return mix(0.2, '#404040', COLORS[productionLine.color])
}

export const ProcessNameDisplay = styled.b`
  opacity: 0.6;
`

export const ProcessVersionDisplay = styled.span`
  color: ${theme.primaryColor};
  border-left: 1px solid rgba(34, 36, 38, 0.25);
  padding-left: 0.5em;
  margin-left: 0.5em;
  font-weight: bold;
`

export const ProductionLineRow = styled(StyledTableRow)`
  ${({ first }) =>
    first
      ? `
        > td {
            background-color: #f9fafb;
            padding-top: 0.25rem !important;
            padding-bottom: 0.25rem !important;
        }
    `
      : ''}
  ${({ warning }) =>
    warning
      ? `
        /* Only way to override semantic ui using !important */
        &.warning.warning.warning.warning.warning {
            background-color: #FFF6E0 !important;
        }
    `
      : ''}
  ${({ mainLineColor }) =>
    mainLineColor
      ? `
      > td {
        border-top: 0.25rem solid ${mainLineColor} !important;
      }
  `
      : ''}
`

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`

const ProductionLineStat = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1.5rem;
  > div:first-child {
    font-size: 1.25rem;
    line-height: 1.5rem;
    height: 1.5rem;
    text-align: center;
    font-weight: bold;
  }
  > div:last-child {
    font-size: 1rem;
    line-height: 1rem;
    height: 1rem;
    text-align: center;
    margin-left: 0.375rem;
  }
`

const StepsOuterContainer = styled.div`
  padding: 0 1rem 0.5rem;
  flex: 1 1 auto;
  ${({ onClick }) =>
    onClick
      ? `
        cursor: pointer;
    `
      : ''}
`

const BranchesContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
`

export const BranchContainer = styled.div`
  display: flex;
  align-items: center;
`

const StepsContainer = styled.div`
  position: relative;
  flex: 1 1 auto;
  display: flex;
  justify-content: space-between;
  padding: 0 1.25rem;
`

const StepsBg = styled.div`
  position: absolute;
  left: 1.25rem;
  top: 2.5rem;
  width: calc(100% - 2.5rem);
  height: 0.5rem;
  border-radius: 0.1875rem;
  background: ${({ color, afterCurrentColor, progress }) =>
    progress !== undefined
      ? `linear-gradient(to right, ${color}, ${color} ${progress * 100}%, ${afterCurrentColor} ${progress * 100
      }%, ${afterCurrentColor})`
      : `${color}`};
`

const StepLineTo = styled.div`
  position: absolute;
  top: 2.5rem;
  right: ${({ dx, dy }) => -Math.hypot(dx, dy) + 0.5}rem;
  width: ${({ dx, dy }) => Math.hypot(dx, dy) + 1.25}rem;
  height: 0.5rem;
  border-radius: 0.25rem;
  transform-origin: 0.25rem 50%;
  transform: rotate(${({ dx, dy }) => Math.atan2(dy, dx)}rad);
  background: ${({ color }) => color};
`

const StepPostCountContainer = styled.div`
  position: absolute;
  top: ${({ height }) => 2 + (height < 0 ? height : 0)}rem;
  right: -${({ width }) => width - 1.25}rem;
  width: ${({ width }) => width}rem;
  height: ${({ height }) => Math.abs(height)}rem;
  text-align: center;
  line-height: ${({ height }) => Math.abs(height)}rem;
`

const StepContainer = styled.div`
  flex: 0 0 auto;
  position: relative;
  width: 6rem;
  height: 6rem;
  margin: -0.5rem -2.5rem;
`

const StepBody = styled.div`
  position: absolute;
  left: 1.25rem;
  top: 1.25rem;
  width: 4rem;
  height: 4rem;
  border-radius: 2em;
  background-color: ${({ current, color }) => current ? lighten(0.15, color) : color};
  text-align: center;
  color: #fff;
  font-weight: bold;
  line-height: 3.75rem;
  > i.icon {
    margin: 0 !important;
    font-size: 2rem;
  }
  ${({ current }) =>
    current
      ? `
        transform: scale(1.25);
    `
      : ''}
  ${({ edge }) =>
    edge
      ? `
        transform: rotate(45deg);
    `
      : ''}
  ${({ workStation, edge, multiplier, color, current }) => {
    if (workStation || edge) {
      return `
        left: 1.5rem;
        top: 1.75rem;
        width: 2.75rem;
        height: 2.75rem;
        border-radius: 0.3125em;
        line-height: 2rem;
      `;
    }
    return multiplier ? `
      left: 1.5rem;
      top: 1.5rem;
      width: 2rem;
      height: 2rem;
      border-radius: 1rem;
      line-height: 2rem;
    ` : '';
  }}
`

const StepCountContainer = styled.div`
  position: absolute;
  text-align: right;
  ${({ multiplier }) =>
    multiplier
      ? `
        top: 0.75rem;
        right: 0.75rem;
        width: calc(100% - 1.5rem);
    `
      : `
        top: 0.625rem;
        right: 0.625rem;
        width: calc(100% - 1.25rem);
    `}
`

const StepCount = styled.span`
  display: inline-block;
  min-width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.75rem;
  font-size: 0.9rem;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  text-align: center;
  line-height: 1.5rem;
  font-weight: bold;
  color: #fff;
  background-color: #212121;
`

const StepLabel = styled.div`
  position: absolute;
  left: 0;
  top: 5.25rem;
  width: 100%;
  text-align: center;
  font-size: 0.9rem;
  font-weight: bold;
  color: ${({ color, current }) => (current ? lighten(0.15, color) : color)};
  ${({ current }) =>
    current
      ? `
        transform: scale(1.1) translate(0, 0.35rem);
    `
      : ''}
  white-space: break-spaces;
  line-height: 1.15em;
`

const BatchContainer = styled.div`
  cursor: pointer;
  border-top: 1px solid rgba(34, 36, 38, 0.15);
  &:first-child {
    border-top: none;
  }
  display: flex;
  align-items: center;
`

const BatchSerialNumber = styled.div`
  padding: 1.5rem;
  font-size: 1.5rem;
  font-family: monospace;
  flex: 0 0 auto;
`

const BatchSteps = styled.div`
  flex: 1 1 auto;
  padding: 0 1rem;
`

function groupable(step) {
  return step.type !== 'multiplier'
}

function extendable(group) {
  return groupable(group.steps[0])
}

function* flatSteps({ branches, steps }) {
  // eslint-disable-next-line
  for (const branch of branches) {
    yield* flatSteps(branch)
  }
  yield* steps
}

export function getFlatStepBatchSizes(steps, productionRequestQuantity) {
  const stepBatchSizes = getStepBatchSizes(steps, productionRequestQuantity)
  const flatStepBatchSizes = {}

  // eslint-disable-next-line
  for (const { cid } of flatSteps(steps)) {
    flatStepBatchSizes[cid] = { before: null, after: null }
  }

  // eslint-disable-next-line
  for (const [cid, { before, after, syncTo, useOrderSize }] of Object.entries(stepBatchSizes)) {
    flatStepBatchSizes[cid] = { before, after, useOrderSize }
    // eslint-disable-next-line
    for (const { cid } of syncTo) {
      flatStepBatchSizes[cid] = { before: after, after, useOrderSize }
    }
  }

  return flatStepBatchSizes
}

export function getInProgress({ branches, steps }, counts) {
  if (Array.isArray(counts)) {
    const targets = counts
    counts = {}
    // eslint-disable-next-line
    for (const target of targets) {
      if (!target.finished && target.scrapReason === null) {
        if (counts[target.lastStep.id] === undefined) {
          counts[target.lastStep.id] = Decimal(0)
        }
        counts[target.lastStep.id] = Decimal.add(counts[target.lastStep.id], target.quantityRemaining)
      }
    }
  }

  if (steps.length > 0) {
    const step = steps[steps.length - 1]
    const inProgress = getInProgress({ branches, steps: steps.slice(0, steps.length - 1) }, counts)
    if (step.type === 'multiplier') {
      return inProgress.div(step.multiplierStep.multiplier).ceil() // TODO: is ceil needed?
    } else {
      return inProgress.add((counts[step.id] || Decimal(0)))
    }
  } else {
    return Decimal.max(Decimal(0), ...branches.map((branch) => getInProgress(branch, counts)))
  }
}

/**
 * get quantity information about a set of steps and their productionRequest, gives number of articles (not number of batches)
 *
 * @param {{steps: Step[]}} tree groups of steps
 * @param {ProductionRequest} productionRequest
 * @returns quantity:           productionRequest.quantity
 *          quantityTodo:       quantity - quantityInProgress - quantityDone
 *          quantityInProgress: Sum of batch.quantity in finished (non-scrapped) batches
 *          quantityDone:       Sum of batch.quantity in finished (non-scrapped) batches
 */
export function getStats(tree, productionRequest) {
  const quantity = productionRequest.quantity
  const quantityDone = productionRequest.batches.models
    .filter((batch) => batch.scrapReason === null && batch.finished && !batch.isSubassembly)
    .reduce((res, batch) => Decimal.add(res, batch.quantity), Decimal(0))

  const quantityInProgress = getInProgress(
    tree,
    productionRequest.batches.models.filter((target) => target.scrapReason === null)
  )
  const quantityTodo = quantity.sub(quantityInProgress).sub(quantityDone)

  return { quantity, quantityTodo, quantityInProgress, quantityDone }
}

const GROUP_VALUE = {
  step: (step) => step,
  workStation: (step) => step.workStation,
}

const GROUP_MEMBER = {
  step: (step, group) => step.id === group.value.id,
  workStation: (step, group) => step.workStation.id === group.value.id,
}

@observer
export class ProductionRequestModal extends Component {
  static propTypes = {
    productionRequestId: PropTypes.number.isRequired,
    processVersion: PropTypes.instanceOf(ProcessVersion).isRequired,
    onBatchSelect: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    color: PropTypes.string.isRequired,
    afterCurrentColor: PropTypes.string.isRequired,
  }

  static defaultProps = {
    color: theme.primaryColor,
    afterCurrentColor: '#C0C0C0',
  }

  // Create a copy so we don't mess up the step counts in th progress view,
  // since this modal needs all batches including the finalized ones.
  @observable productionRequest = new ProductionRequest({
    id: this.props.productionRequestId,
  }, {
    relations: [
      'batches.lastStep',
      'batches.batchType',
      'processVersion.steps.workStation.productionLineVersion.productionLine',
      'processVersion.steps.nextStep',
      'processVersion.steps.formStep',
      'processVersion.steps.multiplierStep',
      'processVersion.steps.splitStep',
    ],
    params: {
      where: serializeWheres({
        batches: {
          'batch_type.type:in': USER_DEFINED_TYPES.join(','),
        },
      }),
    },
  })

  constructor(...args) {
    super(...args)

    this.renderBranch = this.renderBranch.bind(this)
    this.renderTarget = this.renderTarget.bind(this)
  }

  componentDidMount() {
    this.productionRequest.fetch()
  }

  @computed get steps() {
    const { processVersion } = this.props
    return sortSteps(processVersion.steps)
  }

  renderBranch({ branches, steps }, target, beforeStep, i, superbranches) {
    const { processVersion, color, afterCurrentColor } = this.props

    const lastStep = processVersion.steps.get(target.lastStep.id)
    const currentStep = processVersion.steps.get(lastStep.nextStep.id)

    let stepIndex = steps.indexOf(currentStep, 1)
    const done = beforeStep || (steps.length > 0 && steps[steps.length - 1] === lastStep)

    let lineTo = null

    if (superbranches) {
      let leftY = 0
      let rightY = 0

      let j = 0
      // eslint-disable-next-line
      for (const branch of superbranches) {
        const height = getHeight(branch)

        if (j < i) {
          leftY += height
        } else if (j === i) {
          leftY += height / 2
        }

        rightY += height / 2

        j++
      }

      const dx = 3
      const dy = (rightY - leftY) * 4

      lineTo = <StepLineTo color={done ? color : afterCurrentColor} dx={dx} dy={dy} />
    }

    const fillBefore = branches.length !== 0 || (!superbranches && steps.length === 1)
    const fillAfter = !!superbranches || (branches.length === 0 && steps.length === 1)

    let progress
    if (done) {
      progress = 1
    } else if (stepIndex === -1) {
      progress = 0
    } else {
      progress = (stepIndex + (fillBefore ? 1 : 0)) / (steps.length + (fillBefore ? 1 : 0) - (fillAfter ? 0 : 1))
    }

    let branchNode = (
      <StepsContainer key={i} style={superbranches ? { paddingRight: '1rem' } : undefined}>
        <StepsBg
          color={color}
          afterCurrentColor={afterCurrentColor}
          progress={progress}
          data-test-production-request-line-advanced
        />
        {lineTo}
        {fillBefore && <Fill />}
        {steps.map((step, i) => {
          const stepColor = done || i <= stepIndex ? color : afterCurrentColor
          return (
            <React.Fragment key={i}>
              {i !== 0 && <Fill />}
              <StepContainer data-test-production-request-step-container >
                <StepBody current={step === currentStep} color={stepColor} multiplier={step.type === 'multiplier'}>
                  {step.type === 'multiplier' ? (
                    <React.Fragment>
                      {step.multiplierStep.multiplier}
                      <SmallRatio>:1</SmallRatio>
                    </React.Fragment>
                  ) : (
                    <Icon key={step.id} name={stepIcon(step)} />
                  )}
                </StepBody>
                {step.type !== 'multiplier' && (
                  <StepLabel current={step === currentStep} color={stepColor} data-test-production-request-step-label>
                    {step.label}
                  </StepLabel>
                )}
              </StepContainer>
            </React.Fragment>
          )
        })}
        {fillAfter && <Fill />}
      </StepsContainer>
    )

    if (branches.length > 0) {
      branchNode = (
        <BranchContainer key={i}>
          <BranchesContainer>
            {branches.map((branch, i, branches) =>
              this.renderBranch(branch, target, done || stepIndex !== -1, i, branches)
            )}
          </BranchesContainer>
          {branchNode}
        </BranchContainer>
      )
    }

    return branchNode
  }

  renderTarget(target) {
    const { onBatchSelect } = this.props

    return (
      <BatchContainer key={target.cid} onClick={() => onBatchSelect(target)}>
        <BatchSerialNumber data-test-batch-serial-number={target.serialNumber}>{target.serialNumber}</BatchSerialNumber>
        <BatchSteps>
          <StepsOuterContainer wide>{this.renderBranch(this.steps, target, target.finished)}</StepsOuterContainer>
        </BatchSteps>
      </BatchContainer>
    )
  }

  render() {
    const { processVersion, onClose } = this.props

    return (
      <ScrollModal open closeIcon onClose={onClose} size="fullscreen">
        <ScrollModal.Header>
          {processVersion.batchType.articleType.name}
          {' - '}
          {processVersion.batchType.description}
          {' - '}
          {t('process.edit.version', { version: processVersion.version })}
        </ScrollModal.Header>
        <ScrollModal.Content noPadding>
          {this.productionRequest.batches.map(this.renderTarget)}
        </ScrollModal.Content>
      </ScrollModal>
    )
  }
}


const POPUP_MARGIN = 3.5 // in rem

@observer
export class ProductionRequestProgress extends Component {
  static propTypes = {
    productionRequest: PropTypes.instanceOf(ProductionRequest).isRequired,
    color: PropTypes.string,
    onSelect: PropTypes.func,
    onSelectStep: PropTypes.func,
    canSelectStep: PropTypes.func,
    onSelectBefore: PropTypes.func,
    onSelectAfter: PropTypes.func,
    renderTopBefore: PropTypes.func,
    renderTopAfter: PropTypes.func,
    steps: PropTypes.object,
    groupedSteps: PropTypes.object,
    stats: PropTypes.object,
    groupBy: PropTypes.oneOf(['step', 'workStation']).isRequired,
    workStationCode: PropTypes.string,
    includeQuantityDone: PropTypes.bool,
    showLabels: PropTypes.bool,
    selectedStep: PropTypes.instanceOf(Step),
  }

  static defaultProps = {
    color: theme.primaryColor,
    groupBy: 'step',
    showLabels: true,
  }

  constructor(...args) {
    super(...args)
    this.renderStep = this.renderStep.bind(this)
    this.renderBefore = this.renderBefore.bind(this)
    this.renderAfter = this.renderAfter.bind(this)
    this.renderGroup = this.renderGroup.bind(this)
    this.renderBranch = this.renderBranch.bind(this)
    this.renderSection = this.renderSection.bind(this)
  }

  @computed get steps() {
    const { productionRequest, steps } = this.props
    if (steps) {
      return steps
    }
    return sortSteps(productionRequest.processVersion.steps)
  }

  @computed get stats() {
    const { productionRequest, stats } = this.props
    if (stats) {
      return stats
    }
    return getStats(this.steps, productionRequest)
  }

  @computed get stepCounts() {
    const { productionRequest, includeQuantityDone } = this.props

    let done = 0
    if (includeQuantityDone) {
      done = productionRequest.quantityDone
    }

    return getCounts({
      steps: this.steps,
      done: done,
      quantity: productionRequest.quantity,
      batches: productionRequest.batches,
      superrequestAtSubprocesses: productionRequest.superrequestAtSubprocesses,
      subrequestsFinished: productionRequest.subrequestsFinished,
    })
  }

  @computed get stepBatchSizes() {
    const { productionRequest } = this.props
    return getFlatStepBatchSizes(this.steps, productionRequest.quantity)
  }

  renderStepCount(prefix, step) {
    const { productionRequest } = this.props

    const articleCount = this.stepCounts[`${prefix}_${step.id}`]

    const batches = productionRequest.batches
      .filter((batch) => findStep(this.steps, batch.lastStep.id)?.nextStep?.id === step.id)

    // T38240: display both the number of articles that still need to be done in this step, as well as the number of batches
    return (
      articleCount.gt(0) &&
      <Popup data-test-step-batch-count-modal
        hoverable
        trigger={
          <StepCount data-test-step-id={step.id} data-test-production-request-id={productionRequest.id} data-test-production-request-step-count data-test-production-perform-count>
            {humanReadable(articleCount)}
          </StepCount>
        }
        content={
          <div style={{ overflow: 'auto', maxHeight: 500 }}>
            {batches.length > 0 ? <Table compact style={{ minWidth: '150px' }}>
              <Table.Header>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell>{t('batch.field.serialNumber.label')}</Table.HeaderCell>
              </Table.Header>
              <Table.Body>
                {batches.map((batch) =>
                  <Table.Row>
                    <Table.Cell>{humanReadable(batch.quantityRemaining)}</Table.Cell>
                    <Table.Cell>{batch.serialNumber}</Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table> : t('batch.batchInfoModal.noBatches')}
          </div>
        }
      />
    )
  }

  renderStepPreCount(step) {
    return this.renderStepCount('pre', step)
  }

  renderStepPostCount(step) {
    return this.renderStepCount('post', step)
  }

  renderStepBase(step, color) {
    const { showLabels, onSelectStep, canSelectStep, selectedStep } = this.props

    const disabled = canSelectStep === undefined || !canSelectStep(step)
    const selected = !selectedStep || (selectedStep && selectedStep?.id === step.id)

    color = color || getStepColor(step)
    const bgColor = selected ? color : workstationColors[color]
    return (
      <StepContainer data-test-production-request-step-container data-test-step={step.id}>
        <StepBody
          data-test-type-step
          color={bgColor}
          multiplier={step.type === 'multiplier'}
          onClick={disabled ? undefined : () => onSelectStep(step)}
        >
          {step.type === 'multiplier' ? (
            <React.Fragment>
              {step.multiplierStep.multiplier}
              <SmallRatio>:1</SmallRatio>
            </React.Fragment>
          ) : (
            <Icon data-test-step-icon data-test-step-id={step.id} key={step.id} name={stepIcon(step)} />
          )}
        </StepBody>
        <StepCountContainer multiplier={step.type === 'multiplier'}>{this.renderStepPreCount(step)}</StepCountContainer>
        {step.type !== 'multiplier' && showLabels && (
          <StepLabel data-test-production-request-step-label color={bgColor}>
            {step.label}
          </StepLabel>
        )}
      </StepContainer>
    )
  }

  renderStep(step, i) {
    return (
      <React.Fragment key={step.id}>
        {i !== 0 && <Fill />}
        {this.renderStepBase(step)}
      </React.Fragment>
    )
  }

  renderGroup({ value, steps, color }, i) {
    const { groupBy } = this.props
    color = color || getStepColor(steps[0])

    let quantity = Decimal(0)
    // eslint-disable-next-line
    for (const step of steps) {
      quantity = quantity.add(this.stepCounts[`pre_${step.id}`])
    }

    const popup = (
      <StepsContainer
        style={{
          width: `${steps.length * (5 + POPUP_MARGIN) - POPUP_MARGIN}rem`,
          margin: '0 1.25rem 1.25rem',
        }}
      >
        <StepsBg color={color} data-test-production-request-line-popup />
        {steps.map(this.renderStep)}
      </StepsContainer>
    )

    let node = null
    switch (groupBy) {
      case 'step':
        node = this.renderStepBase(value, color)
        break
      case 'workStation':
        node = (
          <StepContainer data-test-production-request-step-container>
            <Popup
              position="top center"
              trigger={
                <StepBody
                  data-test-type-work-station
                  workStation
                  color={color}
                  style={{ width: '3em', height: '3em' }}
                />
              }
              content={popup}
            />
            <StepCountContainer>
              {quantity.gt(Decimal(0)) && (
                <StepCount data-test-production-request-step-count>
                  {humanReadable(quantity)}
                </StepCount>
              )}
            </StepCountContainer>
            <StepLabel color={color}>{value.name}</StepLabel>
          </StepContainer>
        )
        break
      default:
        throw new Error(`Unknown groupBy: ${groupBy}`)
    }

    return (
      <React.Fragment key={`group_${i}`}>
        {i !== 0 && <Fill />}
        {node}
      </React.Fragment>
    )
  }

  renderEdge(step, prefix, defaultLabel, color, onSelect) {
    const { onSelectStep } = this.props
    color = color || (step && getStepColor(step))
    color = !onSelectStep ? color : workstationColors[color]
    return (
      <StepContainer data-test-production-request-step-container={defaultLabel}>
        <StepBody workStation={!!step} edge={!step} color={color} onClick={!!step && onSelect ? () => onSelect(step) : undefined} />
        {step && <StepCountContainer>{this.renderStepCount(prefix, step)}</StepCountContainer>}
        <StepLabel color={color}>{step ? step.workStation.name : defaultLabel}</StepLabel>
      </StepContainer>
    )
  }

  renderBefore(step, color) {
    const { onSelectBefore } = this.props
    return this.renderEdge(step, 'total_post', t('step.edit.start'), color, onSelectBefore)
  }

  renderAfter(step, color) {
    const { onSelectAfter } = this.props
    return this.renderEdge(step, 'total_pre', t('step.edit.end'), color, onSelectAfter)
  }

  renderBranch({ branches, steps, before = [], after }, i, superbranches, afterColor) {
    const { groupBy, onSelectStep } = this.props

    const groups = []
    if (steps.length > 0) {
      let group = {
        value: GROUP_VALUE[groupBy](steps[0]),
        steps: [steps[0]],
      }
      // eslint-disable-next-line
      for (const step of steps.slice(1)) {
        if (groupable(step) && extendable(group) && GROUP_MEMBER[groupBy](step, group)) {
          group.steps.push(step)
        } else {
          groups.push(group)
          group = {
            value: GROUP_VALUE[groupBy](step),
            steps: [step],
          }
        }
      }
      groups.push(group)
    }

    let branchCount = branches.length
    if (before.length > 1) {
      branchCount += before.length
    }


    let nodeCount = groups.length
    if (branchCount === 0 && before.length === 1) {
      nodeCount += 1
    }
    if (after !== undefined && !superbranches) {
      nodeCount += 1
    }

    const colors = (
      steps.length === 0
        ? [{ color: null, weight: 1 }]
        : groups.map(({ steps }) => ({ color: getStepColor(steps[0]), weight: 1 }))
    )
    let offset = 0
    if (branchCount === 0) {
      if (before.length === 1) {
        offset++
        colors.splice(0, 0, { color: before[0] && getStepColor(before[0]), weight: 0.5 })
      } else {
        colors[0].weight = 0.5
      }
    }
    if (!superbranches) {
      if (after !== undefined) {
        colors.push({ color: after && getStepColor(after), weight: 0.5 })
      } else {
        colors[colors.length - 1].weight = 0.5
      }
    }

    if (afterColor && colors.every(({ color }) => color === null)) {
      for (let i = 0; i < colors.length; i++) {
        colors[i].color = afterColor
      }
    }

    for (let i = 0; i < colors.length; i++) {
      const start = i;
      while (i < colors.length && colors[i].color === null) {
        i++;
      }
      if (start === i) {
        continue
      }
      const startColor = start === 0 ? null : colors[start - 1].color
      const endColor = i === colors.length ? null : colors[i].color
      let color = ''
      if (startColor !== null && (endColor === null || endColor === startColor)) {
        color = startColor
      } else if (startColor === null && endColor !== null) {
        color = endColor
      } else {
        color = '#808080'
      }
      for (let j = start; j < i; j++) {
        colors[j].color = color
      }
    }

    let color
    if (colors.slice(1).every((color) => color === colors[0])) {
      color = !onSelectStep ? colors[0].color : workstationColors[colors[0].color]
    } else {
      let totalWeight = colors.reduce((totalWeight, { weight }) => totalWeight + weight, 0)
      let cumWeight = 0
      const parts = []
      // eslint-disable-next-line
      for (const { color, weight } of colors) {
        let _color = !onSelectStep ? color : workstationColors[color]
        parts.push(`${_color} ${cumWeight / totalWeight * 100}%`)
        cumWeight += weight
        parts.push(`${_color} ${cumWeight / totalWeight * 100}%`)
      }
      color = `linear-gradient(to right, ${parts.join(', ')})`
    }

    for (let i = 0; i < groups.length; i++) {
      groups[i].color = colors[i + offset].color
    }

    let lineTo = null

    if (superbranches) {
      let leftY = 0
      let rightY = 0

      let j = 0
      // eslint-disable-next-line
      for (const branch of superbranches) {
        const height = getHeight(branch)

        if (j < i) {
          leftY += height
        } else if (j === i) {
          leftY += height / 2
        }

        rightY += height / 2

        j++
      }

      const dx = 3
      const dy = (rightY - leftY) * 4

      const startColor = colors[colors.length - 1].color
      const endColor = afterColor
      let color = ''
      if (startColor === endColor && !onSelectStep) {
        color = startColor
      } else if (startColor === endColor && onSelectStep) {
        color = workstationColors[startColor]
      } else {
        color = `linear-gradient(to right, ${startColor} 0%, ${startColor} 50%, ${endColor} 50%, ${endColor} 100%)`
      }

      lineTo = (
        <React.Fragment>
          <StepLineTo color={color} dx={dx} dy={dy} data-test-production-request-line-45 />
          <StepPostCountContainer data-test-post-step-count width={dx} height={dy}>
            {steps.length > 0 && this.renderStepPostCount(steps[steps.length - 1])}
          </StepPostCountContainer>
        </React.Fragment>
      )
    }

    let branchNode = (
      <StepsContainer key={i} style={lineTo ? { paddingRight: '1rem' } : undefined} data-test-production-request-steps data-test-steps-branch>
        <StepsBg color={color} data-test-production-request-line-simple />
        {lineTo}
        {(branchCount !== 0 || (!superbranches && nodeCount === 1)) && <Fill weight={0.5} />}
        {branchCount === 0 && before.length === 1 && (
          <React.Fragment>
            {this.renderBefore(before[0], colors[0].color)}
            <Fill />
          </React.Fragment>
        )}
        {groups.map(this.renderGroup)}
        {after !== undefined && !superbranches && (
          <React.Fragment>
            <Fill />
            {this.renderAfter(after, colors[colors.length - 1].color)}
          </React.Fragment>
        )}
        {(!!superbranches || (branchCount === 0 && nodeCount === 1)) && <Fill weight={0.5} />}
      </StepsContainer>
    )

    if (branchCount > 0) {
      const beforeBranches = [...before.map((step) => ({ branches: [], steps: [], before: [step] })), ...branches]

      branchNode = (
        <BranchContainer key={i} data-test-production-request-branch>
          <BranchesContainer data-test-production-request-branches>
            {beforeBranches.map((...args) => this.renderBranch(...args, colors[0].color))}
          </BranchesContainer>
          {branchNode}
        </BranchContainer>
      )
    }

    return branchNode
  }

  @computed get sections() {
    const { workStationCode } = this.props

    if (workStationCode) {
      return getSections(this.steps, workStationCode)
    } else {
      return [this.steps]
    }
  }

  renderSection(section, i) {
    return <StepsOuterContainer data-test-production-request-section>{this.renderBranch(section)}</StepsOuterContainer>
  }

  render() {
    const { onSelect } = this.props;
    return (
      <BranchContainer data-test-production-request-progress onClick={onSelect}>
        {this.sections.map(this.renderSection)}
      </BranchContainer>
    )
  }
}

// Duration of how long after a performance an operator counts as active
const ACTIVE_DURATION = { hours: 1 }
// Interval in ms of how often the active operators list should be updated
const ACTIVE_INTERVAL = 60 * 1000

@observer
class ProductionLineProgress extends Component {
  static propTypes = {
    productionRequestStore: PropTypes.instanceOf(ProductionLineStore).isRequired,
    workStationCode: PropTypes.string,
    color: PropTypes.string.isRequired,
    onProductionRequestSelect: PropTypes.func.isRequired,
    week: PropTypes.shape({
      year: PropTypes.number.isRequired,
      week: PropTypes.number.isRequired,
    }).isRequired,
    groupBy: PropTypes.oneOf(['step', 'workStation']).isRequired,
  }

  @observable minPerformedAt = moment().subtract(ACTIVE_DURATION)

  constructor(...args) {
    super(...args)
    this.setMinPerformedAt = this.setMinPerformedAt.bind(this)
  }

  componentDidMount() {
    this.setMinPerformedAt()
    this.setMinPerformedAtInterval = setInterval(this.setMinPerformedAt, ACTIVE_INTERVAL)
  }

  componentWillUnmount() {
    clearInterval(this.setMinPerformedAtInterval)
  }

  setMinPerformedAt() {
    this.minPerformedAt = moment().subtract(ACTIVE_DURATION)
  }

  @computed get activeOperators() {
    const ids = []
    const performances = {}

    // eslint-disable-next-line
    for (const productionRequest of this.productionRequests.models || this.productionRequests) {
      // eslint-disable-next-line
      for (const batch of productionRequest.batches.models) {
        // eslint-disable-next-line
        for (const performance of batch.performances.models) {
          if (
            performance.createdAt.isAfter(
              performances[performance.operator.id]
                ? performances[performance.operator.id].performance.createdAt
                : this.minPerformedAt
            )
          ) {
            if (!performances[performance.operator.id]) {
              ids.push(performance.operator.id)
            }
            performances[performance.operator.id] = { performance, processVersion: productionRequest.processVersion }
          }
        }
      }
    }

    return ids.map((id) => ({
      operator: performances[id].performance.operator,
      workStation: performances[id].processVersion.steps.get(performances[id].performance.step.id).workStation,
    }))
  }

  @computed get productionRequests() {
    const { productionRequestStore } = this.props

    return productionRequestStore
  }

  @computed get productionRequestSteps() {
    const steps = {}
    // eslint-disable-next-line
    for (const productionRequest of this.productionRequests.models || this.productionRequests) {
      steps[productionRequest.id] = sortSteps(productionRequest.processVersion.steps)
    }
    return steps
  }

  @computed get productionRequestStats() {
    const stats = {}
    // eslint-disable-next-line
    for (const productionRequest of this.productionRequests.models || this.productionRequests) {
      stats[productionRequest.id] = getStats(this.productionRequestSteps[productionRequest.id], productionRequest)
    }
    return stats
  }

  @computed get stats() {
    const stats = {
      quantityTodo: 0,
      quantityInProgress: 0,
      quantityDone: 0,
    }

    // eslint-disable-next-line
    for (const productionRequest of this.productionRequests.models || this.productionRequests) {
      const productionRequestStats = this.productionRequestStats[productionRequest.id]
      if (productionRequestStats) {
        stats.quantityTodo += productionRequestStats.quantityTodo
        stats.quantityInProgress += productionRequestStats.quantityInProgress
        stats.quantityDone += productionRequestStats.quantityDone
      }
    }

    return stats
  }

  renderOperator({ operator, workStation }, i) {
    return (
      <div key={operator.id}>
        {operator.fullName} ({workStation.name})
      </div>
    )
  }

  render() {
    const { week, groupBy, workStationCode } = this.props

    let operatorsStatNode = (
      <ProductionLineStat>
        <div>{this.activeOperators.length}</div>
        <div>{t('progress.productionLine.stat.operators', { count: this.activeOperators.length })}</div>
      </ProductionLineStat>
    )

    if (this.activeOperators.length > 0) {
      operatorsStatNode = (
        <Popup position="top center" trigger={operatorsStatNode}>
          {this.activeOperators.map(this.renderOperator)}
        </Popup>
      )
    }

    return (
      <React.Fragment>
        <ProductionLineRow first>
          <Table.Cell colSpan={3}>
            <FlexContainer>
              {/* <ProductionLineTitle>{productionLine.name}</ProductionLineTitle> */}
              <Fill />
              <ProductionLineStat>
                <div>{this.stats.quantityTodo}</div>
                <div>{t('progress.productionLine.stat.todo')}</div>
              </ProductionLineStat>
              <ProductionLineStat>
                <div>{this.stats.quantityInProgress}</div>
                <div>{t('progress.productionLine.stat.inProgress')}</div>
              </ProductionLineStat>
              <ProductionLineStat>
                <div>{this.stats.quantityDone}</div>
                <div>{t('progress.productionLine.stat.done')}</div>
              </ProductionLineStat>
              {operatorsStatNode}
            </FlexContainer>
          </Table.Cell>
        </ProductionLineRow>
        {this.productionRequests.map((productionRequest) => (
          <ProductionLineRow
            key={productionRequest.id}
            warning={productionRequest.year !== week.year || productionRequest.week !== week.week}
          >
            <ProductionRequestProgress
              productionRequest={productionRequest}
              processVersion={productionRequest.processVersion}
              workStationCode={workStationCode}
              steps={this.productionRequestSteps[productionRequest.id]}
              stats={this.productionRequestStats[productionRequest.id]}
              groupBy={groupBy}
            />
          </ProductionLineRow>
        ))}
      </React.Fragment>
    )
  }
}

const GROUP_BY_KEY = 'progress-overview-group-by'

@observer
export default class ProgressOverview extends Component {
  static propTypes = {
    productionRequestStore: PropTypes.instanceOf(ProductionRequestStore).isRequired,
    week: PropTypes.shape({
      year: PropTypes.number.isRequired,
      week: PropTypes.number.isRequired,
    }).isRequired,
    fetchWeek: PropTypes.func.isRequired,
  }

  constructor(...args) {
    super(...args)

    this.onProductionRequestSelect = this.onProductionRequestSelect.bind(this)
    this.onProductionRequestClose = this.onProductionRequestClose.bind(this)
    this.onBatchSelect = this.onBatchSelect.bind(this)
    this.onBatchClose = this.onBatchClose.bind(this)
  }

  @observable groupBy = localStorage.getItem(GROUP_BY_KEY) || 'step'

  @observable selectedProductionRequest = null
  @observable seelctedProcessVersion = null
  @observable selectedBatch = null

  // XXX TODO: Should this receive both?  Seems quite redundant.
  @action onProductionRequestSelect(productionRequest, processVersion) {
    this.selectedProductionRequest = productionRequest
    this.selectedProcessVersion = processVersion
  }

  @action onProductionRequestClose() {
    this.selectedProductionRequest = null
    this.selectedProcessVersion = null
  }

  onBatchSelect(batch) {
    this.selectedBatch = batch
  }

  onBatchClose() {
    this.selectedBatch = null
  }

  render() {
    const { productionRequestStore, week, fetchWeek } = this.props

    let productionLineProgressContent = null
    if (!productionRequestStore.isLoading && productionRequestStore.length === 0) {
      productionLineProgressContent = <EmptyMessageContainer>{t('progress.empty')}</EmptyMessageContainer>
    } else {
      productionLineProgressContent = <Table>
        <Table.Body>
          <ProductionLineProgress
            productionRequestStore={productionRequestStore}
            onProductionRequestSelect={this.onProductionRequestSelect}
            week={week}
            groupBy={this.groupBy}
          />
        </Table.Body>
      </Table>
    }
    let productionRequestModalContent = null
    if (this.selectedBatch) {
      productionRequestModalContent = <TargetInfoModal open target={this.selectedBatch} onClose={this.onBatchClose} />
    } else if (this.selectedProductionRequest) {
      productionRequestModalContent = <ProductionRequestModal
        productionRequestId={this.selectedProductionRequest.id}
        processVersion={this.selectedProcessVersion}
        onBatchSelect={this.onBatchSelect}
        onClose={this.onProductionRequestClose}
      />
    }


    return (
      <Body>
        <Content>
          {productionLineProgressContent}
          {productionRequestModalContent}
        </Content>
        <Toolbar>
          <PaginationControls store={productionRequestStore} />
          <WideWeekPicker includeDates size="small" value={week} onChange={fetchWeek} />
          <RightDivider />
          <Popup trigger={<ToolbarIcon name="object group" />} content={t('progress.groupBy.label')} />
          <TargetSelect
            noLabel
            name="groupBy"
            size="small"
            value={this.groupBy}
            onChange={(groupBy) => {
              this.groupBy = groupBy
              localStorage.setItem(GROUP_BY_KEY, groupBy)
            }}
            options={[
              { value: 'workStation', text: t('progress.groupBy.value.workStation') },
              { value: 'step', text: t('progress.groupBy.value.step') },
            ]}
          />
        </Toolbar>
      </Body>
    )
  }
}
