import React from 'react'
import { observable, computed } from 'mobx'
import { Model, Store, Casts } from 'store/Base'
import { ProductionRequest } from './ProductionRequest'
import { PerformanceStore } from './Performance'
import { Step } from './Step'
import { Detail } from './Detail'
import { LoadCarrier } from './LoadCarrier'
import { BatchType, TYPE_COMPONENT } from './BatchType'
import { BatchUsageStore } from './BatchUsage'
import { ExactGoodsDeliveryLineStore } from './ExactGoodsDeliveryLine'
import { ExactGoodsReceiptLineStore } from './ExactGoodsReceiptLine'
import { MetavalueStore } from './Metavalue'
import { StorageLocation } from './StorageLocation'
import { ProductionRequestLinkStore } from './ProductionRequestLink'
import Decimal from 'decimal.js'

// model relations needed to use batch.getRelatedLinks()
export const RELATED_LINK_RELATIONS = [
'productionRequest.inShipment.purchaseOrder.exactPurchaseOrder',
'productionRequest.inShipmentLine.inShipment.purchaseOrder.exactPurchaseOrder',
'productionRequest.outShipment.salesOrder.exactSalesOrder',
'productionRequest.outShipmentLine.outShipment.salesOrder.exactSalesOrder',
'productionRequest.warehouseTransfer',
'productionRequest.warehouseTransferLine.warehouseTransfer',
'productionRequest.productionOrder.exactShopOrder',
'productionRequest.stockCount',
]

export class Batch extends Model {
  static backendResourceName = 'batch'

  @observable id = null
  @observable serialNumber = ''
  @observable scrapReason = null
  @observable quantity = Decimal(1)
  @observable variableQuantity = false
  @observable expectedQuantity = 1
  @observable resettable = false
  @observable scrappable = false
  @observable finalizedAt = null
  @observable deleted = false

  // Annotations
  @observable finished = false
  @observable quantityRemaining = Decimal(1)
  @observable lastPerformance = null

  @computed
  get isSubassembly() {
    return this.batchType?.type === TYPE_COMPONENT
  }

  toBackend(...args) {
    const res = super.toBackend(...args)
    delete res.finished
    delete res.quantity_remaining
    delete res.resettable
    delete res.last_performance
    return res
  }

  relations() {
    return {
      productionRequest: ProductionRequest,
      lastStep: Step,
      performances: PerformanceStore,

      batchType: BatchType,
      detail: Detail,
      batchUsings: BatchUsageStore,
      batchUseds: BatchUsageStore,

      exactGoodsDeliveryLines: ExactGoodsDeliveryLineStore,
      exactGoodsReceiptLines: ExactGoodsReceiptLineStore,
      loadCarrier: LoadCarrier,

      metavalues: MetavalueStore,
      flatMetavalues: MetavalueStore,

      storageLocation: StorageLocation,
      subProductionRequestBatchLinks: ProductionRequestLinkStore,
    }
  }

  casts() {
    return {
      quantity: Casts.decimal,
      quantityRemaining: Casts.decimal,
      finalizedAt: Casts.datetime,
      lastPerformance: Casts.datetime,
    }
  }

  getRelatedLinks() {
    const inShipment = (
      !this.productionRequest.inShipment.isNew
        ? this.productionRequest.inShipment
        : this.productionRequest.inShipmentLine.inShipment
    )
    const outShipment = (
      !this.productionRequest.outShipment.isNew
        ? this.productionRequest.outShipment
        : this.productionRequest.outShipmentLine.outShipment
    )
    const warehouseTransfer = (
      !this.productionRequest.warehouseTransfer.isNew
        ? this.productionRequest.warehouseTransfer
        : this.productionRequest.warehouseTransferLine.warehouseTransfer
    )
    const productionOrder = this.productionRequest.productionOrder
    const stockCount = this.productionRequest.stockCount

    const purchaseOrder = inShipment.purchaseOrder
    const salesOrder = outShipment.salesOrder

    const erpPurchaseOrder = purchaseOrder.exactPurchaseOrder
    const erpSalesOrder = salesOrder.exactSalesOrder
    const erpProductionOrder = this.productionRequest.productionOrder.exactShopOrder

    return <>
      {!inShipment.isNew && inShipment.getLink()}
      {!purchaseOrder.isNew && purchaseOrder.getLink()}
      {!outShipment.isNew && outShipment.getLink()}
      {!salesOrder.isNew && salesOrder.getLink()}
      {!warehouseTransfer.isNew && warehouseTransfer.getLink()}
      {!productionOrder.isNew && productionOrder.getLink()}
      {!stockCount.isNew && stockCount.getLink()}
      {!erpSalesOrder.isNew && erpSalesOrder.getLink()}
      {!erpPurchaseOrder.isNew && erpPurchaseOrder.getLink()}
      {!erpProductionOrder.isNew && erpProductionOrder.getLink()}
    </>
  }

}

export class BatchStore extends Store {
  Model = Batch
  static backendResourceName = 'batch'
}
