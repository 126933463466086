import React, { Component } from 'react'
import { observable, computed } from 'mobx'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  TargetBase,
  TargetTextInput,
  TargetRadioButtons,
  TargetCheckbox,
  TargetNumberInput,
  TargetSelect,
} from 'spider/semantic-ui/Target'
import { Popup, Icon, Form, Dropdown } from 'semantic-ui-react'
import { t } from 'i18n'

export const SerialNumberPartPropType = PropTypes.oneOfType([
  PropTypes.shape({
    type: PropTypes.oneOf(['text']).isRequired,
    content: PropTypes.string.isRequired,
  }).isRequired,

  PropTypes.shape({
    type: PropTypes.oneOf(['date']).isRequired,
    part: PropTypes.oneOf(['year']).isRequired,
    format: PropTypes.oneOf(['yyyy', 'yy']).isRequired,
  }).isRequired,

  PropTypes.shape({
    type: PropTypes.oneOf(['date']).isRequired,
    part: PropTypes.oneOf(['month']).isRequired,
    format: PropTypes.oneOf(['mm', 'm']).isRequired,
  }).isRequired,

  PropTypes.shape({
    type: PropTypes.oneOf(['date']).isRequired,
    part: PropTypes.oneOf(['month']).isRequired,
    format: PropTypes.oneOf(['text']).isRequired,
    names: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }).isRequired,

  PropTypes.shape({
    type: PropTypes.oneOf(['date']).isRequired,
    part: PropTypes.oneOf(['day']).isRequired,
    format: PropTypes.oneOf(['ddd', 'dd', 'd']).isRequired,
  }).isRequired,

  PropTypes.shape({
    type: PropTypes.oneOf(['date']).isRequired,
    part: PropTypes.oneOf(['isoyear']).isRequired,
    format: PropTypes.oneOf(['yyyy', 'yy']).isRequired,
  }).isRequired,

  PropTypes.shape({
    type: PropTypes.oneOf(['date']).isRequired,
    part: PropTypes.oneOf(['isoweek']).isRequired,
    format: PropTypes.oneOf(['ww', 'w']).isRequired,
  }).isRequired,

  PropTypes.shape({
    type: PropTypes.oneOf(['date']).isRequired,
    part: PropTypes.oneOf(['isoweekday']).isRequired,
    format: PropTypes.oneOf(['d']).isRequired,
  }).isRequired,

  PropTypes.shape({
    type: PropTypes.oneOf(['date']).isRequired,
    part: PropTypes.oneOf(['isoweekday']).isRequired,
    format: PropTypes.oneOf(['text']).isRequired,
    names: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }).isRequired,

  PropTypes.shape({
    type: PropTypes.oneOf(['code']).isRequired,
    alphabet: PropTypes.string.isRequired,
    digits: PropTypes.number.isRequired,
    expand: PropTypes.bool.isRequired,
  }).isRequired,

  PropTypes.shape({
    type: PropTypes.oneOf(['article_type']).isRequired,
    articleTypeProp: PropTypes.string.isRequired,
  }).isRequired,

  PropTypes.shape({
    type: PropTypes.oneOf(['anything']).isRequired,
  }).isRequired,
])

export const SerialNumberFormatPropType = PropTypes.arrayOf(SerialNumberPartPropType.isRequired)

const TYPE_COLORS = {
  text: '#608020',
  date: '#2060A0',
  code: '#A04040',
  anything: '#602080',
  article_type: '#A333C8',
}

const DEFAULT_ALPHABETS = [
  {
    key: 'decimal',
    value: 'decimal',
    text: t('serialNumberFormat.part.code.defaultAlphabets.decimal'),
    alphabet: '0123456789',
  },
  {
    key: 'binary',
    value: 'binary',
    text: t('serialNumberFormat.part.code.defaultAlphabets.binary'),
    alphabet: '01',
  },
  {
    key: 'hexadecimalLower',
    value: 'hexadecimalLower',
    text: t('serialNumberFormat.part.code.defaultAlphabets.hexadecimalLower'),
    alphabet: '0123456789abcdef',
  },
  {
    key: 'hexadecimalUpper',
    value: 'hexadecimalUpper',
    text: t('serialNumberFormat.part.code.defaultAlphabets.hexadecimalUpper'),
    alphabet: '0123456789ABCDEF',
  },
  {
    key: 'octal',
    value: 'octal',
    text: t('serialNumberFormat.part.code.defaultAlphabets.octal'),
    alphabet: '01234567',
  },
  {
    key: 'alphanumericLower',
    value: 'alphanumericLower',
    text: t('serialNumberFormat.part.code.defaultAlphabets.alphanumericLower'),
    alphabet: '0123456789abcdefghijklmnopqrstuvwxyz',
  },
  {
    key: 'alphanumericUpper',
    value: 'alphanumericUpper',
    text: t('serialNumberFormat.part.code.defaultAlphabets.alphanumericUpper'),
    alphabet: '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ',
  },
  {
    key: 'alphabeticLower',
    value: 'alphabeticLower',
    text: t('serialNumberFormat.part.code.defaultAlphabets.alphabeticLower'),
    alphabet: 'abcdefghijklmnopqrstuvwxyz',
  },
  {
    key: 'alphabeticUpper',
    value: 'alphabeticUpper',
    text: t('serialNumberFormat.part.code.defaultAlphabets.alphabeticUpper'),
    alphabet: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
  },
  {
    key: 'custom',
    value: 'custom',
    text: t('serialNumberFormat.part.code.defaultAlphabets.custom'),
    alphabet: '',
  },
]

const ARTICLE_TYPE_PROP_OPTIONS = [
  { value: 'code',    text: t('serialNumberFormat.part.article_type.prop.code') },
  { value: 'barcode', text: t('serialNumberFormat.part.article_type.prop.barcode') },
]

const SerialNumberContainer = styled.div`
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  padding: 0.5em 1em;
  line-height: 1.6em;
  font-family: monospace;
  background-color: #fff;
`

const SerialNumberPart = styled.span`
  padding: 0.17857143em 0.25em;
  font-size: 0.9em;
  cursor: pointer;
  color: ${({ type }) => TYPE_COLORS[type]};
  background-color: ${({ type }) => TYPE_COLORS[type]}18;
  &:hover {
    background-color: ${({ type }) => TYPE_COLORS[type]}30;
  }
  ${({ first }) =>
    first
      ? `
        border-top-left-radius: 0.25em;
        border-bottom-left-radius: 0.25em;
    `
      : ''}
  ${({ last }) =>
    last
      ? `
        border-top-right-radius: 0.25em;
        border-bottom-right-radius: 0.25em;
    `
      : ''}
`

function displayAlphabet(alphabet) {
  // eslint-disable-next-line
  for (const defaultAlphabet of DEFAULT_ALPHABETS) {
    if (defaultAlphabet.alphabet === alphabet) {
      return t(`serialNumberFormat.part.code.defaultAlphabets.${defaultAlphabet.key}`)
    }
  }
  return `'${alphabet.replace('\\', '\\\\').replace('\'', '\\\'')}'`
}

const PopupIcon = styled(Icon)`
  ${({ onClick }) =>
    onClick
      ? `
        cursor: pointer;
        opacity: 0.5 !important;
        &:hover {
            opacity: 0.75 !important;
        }
    `
      : `
        opacity: 0.25 !important;
    `}
  margin: 0 0 0 0.35em !important;
  font-size: 0.8em !important;
  float: right;
`

const MonoTargetTextInput = styled(TargetTextInput)`
  > .ui.input > input {
    font-family: monospace !important;
  }
`

const PART_TYPE_OPTIONS = ['text', 'date', 'code', 'anything', 'article_type'].map((key) => ({
  key,
  text: t(`serialNumberFormat.part.${key}.label`),
  value: key,
}))

function formatOptions(...options) {
  return options.map((key) => ({
    key,
    text: key,
    value: key,
  }))
}

const PART_DATE_PART_OPTIONS = ['year', 'month', 'day', 'isoyear', 'isoweek', 'isoweekday'].map((key) => ({
  key,
  text: t(`serialNumberFormat.part.date.part.${key}.label`),
  value: key,
}))

const PART_DATE_PART_DEFAULT_FORMAT = {
  year: 'yyyy',
  month: 'mm',
  day: 'dd',
  isoyear: 'yyyy',
  isoweek: 'ww',
  isoweekday: 'd',
}

const PART_DATE_FORMAT_OPTIONS = {
  year: formatOptions('yyyy', 'yy'),
  month: formatOptions('mm', 'm', 'text'),
  day: formatOptions('ddd', 'dd', 'd'),
  isoyear: formatOptions('yyyy', 'yy'),
  isoweek: formatOptions('ww', 'w'),
  isoweekday: formatOptions('d', 'text'),
}

const MONTHS = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
]

const WEEKDAYS = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']

const PART_DATE_FORMAT_EXTRAS = {
  year: {
    yyyy: {},
    yy: {},
  },
  month: {
    mm: {},
    m: {},
    text: {
      names: MONTHS.map((key) => t(`serialNumberFormat.part.date.part.month.names.${key}.value`)),
    },
  },
  day: {
    dd: {},
    d: {},
  },
  isoyear: {
    yyyy: {},
    yy: {},
  },
  isoweek: {
    ww: {},
    w: {},
  },
  isoweekday: {
    d: {},
    text: {
      names: WEEKDAYS.map((key) => t(`serialNumberFormat.part.date.part.isoweekday.names.${key}.value`)),
    },
  },
}

const PART_DATE_MONTH_OPTIONS = MONTHS.map((month, i) => ({
  key: i,
  text: t(`serialNumberFormat.part.date.part.month.names.${month}.label`),
  value: i,
}))

const PART_DATE_ISO_WEEKDAY_OPTIONS = WEEKDAYS.map((weekday, i) => ({
  key: i,
  text: t(`serialNumberFormat.part.date.part.isoweekday.names.${weekday}.label`),
  value: i,
}))

function numberFromTarget(value) {
  if (typeof value === 'number') {
    return value.toString()
  } else {
    return ''
  }
}

function numberToTarget(value) {
  if (value === '') {
    return null
  }
  if (value.includes('.')) {
    return parseFloat(value)
  } else {
    return parseInt(value)
  }
}

const Fields = styled.div`
  display: flex;
  align-items: flex-end;
  > * {
    flex: 1 1 0;
    margin-bottom: 0 !important;
    margin-left: 0.5em !important;
  }
  > *:first-child {
    margin-left: 0 !important;
  }
  margin-bottom: 1em;
  &:last-child {
    margin-bottom: 0;
  }
`

@observer
class SerialNumberPartPopup extends Component {
  static propTypes = {
    trigger: PropTypes.node.isRequired,
    value: SerialNumberPartPropType.isRequired,
    onChange: PropTypes.func.isRequired,
    onMoveLeft: PropTypes.func,
    onMoveRight: PropTypes.func,
    onDelete: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    allowAnything: PropTypes.bool,
    disabled: PropTypes.bool,
  }

  static defaultProps = {
    allowAnything: false,
    disabled: false,
  }

  @observable customAlphabet = false

  constructor(...args) {
    super(...args)
    this.onTypeChange = this.onTypeChange.bind(this)

    const { value } = this.props
    this.customAlphabet = value.type === 'code' && !DEFAULT_ALPHABETS.find((a) => a.alphabet === value.alphabet)
  }

  onTypeChange(e, { value: type }) {
    const { value, onChange } = this.props

    if (value.type === type) {
      return
    }

    switch (type) {
      case 'text':
        onChange({
          type: 'text',
          content: '',
        })
        break
      case 'date':
        onChange({
          type: 'date',
          part: 'year',
          format: 'yyyy',
        })
        break
      case 'code':
        onChange({
          type: 'code',
          digits: 8,
          alphabet: '0123456789',
          expand: false,
        })
        break
      case 'anything':
        onChange({
          type: 'anything',
        })
        break
      case 'article_type':
        onChange({
          type: 'article_type',
        })
        break
      default:
      // noop
    }
  }

  renderTextBody() {
    const { value, onChange, disabled } = this.props
    return (
      <Form style={{ fontSize: '0.8em' }}>
        <TargetTextInput
          autoFocus
          label={t('serialNumberFormat.part.text.content')}
          value={value.content}
          onChange={(content) => onChange({ ...value, content })}
          disabled={disabled}
        />
      </Form>
    )
  }

  @observable selectedMonth = 0
  @observable selectedISOWeekday = 0

  renderDateBody() {
    const { value, onChange, disabled } = this.props
    return (
      <Form style={{ fontSize: '0.8em' }}>
        <TargetSelect
          label={t('serialNumberFormat.part.date.part.label')}
          value={value.part}
          options={PART_DATE_PART_OPTIONS}
          onChange={(part) =>
            onChange({
              type: 'date',
              part,
              format: PART_DATE_PART_DEFAULT_FORMAT[part],
              ...PART_DATE_FORMAT_EXTRAS[part][PART_DATE_PART_DEFAULT_FORMAT[part]],
            })
          }
          disabled={disabled}
        />
        <TargetRadioButtons
          size="mini"
          label={t('serialNumberFormat.part.date.format')}
          value={value.format}
          options={PART_DATE_FORMAT_OPTIONS[value.part]}
          onChange={(format) =>
            onChange({
              type: 'date',
              part: value.part,
              format,
              ...PART_DATE_FORMAT_EXTRAS[value.part][format],
            })
          }
          disabled={disabled}
        />
        {value.part === 'month' && value.format === 'text' && (
          <Fields>
            <TargetSelect
              label={t('serialNumberFormat.part.date.part.month.label')}
              value={this.selectedMonth}
              onChange={(month) => (this.selectedMonth = month)}
              options={PART_DATE_MONTH_OPTIONS}
              type="int"
              disabled={disabled}
            />
            <TargetTextInput
              label={t('serialNumberFormat.part.date.part.month.text')}
              value={value.names[this.selectedMonth]}
              onChange={(name) => {
                const names = value.names.slice()
                names[this.selectedMonth] = name
                onChange({ ...value, names })
              }}
              disabled={disabled}
            />
          </Fields>
        )}
        {value.part === 'isoweekday' && value.format === 'text' && (
          <Fields>
            <TargetSelect
              label={t('serialNumberFormat.part.date.part.isoweekday.label')}
              value={this.selectedISOWeekday}
              onChange={(isoWeekday) => (this.selectedISOWeekday = isoWeekday)}
              options={PART_DATE_ISO_WEEKDAY_OPTIONS}
              type="int"
              disabled={disabled}
            />
            <TargetTextInput
              label={t('serialNumberFormat.part.date.part.isoweekday.text')}
              value={value.names[this.selectedISOWeekday]}
              onChange={(name) => {
                const names = value.names.slice()
                names[this.selectedISOWeekday] = name
                onChange({ ...value, names })
              }}
              disabled={disabled}
            />
          </Fields>
        )}
      </Form>
    )
  }

  renderCodeBody() {
    const { value, onChange, disabled } = this.props

    const defaultAlphabet = !this.customAlphabet && DEFAULT_ALPHABETS.find((a) => a.alphabet === value.alphabet)

    return (
      <Form style={{ fontSize: '0.8em' }}>
        <Fields>
          <TargetNumberInput
            autoFocus
            label={t('serialNumberFormat.part.code.digits')}
            value={value.digits}
            fromTarget={numberFromTarget}
            toTarget={numberToTarget}
            onChange={(digits) => onChange({ ...value, digits })}
            disabled={disabled}
          />
          <TargetCheckbox
            noLabel
            rightLabel
            label={t('serialNumberFormat.part.code.expand')}
            value={value.expand}
            onChange={(expand) => onChange({ ...value, expand })}
            disabled={disabled}
          />
        </Fields>
        <Fields>
          <TargetSelect
            label={t('serialNumberFormat.part.code.alphabet')}
            value={defaultAlphabet ? defaultAlphabet.value : 'custom'}
            options={DEFAULT_ALPHABETS}
            onChange={(alphabetValue) => {
              const alphabet = DEFAULT_ALPHABETS.find((a) => a.value === alphabetValue)
              this.customAlphabet = alphabet.key === 'custom'
              onChange({ ...value, alphabet: alphabet.alphabet })
            }}
            disabled={disabled}
          />
          <MonoTargetTextInput
            noLabel
            value={value.alphabet}
            onChange={(alphabet) => onChange({ ...value, alphabet })}
            disabled={disabled || !!defaultAlphabet}
          />
        </Fields>
      </Form>
    )
  }

  renderArticleTypeBody() {
    const { value, onChange, disabled } = this.props
    return (
      <Form style={{ fontSize: '0.8em' }}>
        <TargetSelect data-test-article-type-prop-select
          autoFocus
          label={t('serialNumberFormat.part.article_type.prop.label')}
          value={value.articleTypeProp}
          options={ARTICLE_TYPE_PROP_OPTIONS}
          onChange={(articleTypeProp) => {
            onChange({ ...value, articleTypeProp })
          }}
          disabled={disabled}
        />
      </Form>
    )
  }

  @computed get options() {
    const { allowAnything } = this.props

    let options = PART_TYPE_OPTIONS
    if (!allowAnything) {
      options = options.filter(({ value }) => value !== 'anything')
    }
    return options
  }

  render() {
    const { value, trigger, onClose, onMoveLeft, onMoveRight, onDelete, disabled } = this.props

    let contentBody = null
    if (value.type === 'text') {
      contentBody = this.renderTextBody()
    } else if (value.type === 'date') {
      contentBody = this.renderDateBody()
    } else if (value.type === 'code') {
      contentBody = this.renderCodeBody()
    } else if (value.type === 'article_type') {
      contentBody = this.renderArticleTypeBody()
    }

    return (
      <Popup open flowing on="click" position="top center" trigger={trigger} onClose={onClose} data-test-serial-number-part-popup>
        <Popup.Header>
          <Dropdown inline value={value.type} options={this.options} onChange={this.onTypeChange} disabled={disabled} />
          <PopupIcon name="trash alternate" onClick={onDelete} disabled={disabled} />
          <PopupIcon name="arrow right" onClick={onMoveRight} />
          <PopupIcon name="arrow left" onClick={onMoveLeft} />
        </Popup.Header>
        <Popup.Content>
          {contentBody}
        </Popup.Content>
      </Popup>
    )
  }
}

const AddIcon = styled(Icon)`
  cursor: pointer;
  opacity: 0.25 !important;
  &:hover {
    opacity: 0.5 !important;
  }
  margin: 0 0 0 0.25em !important;
  font-size: 0.75em !important;
  line-height: 1.33em;
`

@observer
export class SerialNumberFormat extends Component {
  static propTypes = {
    value: SerialNumberFormatPropType.isRequired,
    onChange: PropTypes.func.isRequired,
    allowAnything: PropTypes.bool,
    disabled: PropTypes.bool,
  }

  static defaultProps = {
    allowAnything: false,
    disabled: false,
  }

  constructor(...args) {
    super(...args)
    this.renderPart = this.renderPart.bind(this)
    this.onAddPart = this.onAddPart.bind(this)
    this.onChangePart = this.onChangePart.bind(this)
    this.onMovePartLeft = this.onMovePartLeft.bind(this)
    this.onMovePartRight = this.onMovePartRight.bind(this)
    this.onDeletePart = this.onDeletePart.bind(this)
    this.onDeselectPart = this.onDeselectPart.bind(this)
  }

  @observable selected = null

  renderPart(part, i, parts) {
    const { allowAnything, disabled } = this.props

    let partNodeContent = null
      if (part.type === 'text') {
        partNodeContent = part.content
      } else if (part.type === 'date') {
        partNodeContent = `${t(`serialNumberFormat.part.date.part.${part.part}.label`)} (${part.format})`
      } else if (part.type === 'code') {
        partNodeContent = `${displayAlphabet(part.alphabet)} (${part.digits}${part.expand ? '+' : ''})`
      } else if (part.type === 'anything') {
        partNodeContent = '???'
      } else if (part.type === 'article_type') {
        partNodeContent = 'ART_' + (
          (part.articleTypeProp !== undefined && part.articleTypeProp !== null) ? t(`serialNumberFormat.part.article_type.prop.${part.articleTypeProp}`) : 'prop'
        )
      }

    let partNode = (
      <SerialNumberPart
        key={i}
        first={i === 0}
        last={i === parts.length - 1}
        type={part.type}
        onClick={() => this.onSelectPart(i)}
      >
        {partNodeContent}
      </SerialNumberPart>
    )

    if (this.selected === i) {
      return (
        <SerialNumberPartPopup data-test-target-serial-number-format={`${part.type}_${i}`}
          key={`${part.type}_${i}`}
          trigger={partNode}
          value={part}
          onChange={this.onChangePart}
          onMoveLeft={i === 0 ? undefined : this.onMovePartLeft}
          onMoveRight={i === parts.length - 1 ? undefined : this.onMovePartRight}
          onDelete={this.onDeletePart}
          onClose={this.onDeselectPart}
          allowAnything={allowAnything}
          disabled={disabled}
        />
      )
    } else {
      return partNode
    }
  }

  onAddPart() {
    const { value, onChange } = this.props
    onChange([...value, { type: 'text', content: '' }])
    this.selected = value.length
  }

  onChangePart(part) {
    const { value, onChange } = this.props
    onChange([...value.slice(0, this.selected), part, ...value.slice(this.selected + 1)])
  }

  onMovePart(destination) {
    const { value, onChange } = this.props
    const newValue = value.slice()
    const [part] = newValue.splice(this.selected, 1)
    newValue.splice(destination, 0, part)
    onChange(newValue)
    this.selected = destination
  }

  onMovePartLeft() {
    this.onMovePart(this.selected - 1)
  }

  onMovePartRight() {
    this.onMovePart(this.selected + 1)
  }

  onDeletePart() {
    const { value, onChange } = this.props
    onChange([...value.slice(0, this.selected), ...value.slice(this.selected + 1)])
    this.selected = null
  }

  onSelectPart(pos) {
    if (this.selected === pos) {
      this.onDeselectPart()
    } else {
      this.selected = pos
    }
  }

  onDeselectPart() {
    this.selected = null
  }

  render() {
    const { value, onChange, allowAnything, disabled, ...props } = this.props
    return (
      <SerialNumberContainer {...props}>
        {value.map(this.renderPart)}
        {!disabled && <AddIcon name="add" onClick={this.onAddPart} />}
      </SerialNumberContainer>
    )
  }
}

export default class TargetSerialNumberFormat extends TargetBase {
  static propTypes = {
    ...TargetBase.propTypes,
    value: SerialNumberFormatPropType,
  }

  renderContent(props) {
    return <SerialNumberFormat value={this.value} onChange={this.onChange} {...props} />
  }
}
