import { observable, computed } from 'mobx'
import { Model, Store, Casts } from 'store/Base'
import { Warehouse } from './Warehouse'
import { WarehouseTransferLineStore } from './WarehouseTransferLine';
import { ProductionRequest } from './ProductionRequest'

export class WarehouseTransfer extends Model {
  static backendResourceName = 'warehouse_transfer'
  static SOURCES = ['manual_entry', 'import', 'transfer_advice', 'web_service']
  static STATUSES = ['draft', 'processed']
  static idPrefix = 'TR'
  static idColor = 'red'
  static idIcon = 'swap_horizontal_circle_white'

  getMiniTag() {
    return window.viewStore.tracyTagSize === 'mini'
  }

  @observable id = null
  @observable description = ''
  @observable status = 'draft'
  @observable source = 'manual_entry'
  @observable entryDate = null
  @observable plannedDeliveryDate = null
  @observable completedOnDate = null

  @computed
  get type() {
    return this.warehouseFrom.id === this.warehouseTo.id
      ? 'Location transfer' : 'Warehouse transfer'
  }

  getUrl() {
    return `/warehouse/warehouse-transfer?.id=${this.id}`
  }

  relations() {
    return {
      warehouseFrom: Warehouse,
      warehouseTo: Warehouse,
      lines: WarehouseTransferLineStore,
      productionRequest: ProductionRequest,
      targetProductionRequest: ProductionRequest,
    }
  }

  casts() {
    return {
      entryDate: Casts.datetime,
      plannedDeliveryDate: Casts.datetime,
      completedOnDate: Casts.datetime,
    }
  }
}

export class WarehouseTransferStore extends Store {
  Model = WarehouseTransfer
  static backendResourceName = 'warehouse_transfer'
}
