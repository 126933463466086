export default {
  pageNotFoundMessage: 'Wir können diese Seite nicht finden. Ist die URL richtig geschrieben?',
  brandingMessage: 'by Code Yellow',
  nav: {
    main: {
      production: 'Produktion',
      warehouse: 'Logistik',
      planning: 'Planung',
      personnel: 'Mitarbeiter',
      assets: 'Stammdaten',
    },
    production: {
      progress: 'Fortschritt',
      products: 'Artikel',
      batches: 'Produkten',
      productionRequests: 'Arbeitsaufträge',
    },
    planning: {
      productionLines: 'Produktionsbereich',
      productionRequests: 'Arbeitsaufträge',
    },
    warehouse: {
      overview: 'Eingang / Ausgang',
      purchaseOrder: 'Einkaufsauftrag',
    },
    personnel: {
      users: 'Anwender',
      operators: 'Mitarbeiter',
      leaveCalendars: 'Urlaubskalender',
    },
    assets: {
      processes: 'Prozessen',
      articleTypes: 'Artikeltypen',
      exactItems: 'Exact Artikel',
      productionLines: 'Produktionsanlagen',
      capabilities: 'Skills',
      processPhases: 'Prozessstufen',
      dataSources: 'Dataquellen',
      loadCarriers: 'Verpackungsträger',
    },
  },
  user: {
    overview: {
      title: 'Anwender',
      empty: 'Es gibt keine Anwender, die Ihrer Suche entsprechen.',
      addButton: 'Anwender',
      convertToOperator: 'Zum Operator konvertieren',
    },
    create: {
      title: 'Anwender anlegen',
    },
    edit: {
      title: 'Anwender einstellen',
    },
    field: {
      avatar: { label: 'Profilbild' },
      fullName: { label: 'Vollständiger Name' },
      firstName: { label: 'Vorname' },
      lastName: { label: 'Nachname' },
      email: { label: 'E-mail' },
      password: { label: 'Passwort' },
      language: { label: 'Sprache' },
      dateJoined: { label: 'Hinzugefügt am' },
      badgeId: { label: 'Badge ID' },
      groups: {
        label: 'Gruppen',
        value: {
          superuser: 'Super user',
          admin: 'Admin',
          manager: 'Manager',
        },
        empty: 'Kein gruppen zugeordnet',
      },
    },
    login: {
      title: 'Anmelden',
      forgotPasswordLink: 'Passwort vergessen?',
      errors: {
        invalidCredentials: 'E-mail oder Passwort ist nicht korrekt',
        unknown: 'Unbekanten Fehler, Statuscode: {{status}}',
      },
      loginButton: 'Inloggen',
      workStationStartButton: 'Arbeitsplatz anfangen',
      or: 'Oder',
    },
    passwordForgot: {
      title: 'Passwort Vergessen',
      requestButton: 'Email senden',
      requestedEmailText: 'Eine E-Mail zum Zurücksetzen des Passworts wurde an Ihre E-Mail-Adresse gesendet.',
    },
    resetPassword: {
      title: 'Passwort Zurücksetzen',
      resetButton: 'Zurücksetzen',
      saveSuccess: 'Ihr Passwort wurde erfolgreich zurückgesetzt.',
    },
    activate: {
      title: 'Account Aktivieren',
      intro: 'Willkommen bei Tracy! Sie müssen nur ein Passwort festlegen, um Ihr Account zu aktivieren.',
      activateButton: 'Account aktivieren',
      saveSuccess: 'Ihr account wurde erfolgreich aktiviert.',
    },
    changePassword: {
      title: 'Passwort ändern',
      field: {
        passwordOld: {
          label: 'Aktuelles Passwort',
        },
        passwordNew: {
          label: 'Neues Passwort',
        },
      },
      saveButton: 'Speichern',
    },
    account: {
      title: 'Profil',
      logoutButton: 'Abmelden',
      info: {
        title: 'Daten',
      },
      password: {
        title: 'Passwort ändern',
      },
      logout: {
        title: 'Abmelden',
        confirm: 'Möchten Sie sich abmelden?',
      },
    },
  },
  exactItem: {
    overview: {
      title: 'Artikel',
      empty: 'Es gibt keine Artikel, die Ihrer Suche entsprechen.',
      viewLink: '(anzeigen)',
      actions: {
        label: 'Links ändern ({{count}})',
        action: {
          linkArticleTypes_one: 'Artikeltyp verbinden ({{count}})',
          linkArticleTypes_other: 'Artikeltypen verbinden ({{count}})',
          unlinkArticleTypes_one: 'Artikeltyp trennen ({{count}})',
          unlinkArticleTypes_other: 'Artikeltypen trennen ({{count}})',
        },
      },
    },
    edit: {
      title: 'Artikel ändern',
    },
    field: {
      productType: { label: 'Artikel' },
      id: { label: 'ID' },
      code: { label: 'Kode' },
      classification: { label: 'Warengruppe' },
      isMakeItem: { label: 'Produzieren' },
      isPurchaseItem: { label: 'Einkaufen' },
      name: { label: 'Name' },
      articleType: { label: 'Artikeltyp' },
      componentType: { label: 'Komponenttyp' },
    },
  },
  operator: {
    overview: {
      title: 'Mitarbeiter',
      empty: 'Es gibt keine Mittarbeiter, die Ihrer Suche entsprechen.',
      addButton: 'Mittarbeiter',
    },
    create: {
      title: 'Mittarbeiter anlegen',
    },
    edit: {
      title: 'Mittarbeter einstellen',
      tab: {
        info: 'Daten',
        capabilities: 'Fähigkeiten',
        availability: 'Verfügbarkeit',
      },
      workScheduleDescription: 'Arbeitsplan ab {{startDate}}',
    },
    field: {
      avatar: { label: 'Proflbild' },
      fullName: { label: 'Name' },
      firstName: { label: 'Vorname' },
      lastName: { label: 'Nachname' },
      operatorCapabilities: {
        label: 'Fähigkeiten',
        empty: 'Kein Fähigkeiten erstellt',
        none: 'Im System wurden noch keine Fähigkeiten konfiguriert.',
      },
      createdAt: { label: 'Erstellt am' },
      badgeId: { label: 'Ausweis ID' },
      workSchedules: {
        label: 'Arbeitspläne',
        empty: 'Es wurden noch keine Arbeitspläne hinzugefügt.',
      },
      availability: { label: 'Verfügbarkeit' },
      leaveCalendars: { label: 'Urlaubskalender' },
      manager: { label: 'Manager' },
    },
  },
  salesOrder: {
    basicInfo: 'Basis Info',
    overview: {
      title: 'Verkaufsaufträge',
      empty: 'Es gibt keine Verkaufsaufträge die Ihre suche entspechen.',
      addButton: 'Verkaufsauftrag',
    },
    create: {
      title: 'Neue Verkaufsauftrag',
    },
    edit: {
      title: 'Verkaufsauftrag anpassen',
    },
    field: {
      id: { label: 'ID' },
      status: {
        label: 'Status',
        value: {
          open: 'Open',
          partial: 'Im Prozess',
          complete: 'Fertig',
          canceled: 'Abgebrochen',
        },
      },
      customer: { label: 'Kunde' },
      reference: { label: 'Referenz' },
      orderDate: { label: 'Orderdatum' },
      deliveryDate: { label: 'Lieferdatum' },
      deliveryAddress: { label: 'Lieferaddresse' },
      remarks: { label: 'Bemerkungen' },
      description: { label: 'Beschreibung' },
      outShipments: { label: 'Warenausgangauftrag' },
      lines: { label: 'Verkaufsauftragregel' },
      quantity: { label: 'Menge' },
    },
  },
  salesOrderLine: {
    addToOutShipmentButton: 'Hinzufügen',
    field: {
      id: { label: 'ID' },
      project: { label: 'Projekt' },
      productionRequest: { label: 'Produktionsauftrag' },
      productionDueDate: { label: 'Fertigungsdatum' },
      deliveryDate: { label: 'ERP Senddatum' },
      quantity: { label: 'Menge' },
      _toReceive: { label: 'Zu empfangen' },
      unitPrice: { label: 'Stuckpreis' },
      netPrice: { label: 'Net Preis' },
    },
  },
  exactPurchaseOrder: {
    field: {
      id: { label: 'ID' },
      number: { label: 'Auftrag' },
      status: {
        label: 'Status',
        value: {
          10: 'Open',
          20: 'Im Porzess',
          30: 'Fertig',
          40: 'Abgebrochen',
        },
      },
    },
  },
  exactSalesOrder: {
    field: {
      id: { label: 'ID' },
      number: { label: 'Nummer' },
    },
  },
  purchaseOrder: {
    overview: {
      title: 'Einkaufsauftrag',
      empty: 'Es gibt keine Einkaufsauftrage die ihre suche entsprechen.',
      addButton: 'Einkaufsauftrag',
    },
    create: {
      title: 'Neue Einkaufsauftrag',
    },
    edit: {
      title: 'Einkaufsauftrag anpassen',
      addInShipmentModal: {
        title: 'Neue Wareneingang',
      },
    },
    field: {
      id: { label: 'ID' },
      status: {
        label: 'Status',
        value: {
          open: 'Open',
          partial: 'Im Prozess',
          complete: 'Fertig',
          canceled: 'Abgebrochen',
        },
      },
      supplier: { label: 'Lieferant' },
      quantity: { label: 'Menge' },
      reference: { label: 'Referenz' },
      orderDate: { label: 'Orderdatum' },
      receiptDate: { label: 'Wareneingangsdatum' },
      trackingCode: { label: 'Tracking Kode' },
      licensePlate: { label: 'Nummernschild' },
      lines: { label: 'Einkaufsauftragregel' },
      inShipments: { label: 'Wareneingang' },
    },
  },
  purchaseOrderLine: {
    noBatchTypeBuy: 'Dieses Artikel hat kien Prozesstyp definiert',
    addToInShipmentButton: 'Hinzufügen',
    field: {
      id: { label: 'ID' },
      productionRequest: { label: 'Produktionsauftrag' },
      project: { label: 'Projekt' },
      quantity: { label: 'Menge' },
      receiptDate: { label: 'ERP Wareneingangsdatum' },
      _toReceive: { label: 'Zu empfangen' },
      unitPrice: { label: 'Stuckpreis' },
      netPrice: { label: 'Net Preis' },
    },
  },
  inShipment: {
    disabledDelete: 'Kann Sendung nicht löschen weil Aufträge im Process sind',
    field: {
      id: { label: 'ID' },
      receivedAt: { label: 'Wareneingangsdatum' },
      trackingCode: { label: 'Tracking Kode' },
      licensePlate: { label: 'Nummernschild' },
      shipperName: { label: 'Fahrernahme' },
    },
  },
  inShipmentLine: {
    disabledDelete: 'Kann Sendung nicht löschen weil Aufträge im Process sind',
    field: {
      id: { label: 'ID' },
    },
  },
  outShipment: {
    disabledDelete: 'Kann Sendung nicht löschen weil Aufträge im Process sind',
    field: {
      id: { label: 'ID' },
      shipperPickupDate: { label: 'Abholtermin' },
      shipperName: { label: 'Fahrername' },
      shipperLicensePlate: { label: 'Nummernschild' },
      freightType: { label: 'Transportart' },
      freightCompany: { label: 'Transportfirma' },
      freightInfo: { label: 'Frachtinformationen' },
    },
  },
  outShipmentLine: {
    disabledDelete: 'Kann Sendung nicht löschen weil Aufträge im Process sind',
    field: {
      id: { label: 'ID' },
    },
  },
  workSchedule: {
    field: {
      startDate: { label: 'Eingangsdatum' },
      workSlots: {
        label: 'Arbeitszeiten',
        empty: 'Es wurden noch keine Arbeitszeiten hinzugefügt.',
      },
    },
  },
  capability: {
    overview: {
      title: 'Fähigkeiten',
      empty: 'Es gibt keine Fähigkeiten, die Ihrer Suche entsprechen.',
      addButton: 'Fähigkeiten',
    },
    create: {
      title: 'Fähigkeiten anlegen',
    },
    edit: {
      title: 'Fähigkeiten Einstellen',
    },
    field: {
      name: { label: 'Name' },
      operatorCapabilities: {
        label: 'Mittarbeiter',
        none: 'Im System wurden noch keine Mittarbeiter konfiguriert.',
      },
    },
  },
  processPhase: {
    overview: {
      title: 'Przessphasen',
      empty: 'Es gibt keine Prozessphasen, die Ihrer Suche entsprechen.',
      addButton: 'Processphasen',
    },
    create: {
      title: 'Prozessphase anlegen',
    },
    edit: {
      title: 'Prozessphase einstellen',
    },
    field: {
      icon: { label: 'Symbol' },
      name: { label: 'Name' },
    },
  },
  batchType: {
    field: {
      id: { label: 'ID' },
      description: { label: 'Beschreibung' },
      quantity: { label: 'Größe' },
      name: { label: 'Chargen grösse' },
      type: {
        label: 'Typ',
        value: {
          make: 'Produzieren',
          buy: 'Wareneingang',
          sell: 'Warenausgang',
          component: 'Komponent',
          subassembly: 'Baugruppe',
        },
      },
      serialNumberFormat: { label: 'Chargennummer format' },
    },
  },
  articleType: {
    overview: {
      title: 'Artikel',
      empty: 'Es gibt keine Artikel, die Ihrer Suche entsprechen.',
      addButton: 'Artikel',
    },
    printShelfLabelButton: 'Lagerort Artikel Etikett',
    selectModal: {
      title: 'Artkel wählen',
    },
    create: {
      title: 'Neu Artkel',
    },
    edit: {
      baseFields: {
        label: 'Basis Felder',
        info: 'Diese Felder gehören nicht zu einer Prozesstyp.',
      },
      title: 'Artikel anpassen',
      version: 'Version {{version}}',
      draft: 'Kozept',
      finalizeButton: 'Finalisieren',
      nextVersionButton: 'Nächste Version',
      noStepSelected: 'Kein Prozesschritt gewählt',
      workStationsModal: {
        title: 'Arbeitsplatz wählen',
      },
    },
    field: {
      id: { label: 'ID' },
      code: { label: 'Kode' },
      name: { label: 'Name' },
      line: { label: 'Produktionsumgebung' },
      versions: { label: 'Versionen' },
      batchTypes: { label: 'Prozesstypen' },
      storageLocations: {
        short: 'Lagerorte',
        long: 'Lagerorte - Menge',
      },
    },
  },
  articleTypeVersion: {
    field: {
      articleType: { label: 'Artikeltyp' },
      productionLineVersion: { label: 'Producktionumgebung version' },
    },
  },
  productionLine: {
    overview: {
      title: 'Anlagen',
      empty: 'Es gibt keine Anlagen, die Ihrer Suche entsprechen.',
      addButton: 'Anlagen',
    },
    planning: {
      title: 'Anlagen',
      empty: 'Es gibt keine Produktionslinien, die Ihrer Suche entsprechen.',
      productionLine: 'Anlage',
      week: 'Woche',
      days: {
        monday: 'Montag',
        tuesday: 'Dienstag',
        wednesday: 'Mittwoch',
        thursday: 'Donnerstag',
        friday: 'Freitag',
        saturday: 'Samstag',
        sunday: 'Sonntag',
      },
      parts: {
        night: 'Nacht (00:00 - 06:00)',
        morning: 'Morgen (06:00 - 12:00)',
        afternoon: 'Mittag (12:00 - 18:00)',
        evening: 'Abend (18:00 - 00:00)',
      },
    },
    create: {
      title: 'Anlage anlegen',
    },
    edit: {
      baseFields: {
        label: 'Grund Felder',
        info: 'Diese Felder gehören nicht zu einer Version, gelten jedoch für jede Version.',
      },
      title: 'Anlage einstellen',
      version: 'Version {{version}}',
      draft: 'Konzept',
      finalizeButton: 'Finalisieren',
      nextVersionButton: 'Nächste Version',
      tabs: {
        map: 'Karte',
        codes: 'Code',
        availability: 'Verfügbarkeit',
      },
    },
    selectModal: {
      title: 'Anlage wählen',
    },
    codesModal: {
      title: 'Code übersicht',
      noPrintersAvailable: 'Keine Drücker verfugbar.',
    },
    field: {
      name: { label: 'Naam' },
      fullName: { label: 'Name' },
      versions: { label: 'Versionen' },
      timezone: { label: 'Zeitzone' },
      color: {
        label: 'Farbe',
        value: {
          red: 'Rot',
          orange: 'Orangen',
          yellow: 'Gelb',
          olive: 'Olivgrün',
          green: 'Grün',
          teal: 'Türkis',
          blue: 'Blau',
          violet: 'Violett',
          purple: 'Lila',
          pink: 'Rosa',
          brown: 'Braun',
          grey: 'Grau',
          black: 'Schwarz',
        },
      },
      workSchedules: { label: 'Verfügbarkeitsplan' },
      leaveCalendars: { label: 'Urlaubsplan' },
    },
  },
  productionLineVersion: {
    field: {
      version: { label: 'Version' },
      draft: { label: 'Kozept' },
      floorPlan: { label: 'Plan' },
      workStations: {
        label: 'Arbeitsplätze',
        addButton: 'Arbeitsplatz',
      },
      productionLine: { label: 'Anlage' },
    },
  },
  workStation: {
    field: {
      name: { label: 'Name' },
      code: { label: 'Code' },
      dataSources: { label: 'Datenquellen' },
      defaultFte: { label: 'Standard VZÄ' },
      singleUser: {
        label: 'Anzahl Anmeldungen',
        value: {
          true: 'Einzelne Anmeldung',
          false: 'Mehrere Anmeldungen',
        },
        tooltip: {
          true:
            'Bei der Einstellung \'single login\' fragt der Arbeitsplatz den Bediener einmal nach dem Ausweisscan. Nach 4h wird automatisch abgemeldet.',
          false:
            'Bei der Einstellng \'multi login\' fragt der Arbeitsplatz den Bediener bei jedem Prozessschritt nach dem Ausweisscan.',
        },
      },
      productionStrategy: {
        label: 'Produktionstrategie',
        value: {
          together: 'Samen',
          seperate_spread: 'Getrennt (Verdeeld)',
          seperate_planned: 'Getrennt (Gepland)',
        },
        tooltip: {
          together:
            'Bei der Einstellung \'samen\' werden die verschiedenen Arbeitsplätze als ein Standort zusammen funktionieren.',
          seperate_spread:
            'Bei der Einstellung \'gescheiden (verdeeld)\' werden die verschiedenen Arbeitsplätze getrennt funktionieren.',
          seperate_planned:
            'Bei der Einstellung  \'gescheiden (gepland)\' werden die verschiedenen Arbeitsplätze getrennt funktionieren.',
        },
      },
      maintenanceStrategy: {
        label: 'Wartungsstrategie',
        value: {
          together: 'Zusammen',
          seperate: 'Getrennt',
        },
        tooltip: {
          together:
            'Bei der Einstellung \'samen\' werden Wartungsaufträge von verschiedene arbeitsplätze zusammen geplant und ausgeführt',
          seperate:
            'Bei der Einstellung \'gescheiden\' werden Wartungsaufträge von verschiedene arbeitsplätze getrennt geplant und ausgeführt.',
        },
      },
    },
    start: {
      title: 'Abreitsplatz anmelden',
      startButton: 'Anmelden',
    },
    production: {
      title: 'Produktion',
      empty: 'Es gibt keine Aufträge, die Ihrer Suche entsprechen.',
      stopButton: 'Beenden',
      selectButton: 'Produzieren',
      version: 'Version {{version}}',
      operatorModal: {
        title: 'Ausweisscan',
        confirmButton: 'Bestätigen',
        error: {
          emptyField: 'Geben Sie die Ausweis des mitarbeiter ein',
          noResults: 'Es gibt keine Mitarbeiter mit diesen Ausweis, die Ihrer Suche entsprechen.',
          multipleResults: 'Es wurden mehrere Mitarbeiter mit der angegebenen Ausweis gefunden.',
          unexpected: 'Ein unerwarteter Fehler ist aufgetreten.',
          missingCapabilities: 'Dem gefundenen Mitarbeiter fehlt eine notwendige Fähigkeit: {{capabilities}}.',
        },
      },
      productModal: {
        title: 'Scan Produkt',
        confirmButton: 'Bestätigen',
        error: {
          notFound: 'Mit der angegebenen Seriennummer wurde kein Produkt gefunden.',
        },
      },
      performModal: {
        title: 'Prozess ausführen',
        confirmButton: 'Bestätigen',
        formStep: {
          label: 'Seriennummer',
          subject: {
            product_type: 'Produkt',
          },
          error: {
            not_instance_of: 'Dieses Feld enthält keine korrekte Eingabe',
            missing: 'Dieses Feld enthält keine Eingabe',
            in_use: '{{subject}} ist bereits verwendet.',
            wrong_product_type: '{{subject}} hat den falschen Artikel.',
            not_found: '{{subject}} könnte nicht gefunden werden.',
            general_error: 'Unbekannter charge',
            not_production_request_at_step: '{{subject}} könnte nicht gefunden werden.',
            wrong_article_type: '{{subject}} hat den falschen Artikeltyp',
          },
        },
        printStep: {
          printButton: 'Drücken',
        },
        splitStep: {
          printButton: 'Drücken',
        },
      },
      infoModal: {
        title: 'Info',
      },
      operator: {
        expiresAt: 'Läuft ab{{at}}',
      },
    },
    stopModal: {
      title: 'Arbeitsplatz beenden',
      content: 'Sind Sie sicher, dass Sie an diesem Arbeitsplatz beenden möchten?',
      stopButton: 'Beenden',
    },
  },
  productionRequest: {
    registerExistingButton: 'Neue Charge Indentifizierung',
    overview: {
      title: 'Produktionsaufträge',
      empty: 'Es gibt keine Aufträge, die Ihrer Suche entsprechen.',
      addButton: 'Produktionsauftrag',
      progress: {
        done: '{{count}} Fertig',
        inProgress: '{{count}} Im prozess',
        todo: '{{count}} Zu tun',
        highPrio: '{{count}} Hohen prio',
        normalPrio: '{{count}} Normalen prio',
      },
      week: 'Woche {{week}}',
      stat: {
        count_one: 'Auftrag',
        count_other: 'Aufträge',
        hours: 'Stunde',
      },
      productionLine: 'Produktionumgebung',
      days: {
        monday: 'Montag',
        tuesday: 'Dienstag',
        wednesday: 'Mittwoch',
        thursday: 'Donnerstag',
        friday: 'Freitag',
        saturday: 'Sammstag',
        sunday: 'Sonntag',
      },
      parts: {
        night: 'Nacht (00:00 - 06:00)',
        morning: 'Morgen (06:00 - 12:00)',
        afternoon: 'Mittag (12:00 - 18:00)',
        evening: 'Abend (18:00 - 00:00)',
      },
    },
    create: {
      title: 'Produktionsauftrag anlegen',
    },
    edit: {
      title: 'Produktionsauftrag einstellen',
    },
    field: {
      id: { label: 'ID' },
      productType: { label: 'Artikel' },
      week: { label: 'Woche' },
      quantity: { label: 'Mange' },
      progress: { label: 'Stand' },
      startAt: { label: 'Geplante anfang' },
      endAt: { label: 'Geplante ende' },
      actualDuration: { label: 'Aktuelle Vorlaufzeit' },
      duration: { label: 'Geplante Vorlaufzeit' },
      startedAt: { label: 'Tatsächlichen anfang' },
      endedAt: { label: 'Tatsächlichen beendung' },
      project: { label: 'Projekt' },
      processVersionProductType: {
        label: 'Artikel',
        empty: 'Noch kein Artikel ausgewählt',
      },
    },
    filter: {
      inShipmentId: 'In Shipment ID',
      outShipmentId: 'Out Shipment ID',
      projectCode: 'Project Code',
      purchaseOrderNumber: 'Purchase Order Number',
      salesOrderNumber: 'Sales Order Number',
    },
    addModal: {
      title: 'Produktionsaufträge anlegen',
      step: {
        pickWeek: 'Woche wählen',
        pickProductTypes: 'Artikel wählen',
        setQuantities: 'Menge eingeben',
      },
      quantityWarning:
        'Das Speichern eines Produktionsauftrags mit der Menge 0 entspricht dem Löschen dieses Produktionsauftrags.',
      noProductTypes: 'Sie haben noch keine Artikel ausgewählt.',
      notFullyDeleted:
        'Der Produktionsauftrag konnte nicht vollständig gelöscht werden, da der Auftrag bereits gestartet wurde.',
    },
    editModal: {
      title_one: 'Produktionsauftrag einstellen',
      title_other: 'Produktionsaufträge einstellen',
    },
  },
  myFilter: {
    custom: 'Neu',
    action: {
      unsetDefault: 'Nicht Standardmäßig verwenden',
      setDefault: 'Standardmäßig verwenden',
      setCurrent: 'Aktuelle Filter speichern',
      delete: 'Löschen',
    },
  },
  tooltips: {
    masquerade: 'Auftreten als',
    edit: 'Einstellen',
    delete: 'Löschen',
    restore: 'Zurückstellen',
    view: 'Ansehen',
    select: 'Auswählen',
  },
  form: {
    // Model
    edit: {
      addFieldButton: 'Feld hinzufügen',
    },
    field: {
      fields: {
        label: 'Felder',
        empty: 'Dieses formular ist noch lehr',
      },
    },
    // Form stuff
    yes: 'Ja',
    no: 'Nein',
    either: 'Beiden',
    draftJs: {
      style: {
        'header-one': { label: 'H1' },
        'header-two': { label: 'H2' },
        'header-three': { label: 'H3' },
        'header-four': { label: 'H4' },
        'header-five': { label: 'H5' },
        'header-six': { label: 'H6' },
        blockquote: { label: 'Blockquote' },
        'unordered-list-item': { label: 'UL' },
        'ordered-list-item': { label: 'OL' },
        'code-block': { label: 'Code Block' },
        BOLD: { label: 'Bold' },
        ITALIC: { label: 'Italic' },
        UNDERLINE: { label: 'Underline' },
        CODE: { label: 'Monospace' },
      },
    },
    iconInput: {
      noneSelected: 'Kein Symbol ausgewählt',
    },
    cancelButton: 'Abbrechen',
    deleteButton: 'Löschen',
    backButton: 'Zurück',
    nextButton: 'Nächste',
    submitButton: 'Speichern',
    saveButton: 'Speichern',
    copyButton: 'Kopieren',
    addButton: 'Hinzufügen',
    printButton: 'Drücken',
    bulkActionButton: 'Aktion ({{count}})',
    multiPick: {
      searchPlaceholder: 'Auswählen…',
      selectedText: '$1 von $2 ausgewählt',
      noneSelectedText: 'Nichts ausgewählt',
      selectAllButton: 'Alle',
      selectNoneButton: 'Kein',
    },
    notifications: {
      saveSuccess: 'Erfolgreich gespeichert',
      deleteSuccess: 'Erfolgreich gelöscht',
      saveError: 'Fehler mit speichern ({{status}})',
      saveValError: 'Nicht alle Informationen sind korrekt ausgefüllt',
      saveAuthError: 'Nicht autorisiert',
      newAppVersion: 'Neue Version verfügbar, klicken Sie, um die Seite zu aktualisieren',
    },
    startDate: 'Startdatum',
    endDate: 'Enddatum',
    deleteConfirmation: 'Möchten Sie {{name}} wirklich löschen?',
    fileType: {
      none: 'Kein datei',
      image: 'Bild',
      pdf: 'PDF',
      any: '',
    },
    repeat: 'Wiederholen',
    greaterThan: 'Höher denn',
    lowerThan: 'Niedriger denn',
  },
  crash: {
    heading: 'Programm beended',
    subHeading1: 'Wir entschuldigen uns, aber dass Programm ist beended.',
    subHeading2: 'Unser Team wurde benachrichtigt.',
    reload: 'Programm neu laden',
  },
  daycy: {
    week: {
      label: 'Wo',
      number: '{{week}}',
      value: 'W{{week}} - {{year}}',
    },
    weekDay: {
      monday: 'Mo',
      tuesday: 'Di',
      wednesday: 'Mi',
      thursday: 'Do',
      friday: 'Fr',
      saturday: 'Sa',
      sunday: 'So',
    },
    month: {
      january: 'Januar',
      february: 'Februar',
      march: 'März',
      april: 'April',
      may: 'Mai',
      june: 'Juni',
      july: 'Juli',
      august: 'August',
      september: 'September',
      october: 'Oktober',
      november: 'November',
      december: 'Dezember',
    },
    period: {
      am: 'AM',
      pm: 'PM',
    },
  },
  iconModal: {
    header: 'Symbol Auswählen',
    filterPlaceholder: 'Suchen...',
    category: {
      accessibility: 'Zugänglichkeit',
      arrows: 'Pfeilen',
      audioVideo: 'Audio/Video',
      business: 'Geschäflich',
      chess: 'Schach',
      code: 'Code',
      communication: 'Kommunikation',
      computers: 'Computer',
      currency: 'Geld',
      dateTime: 'Datum/Zeit',
      design: 'Design',
      editors: 'Textverarbeitung',
      files: 'Datei',
      genders: 'Geschlecht',
      handsGestures: 'Handgesten',
      health: 'Gesundheit',
      images: 'Bilder',
      interfaces: 'Verbindungen',
      logistics: 'Logistik',
      maps: 'Karte',
      medical: 'medizinisch',
      objects: 'Objekte',
      paymentsShopping: 'Zahlungen/Einkaufen',
      shapes: 'Formen',
      sports: 'Sport',
      status: 'Status',
      usersPeople: 'Menschen',
      vehicles: 'Fahrzeuge',
      writing: 'Schreiben',
    },
  },
  exact: {
    ok: {
      title: 'Exact Koppeling Succesvol',
      body: 'Exact Online is nu succesvol gekoppeld met het systeem.',
    },
    error: {
      title: 'Erfolgreiche Verbindung mit EXACT',
      body: {
        invalid_request: 'Falsche Anfrage an Exact Online gesendet.',
        invalid_client: 'Falsche Kunde an Exact Online gesendet.',
        invalid_grant: 'Falsche Geste an Exact Online gesendet.',
        invalid_scope: 'Falsche Rahmung an Exact Online gesendet. ',
        unauthorized_client: 'Nicht autorisierter Kunde an Exact Online gesendet.',
        unsupported_grant_type: 'Unbekannter Geste an Exact Online gesendet.',
      },
    },
  },
  warehouse: {
    inbound: 'Wareneingang',
    outbound: 'Warenausgang',
  },
  step: {
    edit: {
      step: 'Prozessschritt {{index}}',
      start: 'Start',
      end: 'Ende',
      addSectionButton: 'Komponent hinzufügen',
    },
    field: {
      label: { label: 'Etikett' },
      type: {
        value: {
          print: 'Prozess Drücken',
          form: 'Prozess Formular ausführen',
          split: 'Kombi Prozess pro Batchgrösse',
          multiplier: 'Multiplikator hinzufügen',
        },
        valueStep: {
          print: 'Drücken',
          form: 'Formular',
          multiplier: 'Multiplikator',
        },
      },
      sections: {
        label: 'Beschreibung',
        empty: 'Diese Beschreibung wurde noch nicht ausgefühlt.',
      },
      workStation: { label: 'Arbeitsplatz' },
      workMinutes: { label: 'Geschätzte Arbeitszeit in Minuten' },
      capabilities: { label: 'Erforderliche Fähigkeiten' },
    },
  },
  printStep: {
    field: {
      printer: {
        label: 'Drücker',
        value: {
          altec: 'Altec',
          zebra: 'Zebra/Toshiba',
        },
      },
      template: {
        label: 'Vorlage',
        keys: {
          article_type_name: 'Artikel.',
          article_type_code: 'Code.',
          warehouse_name: 'Lager.',
          warehouse_code: 'Lager ID.',
          warehouse_locations: 'Lager Orte.',
          serial_number: 'Chargen des Produktes.',
          date: 'Druckdatum.',
          time: 'Zeitpunkt drucken.',
        },
      },
    },
  },
  multiplierStep: {
    field: {
      multiplier: { label: 'Anzahl' },
    },
  },
  formStep: {
    field: {
      fields: {
        label: 'Formular',
        empty: 'Dieses Formular wurde noch nicht ausgefüllt',
      },
      icon: { label: 'Symbol' },
    },
    edit: {
      addFieldButton: 'Feld anlegen',
    },
    perform: {
      between: 'Zwischen {{min}} und {{max}}',
    },
  },
  splitStep: {
    field: {
      form: { label: 'Formular' },
      icon: { label: 'Symbol' },
      newBatchQuantity: { label: 'Neue Größe' },
      printer: { label: 'Drucker' },
      template: { label: 'Template' },
      type: {
        label: 'Identifikationstyp',
        value: {
          scan: 'Verpackungsträger scan ',
          print: 'Etikettierung',
          article: 'Chargen scan',
        },
        tooltip: {
          scan: 'Lieferant Artikel scan mit einen unbekannte Lieferanten Seriennummer',
          print: 'Drucken',
          article: 'Artikel scan von gelagerte oder produzierte Waren',
        },
      },
    },
  },
  formStepField: {
    field: {
      label: { label: 'Symbol' },
      type: {
        label: 'Typ',
        value: {
          text: 'Text',
          check: 'Überprüfe',
          choice: 'Wählen',
          article_type: 'Artikeltype',
          measure: 'Qualitätsmessung',
        },
      },
      target: { label: 'Details' },
      componentType: { label: 'Komponent' },
      productType: { label: 'Artikel' },
      slug: {
        label: 'Referenz drücken',
        info:
          'Dieser Wert wird eingegeben, um auf dieses Feld in den Druckvorlagen zu verweisen. Nur die Zeichen A-Z, a-z, 0-9 und _ sind erlaubt und die Eingabe darf nicht mit einer Zahl anfangen.',
      },
    },
  },
  batch: {
    overview: {
      title: 'Produkten',
      empty: 'Es gibt keine Produkten die Ihre suche entsprechen.',
    },
    field: {
      batchType: { label: 'Typ' },
      batchUseds: {
        label: 'Hauptprodukt',
        empty: 'Dieses Produkt hat keine Hauptprodukt.',
      },
      batchUsings: {
        label: 'Bauteile',
        empty: 'Dieses Produkt hat keine Bauteile.',
      },
      details: {
        label: 'Detail',
        empty: 'Dieses Produkt hat keine Info.',
      },
      performances: {
        label: 'Ausgeführte Schritte',
        empty: 'Dieses Produkt hat keine ausgeführte Schritte',
        description: '{{step}} Ausgeführt durch {{operator}} am {{createdAt}}',
      },
      scrapReason: { label: 'Scrap reason' },
      serialNumber: { label: 'Seriennummer' },
      finished: { label: 'Fertig' },
      scrapped: { label: 'Abgebrochen' },
      loadCarrier: { label: 'Ladungträger' },
    },
  },
  subassembly: {
    overview: {
      title: 'Unterbauprodukte',
      empty: 'Es gibt keine Unterbauprodukte, die Ihrer Suche entsprechen.',
    },
    field: {
      parentProduct: { label: 'Hauptprodukt' },
      parentSubassembly: { label: 'Unterbauprodukt' },
      subassemblies: {
        label: 'Unterbauprodukte',
        empty: 'Diese Unterbauprodukt hat keine Unterbauprodukten.',
      },
      details: {
        label: 'Details',
        empty: 'Dieses Unterbauprodukt enthält keine Details.',
      },
      performances: {
        label: 'Durchgeführte Prozessschritte',
        empty: 'Dieses Unterbauprodukt hat keine abgeschlossenen Prozessschritte.',
        description: '{{step}} Wurde augeführt durch {{operator}} am {{createdAt}}',
      },
      serialNumber: { label: 'Seriennummer' },
      finished: { label: 'Fertig' },
    },
  },
  progress: {
    empty: 'Für diese Woche liegen noch keine Produktionsaufträge vor.',
    productionLine: {
      stat: {
        todo: 'Zu tun',
        inProgress: 'Im Prozess',
        done: 'Fertig',
        operators_one: 'Bediener',
        operators_other: 'Bediener',
      },
    },
    processVersion: 'v{{version}}',
    groupBy: {
      label: 'Gruppieren nach',
      value: {
        workStation: 'Arbeitsplatz',
        step: 'Prozessschritt',
      },
    },
  },
  loading: 'Laden',
  detail: {
    field: {
      value: { label: 'Wert' },
      reason: { label: 'Grund' },
    },
  },
  targetInfoModal: {
    scrapped: 'Abgelehnt: {{reason}}',
  },
  serialNumberFormat: {
    part: {
      text: {
        label: 'Text',
        content: 'Inhalt',
      },
      date: {
        label: 'Datum',
        part: {
          label: 'Komponent',
          year: { label: 'Jahr' },
          month: {
            label: 'Monat',
            text: 'Text',
            names: {
              january: {
                label: 'Januar',
                value: 'JAN',
              },
              february: {
                label: 'Februar',
                value: 'FEB',
              },
              march: {
                label: 'März',
                value: 'MÄR',
              },
              april: {
                label: 'April',
                value: 'APR',
              },
              may: {
                label: 'Mai',
                value: 'MAI',
              },
              june: {
                label: 'Juni',
                value: 'JUN',
              },
              july: {
                label: 'Juli',
                value: 'JUL',
              },
              august: {
                label: 'August',
                value: 'AUG',
              },
              september: {
                label: 'September',
                value: 'SEP',
              },
              october: {
                label: 'Oktober',
                value: 'OKT',
              },
              november: {
                label: 'November',
                value: 'NOV',
              },
              december: {
                label: 'Dezember',
                value: 'DEZ',
              },
            },
          },
          day: { label: 'Tag' },
          isoyear: { label: 'ISO Jahr' },
          isoweek: { label: 'ISO Woche' },
          isoweekday: {
            label: 'ISO Wochentag',
            text: 'Text',
            names: {
              monday: {
                label: 'Montag',
                value: 'MO',
              },
              tuesday: {
                label: 'Dienstag',
                value: 'DI',
              },
              wednesday: {
                label: 'Mittwoch',
                value: 'MI',
              },
              thursday: {
                label: 'Donnerstag',
                value: 'DO',
              },
              friday: {
                label: 'Freitag',
                value: 'FR',
              },
              saturday: {
                label: 'Samstag',
                value: 'SA',
              },
              sunday: {
                label: 'Sonntag',
                value: 'SO',
              },
            },
          },
        },
        format: 'Format',
      },
      code: {
        label: 'Code',
        alphabet: 'Alphabet',
        digits: 'Nummerierung',
        expand: 'Überlaufen',
        defaultAlphabets: {
          decimal: 'Dec',
          binary: 'Bin',
          hexadecimalLower: 'Hex (a)',
          hexadecimalUpper: 'Hex (A)',
          octal: 'Oct',
          alphanumericLower: 'Alfanum (a)',
          alphanumericUpper: 'Alfanum (A)',
          alphabeticLower: 'Alfa (a)',
          alphabeticUpper: 'Alfa (A)',
          custom: 'Sonnst, denn:',
        },
      },
    },
  },
  template: {
    info:
      'In einer Vorlage können Sie produktspezifische Werte verwenden. Sie können angeben, wo sich ein Wert befinden soll, indem Sie {{ an der richtigen Stelle in der Vorlage eingeben.',
  },
  performanceResetModal: {
    title: 'Produkt zurückstellen',
    reason: 'Grund',
    resetButton: 'Zurückstellen',
  },
  textPart: {
    field: {
      text: {
        placeholder: 'Kein Text ausgefühlt',
      },
    },
  },
  imagePart: {
    field: {
      subtitle: {
        placeholder: 'Kein Titel eingegeben',
      },
    },
  },
  printerSetup: {
    connectionNameError: 'Drucker bitte neu konfigurieren',
    connectionError: 'Fehler beim Anschließen an den Drucker,',
    connectionPdfError: 'Fehler beim Abrufen von Lieferschein',
    title: 'Drücker Einstellen',
    installQzTray: {
      beforeLink: 'Installieren ',
      link: 'QZ Tray',
      afterLink: '.',
    },
    downloadCertificate: {
      beforeLink: 'Herunterladen ',
      link: 'Diese Datei',
      beforeLocation: ' und speichern als ',
      afterLocation: '.',
    },
    editProperties: {
      beforeLocation: 'Fügen Sie die folgende Text am Ende der Datei hinzu ',
      afterLocation: ':',
    },
    testPrinter: {
      beforeButtons: 'Drückertest:',
      printer: {
        altec: 'Altec',
        zebra: 'Zebra',
      },
    },
  },
  workSlot: {
    field: {
      monday: { label: 'Mo' },
      tuesday: { label: 'Di' },
      wednesday: { label: 'Mi' },
      thursday: { label: 'Do' },
      friday: { label: 'Fr' },
      saturday: { label: 'Sa' },
      sunday: { label: 'So' },
    },
  },
  workCalendar: {
    scope: {
      type: {
        week: { label: 'Woche' },
        month: { label: 'Monat' },
      },
    },
    available: 'Verfügbar',
    leaveSlotModal: {
      title: 'Urlaubszeit hinzufügen',
    },
  },
  leaveSlot: {
    field: {
      window: { label: 'Zeitraum' },
      name: { label: 'Grund' },
    },
    overview: {
      availabilityHeader: 'Verfügbare Operator-Mandate',
    }
  },
  leaveCalendar: {
    overview: {
      title: 'Urlaubskalender',
      empty: 'Es gibt keine Urlaubskalender, die Ihrer Suche entsprechen.',
      addButton: 'Urlaubskalender',
    },
    create: {
      title: 'Urlaubskalender anlegen',
    },
    edit: {
      title: 'Urlaubskalender einstellen',
    },
    field: {
      name: { label: 'Name' },
      operators: { label: 'Mitarbeiter' },
      leaveSlots: { label: 'Urlaubstage' },
    },
  },
  operatorCapability: {
    field: {
      level: {
        label: 'Niveau',
        value: {
          1: 'Nicht fähig',
          2: 'Lernend',
          3: 'Fähig',
          4: 'Experte',
        },
      },
    },
  },
  process: {
    overview: {
      title: 'Prozessen',
      addButton: 'Prozess',
      empty: 'Es gibt keine Prozesse, die Ihrer Suche entsprechen.',
    },
    edit: {
      baseFields: {
        label: 'Grundfelder',
        info: 'Diese Felder gehören nicht zu einer Version, gelten jedoch für jede Version.',
      },
      title: 'Prozess einstellen',
      version: 'Version {{version}}',
      draft: 'Konzept',
      finalizeButton: 'Beenden',
      nextVersionButton: 'Nächste Version',
      noStepSelected: 'Kein Prozessschritt ausgewählt',
      workStationsModal: {
        title: 'Wählen Sie Arbeitsplätze',
      },
      tabs: {
        steps: 'Prozessschritte',
        instructions: 'Arbeitsanweisungen',
        productTypes: 'Artikel',
        map: 'Karte',
      },
      addProductTypeModal: {
        title: 'Artikel hinzufügen',
        addButton: 'Hinzufügen ({{count}})',
      },
    },
    create: {
      title: 'Prozess anlegen',
    },
    field: {
      name: { label: 'Name' },
      newestVersion: { label: 'Neueste Version' },
    },
  },
  processVersion: {
    field: {
      productionLineVersion: { label: 'Anlage' },
      processVersionProductTypes: {
        label: 'Artikel',
        none: 'Kein artikel verbunden',
      },
      process: { label: 'Prozess' },
      version: { label: 'Version' },
      newest: { label: 'Neuesten' },
    },
  },
  processVersionProductType: {
    field: {
      productType: { label: 'Artikel' },
    },
    selectModal: {
      title: 'Artikel auswählen',
      existingProductionRequests_one: 'Für diesen Artikel liegt diese Woche bereits ein Produktionsauftrag vor:',
      existingProductionRequests_other: 'Produktionsaufträge für diesen Artikel bestehen bereits diese Woche:',
    },
  },
  dataSource: {
    edit: {
      title: 'Dataquelle einstellen',
    },
    create: {
      title: 'Dataquelle anlagen',
    },
    overview: {
      title: 'Dataquellen',
      empty: 'Es wurden noch keine Datenquellen registriert.',
    },
    field: {
      uuid: { label: 'UUID' },
      identifier: { label: 'Identifikationscode' },
      name: { label: 'Name' },
      deviceName: { label: 'Gerätename' },
      description: { label: 'Beschreibung' },
      workStations: { label: 'Verbunden Arbeitsplätze' },
      data: {
        label: 'Data',
        name: 'Name',
        type: 'Typ',
      },
    },
  },
  exactShopOrder: {
    field: {
      status: {
        label: 'Status',
        value: {
          open: 'Offen',
          released: 'Freigegeben',
          in_progress: 'Im Prozess',
          finalized: 'Fertig',
          complete: 'Beended',
        },
      },
    },
  },
  loadCarrier: {
    overview: {
      title: 'Ladungsträger',
      empty: 'Es gibt keine Ladungsträger die Ihre suche entsprechen',
      addButton: 'Ladungsträger',
    },
    create: {
      title: 'Neue Ladungsträger',
    },
    edit: {
      title: 'Ladungsträger Anpassen',
    },
    field: {
      type: {
        label: 'Typ',
        value: {
          pallet_euro: 'Europallet',
          pallet_pool: 'Poolpallet',
          container: 'Container',
          gitterbox: 'Gitterbox',
          carton_box: 'Pappkarton',
          stackbox: 'Stapelbox',
        },
      },
      serialNumber: { label: 'Serienummer' },
      name: { label: 'Naam' },
      description: { label: 'Beschreibung' },
      weight: { label: 'Gewicht' },
      width: { label: 'Breite' },
      height: { label: 'Höhe' },
      length: { label: 'Länge' },
      volume: { label: 'Inhalt' },
      picture: { label: 'Bild' },
    },
  },
  login: {
    barcode: 'Artikelkode',
    workstation: 'Arbeitsplatz Kode',
    title: 'Tracy Picker',
    username: 'Benutzername',
    batchcode: 'Batchkode',
    notAllowed: 'Diesen Arbeitsplatzkode ist nicht bekannt',
    password: 'Passwort',
    forgotPassword: 'Passwort vergessen?',
    clearusername: 'Löschen Benutzername',
    clearworkstation: 'Arbeitsplatz Kode löschen',
    clearprinter: 'Drücker Datei löschen',
    forgotMailSend: 'Senden eine E-Mail, um das Passwort zurückzusetzen',
    successful: 'Erfolgreich angemeldet mit Ausweis-ID',
    logIn: 'Anmelden',
    error: 'Fehler Anmeldung',
    wrong_credentials: 'Batchcode nicht registriert',
  },
  shipping: {
    picture: 'Ladung Bild',
    picture_accept: 'Bild ist ok',
    picture_decline: 'Erneut aufnehmen',
    start_receive: 'Starten den Empfangsprozess',
    packed: 'Beenden verpacken Artikel',
    start_send: 'Anfangen versand',
    send_shipment: 'Lieferungsversand',
    receive_shipment: 'Sendung erhalten',
  },
  overview: {
    search: 'Suche',
    titleOutbound: 'Ausgang Überblick',
    titleInbound: 'Eingang Überblick',
    titleScanInfo: 'Artikel Information',

    shipmentInbound: 'Eingang i{{id}}',
    shipmentOutbound: 'Ausgang o{{id}}',

    title: 'Pickorders',
    shipment: 'Pickorder {{id}}',
    tab: {
      inbound: 'Wareneingang',
      outbound: 'Warenausgang',
      picking: 'Picking',
      packed: 'Verpackt',
      shipped: 'Geschickt',
      received: 'Empfangen',
      toReceive: 'Empfangen werden',
      inProgress: 'in Bearbeitung',
    },
  },
  shipment: {
    yes: 'Ja',
    no: 'Nein',
    confirm: 'Bestätigen',
    confirmLogout: 'Sind Sie sicher, dass Sie sich abmelden möchten?',

    articleInfo: {
      performanceLine:
        '{{stepLabel}} des Prozesses {{productionLineName}} ausgeführt bei {{performanceTime}} durch {{performanceBy}}',
      batchType: 'Prozesstyp',
      batchDescription: 'Batch Beschreibung',
      batchSize: 'Batchgröße',
      articleNameAndCode: 'Artikelname und Exact Code',
      scannedBarcode: 'Gescannter Barcode',
    },

    printInstructionError: 'Fehler beim Abrufen der Druckanweisungen',

    picker: {
      default: 'Wähle einen Wert',
    },
    shipmentInfo: 'Versandinformationen',
    titleInbound: 'Eingehende Sendung {{id}}',
    titleOutbound: 'Auslieferung {{id}}',

    status: {
      picking: 'Picking',
      printing: 'Drücken',
      sending: 'Versand',
      sent: 'Versendet',
      toReceive: 'Zu bekommen',
      received: 'Empfangen',
    },

    field: {
      receivedTime: { label: 'Empfangszeit' },

      purchaseOrder: {
        label: 'Bestellung',
      },
      receiveDate: {
        label: 'Datum des Empfangens',
      },
      salesOrder: {
        label: 'Verkaufsauftrag',
      },
      batchSize: {
        label: 'batch größe',
      },
      shippingDate: {
        label: 'Versand datum',
      },
      freightType: {
        label: 'Frachtart',
        value: {
          road: 'Land',
        },
      },
      freightCompany: {
        label: 'Transportfirma',
      },
      shippingName: {
        label: 'Fahrername',
      },
      shippingLicense: {
        label: 'Nummernschild LKW',
      },
      shippingTime: {
        label: 'Zeitpunkt der Auslieferung',
      },
      quantity: {
        label: 'Menge',
      },
    },

    outbound: {
      pickitem: 'Pick Artikeln',
      printreceipt: 'Drücken Lieferschein',
      package: 'Verpacken',
      printshipmentlabel: 'Drücken Versandetikett',
      send: 'Senden',
    },
    inbound: {
      receiveItem: 'Sendung erhalten',
      barcodeCreation: 'Produkte identifizieren',
      warehousePlacement: 'Platzierung im Lager',
    },

    heading: {
      cmrLines: 'Lieferscheinregeln',
    },
    title: 'Pickorder {{id}}',
    confirmShipment:
      'Sind Sie sicher, dass Sie {{custName}} eine Sendung mit dem ID {{trackingNumber}} senden möchten?',
    confirmReceive:
      'Sind Sie sicher, dass sie von {{custName}} eine Sendung mit Bestellnummer {{trackingNumber}} will annehmen?',

    sendShipmentTitle: 'Senden',
    receiveShipmentTitle: 'Empfangen',

    scan: 'Scan',
    modal: {
      reworkSucces: 'Prozess rückgängig gemacht',
      succesStepScan: 'Prozess erfolgreich durchgeführt',
      printBarcode: 'Prozess ausführen und Etikett drucken',
      reprintBarcode: 'Barcode erneut drucken',
      reprintBarcodeConfirm: 'Muss Der letzte Barcode nachgedruckt werden?',
      reprint: 'Erneut drucken',

      performStep: 'Prozess ausführen',
      enter: 'Eingeben',
      scanTitle: 'Scan Artikeln',
      reworkList: 'Identifizierte Artikeln',
      rework: 'Abbrechen',
      reworkFromAppReason: 'Barcode-Scan über Picker-App rückgängig gemacht',
      resetErrorTitle: 'Fehler beim Abbrechen',
      resetErrorText: 'Unerklärter Serverfehler beim Abbrechen',
      productionRequestConfigError: 'Versandauftrag mit unbekannter konfiguration',
      succesBarcodeScan: 'Barcode erfolgreich verarbeitet',
      succesAllBarcodeScan: 'Alle Verpackungen von dieses Artikel sind identifiziert',

      accessReworkList: 'Liste identifizierte Artikeln',
      accessBarcodeScanning: 'Barcodes scannen',

      shipperInfo: 'Informationen zum Versender',
      scanPrinter: 'Drücker informationen',
      printername: 'Drückername',
      printerIP: 'Arbeitsstelle IP Adresse',
      printSucces: 'Drückerauftrag versandet an {{printer}}',
      printerClear: 'Bekannte Drucker-IP entfernt',

      shipTitle: 'Versand bestätigen',
      cancel: 'Abbrechen',
      ship: 'Senden',
      confirm: 'Bestätigen',
      barcode: 'Barcode',
      quantity: 'Mange',
      shipperName: 'Fahrername',
      shipperLicensePlate: 'Nummernschild',
    },
    leftToPick: 'To Pick',
    actualyPicked: 'Picked',

    documents: {
      label: 'Unterlagen',
      packingListRequired: 'Lieferschein',
      invoiceRequired: 'Rechnung',
      eur1Required: 'EUR1',
      cvoRequired: 'C/O',
    },
    tab: {
      shipmentLines: 'Artikeln',
      info: 'Info',
    },
    noCmrLines: 'Diese Sendung hat keine Lieferscheinregeln.',
    box: 'Box {{id}}',
    buyBox: 'Box',
    hiddenBoxes: '{{count}} boxes',
    error: {
      wrongArticle: 'Falsche Artikel.',
      alreadyScanned: 'Verpackung bereits gescannt.',
      alreadyShipped: 'Verpackung bereits in einem anderen pickorder enthalten.',
      tooManyProducts: 'Verpackung erhalt zu viel Produkten.',
      invalidQuantity: 'Unbekannter menge.',
    },
  },
  articleInfo: {
    clearBarcode: 'Barcode löschen',
    search: 'Suche nach Barcode',
    wrongCode: 'Barcode konnte im System nicht gefunden werden',
    notAllowed: 'Diese Arbeitsplatz dürfen diesen Barcode nicht überprüfen',
    error: 'Fehler bei der Suche nach dem Barcode',
  },
  common: {
    article: {
      field: {
        code: { label: 'Kode' },
        name: { label: 'Name' },
      },
    },
    shipmentLine: {
      field: {
        quantity: { label: 'Menge' },
      },
    },
    shipment: {
      field: {
        salesOrder: { label: 'Verkaufsauftrag' },
        shippingDate: { label: 'Versand Datum' },
        shippingName: { label: 'Fahrername' },
        shippingTime: { label: 'Versand Zeituhr' },

        shippingLicense: { label: 'Nummernschild' },

        freight: {
          label: 'Transportart',
          value: {
            road: 'Weg',
            air: 'Luftcargo',
            sea: 'Mehrfracht',
            parcel: 'Express',
          },
        },
        freightCompany: { label: 'Transportfirma' },
      },
    },
    cmrLine: {
      field: {
        mark: { label: 'Mark' },
        number: { label: 'Nummer' },
        method: { label: 'Verpackungsart' },
        nature: { label: 'Inhalt' },
        weight: { label: 'Gewicht' },
        cubage: { label: 'Kubic' },
      },
    },
    loading: 'In Versand',
    error: {
      somethingWentWrong: 'Unbekannter fehler.',
    },
  },
  warehouseTransfer: {
    overview: {
      title: 'Warehouse transfers',
    },
    field: {
      id: { label: 'ID' },
      description: { label: 'Description' },
      entryDate: { label: 'Entry Date' },
      plannedDeliveryDate: { label: 'Planned Delivery Date' },
      status: {
        label: 'Status',
        value: {
          draft: 'Draft',
          processed: 'Processed',
        },
      },
      source: {
        label: 'Source',
        value: {
          manual_entry: 'Manual entry',
          import: 'Import',
          transfer_advice: 'Transfer advice',
          web_service: 'Web service',
        },
      },
      warehouseFrom: {
        label: 'Warehouse From',
      },
      warehouseTo: {
        label: 'Warehouse To',
      }
    },
  },
}
