import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Button } from 'semantic-ui-react'
import { TargetNumberInput } from 'spider/semantic-ui/Target'
import Decimal from 'decimal.js'
import styled from 'styled-components';

// components
import { FullWidthTable } from 'component/MaterialPlan/FormStep';
import PrintButtonModal from 'component/PrintButtonModal'
import { ProductionRequestDrawingImage } from 'component/ProductionRequest/DrawingImage'
// end components

// helpers
import getGlobalValue from 'helpers/getGlobalValue'
import { humanReadable } from 'helpers/decimal'
import { onEnter } from 'helpers'
import { getSuperProductionRequest } from 'helpers/productionRequest';
// end helpers

// stores
import { ProductionRequest } from 'store/ProductionRequest'
import { Operator } from 'store/Operator'
// end stores


const CollectedSpan = styled.span`
  display: flex;
  min-width: 160px;
`


export default class SubBatches extends Component {
  /**
   * This form-field shows the sub-batches on a nest, and allows you to enter how many of a certain article type were actually collected.
   */
  static propTypes = {
    productionRequest: PropTypes.instanceOf(ProductionRequest).isRequired,
    targetProps: PropTypes.object,
    data: PropTypes.object,
    field: PropTypes.object,
    errors: PropTypes.object,
    errorsAtPath: PropTypes.func,
    onAddRef: PropTypes.func,
    onFocus: PropTypes.func,
    autoFocus: PropTypes.bool,
    index: PropTypes.number,
    operator: PropTypes.instanceOf(Operator),
  }


  getInstructions = async (productionRequest, batch, operator) => {
    const articleType = batch.batchType.articleType
    const exactShopOrder = productionRequest.productionOrder.exactShopOrder
    const exactGlobeProductionOrder = productionRequest.productionOrder.exactGlobeProductionOrder
    const erpOrderID = (!exactShopOrder.isNew && exactShopOrder.number) || (!exactGlobeProductionOrder.isNew && exactGlobeProductionOrder.productionOrderNumber)
    const now = new Date()
    const date = `${now.getFullYear()}-${now.getMonth()}-${now.getDate()}`
    const time = `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`

    const template = await getGlobalValue('sub_batches_print_label')
    const instructions = (
      template
        .replace(/\{\{operator_badge_id\}\}/g, operator.badgeId)
        .replace(/\{\{serial_number\}\}/g, batch.serialNumber)
        .replace(/\{\{erp_order_id\}\}/g, erpOrderID)
        .replace(/\{\{quantity\}\}/g, productionRequest.quantity)
        .replace(/\{\{batch_size\}\}/g, batch.quantity)
        .replace(/\{\{date\}\}/g, date)
        .replace(/\{\{time\}\}/g, time)
        .replace(/\{\{article_type_code\}\}/g, articleType.code)
        .replace(/\{\{article_type_name\}\}/g, articleType.name)
        .replace(/\{\{order_id\}\}/g, productionRequest.productionOrder.id)
        .replace(/\{\{[A-z]*\}\}/g, '')
    )

    return instructions
  }


  getImage(productionRequest) {
    return productionRequest.drawingImage || ''
  }


  onChangeNumberCollected(change, data, key, batch, onChange) {
    const formData = { ...data.form_data[key] }
    formData[batch.id] = Decimal.add(formData[batch.id], Decimal(change))
    onChange(formData)
  }


  render() {
    const { productionRequest, targetProps, data, field, errors, errorsAtPath, onAddRef, onFocus, autoFocus, index, operator } = this.props
    const key = field.id.toString()   // Passing the key through properties did not work, since it got garbage collected, so it is re-derived with the field here.
    const batches = productionRequest.batches.filter(batch => batch.subProductionRequestBatchLinks.length > 0)
    return (
      <FullWidthTable>
        <thead>
          <th>{t('formStepField.field.subBatches.image')}</th>
          <th>{t('formStepField.field.subBatches.articleCode')}</th>
          <th>{t('formStepField.field.subBatches.articleName')}</th>
          <th>{t('formStepField.field.subBatches.nested')}</th>
          <th>{t('formStepField.field.subBatches.collected')}</th>
          <th>{t('formStepField.field.subBatches.superProductionRequest')}</th>
        </thead>
        {
          batches.map((batch, i) => {
            const superProductionRequest = getSuperProductionRequest(productionRequest)
            const superProductionRequestExactShopOrder = superProductionRequest ? superProductionRequest.productionOrder?.exactShopOrder : null
            const superProductionRequestExactGlobeProductionOrder = superProductionRequest ? superProductionRequest.productionOrder?.exactGlobeProductionOrder : null
            return (
              <tr>
                <td>
                  <ProductionRequestDrawingImage productionRequest={productionRequest}/>
                </td>
                <td data-test-sub-batch-article-code={batch.id}>{batch.batchType.articleType.code}</td>
                <td data-test-sub-batch-article-name={batch.id}>{batch.batchType.articleType.name}</td>
                <td data-test-sub-batch-nested={batch.id}>{humanReadable(batch.quantity)}</td>
                <td>
                  <CollectedSpan>
                    <Button
                      icon='minus'
                      onClick={() => this.onChangeNumberCollected(-1, data, key, batch, targetProps.onChange)}
                      data-test-sub-batch-minus={batch.id}
                    />

                    <TargetNumberInput data-test-sub-batch-collected={batch.id} fluid style={{ minWidth:'80px' }}
                        {...targetProps}
                        autoFocus={i === 0 && autoFocus}
                        contentProps={{ // This is for the auto focusing on enter press
                          ref: (ref) => {
                            if (ref) {
                              onAddRef(`field_${index}_${i}`, ref)
                            }
                          },
                        }}
                        value={data.form_data[key] ? humanReadable(data.form_data[key][batch.id]?.toString() ?? '') : ''}
                        onChange={(value) => {
                          const formData = { ...data.form_data[key] }
                          formData[batch.id] = Decimal(value === '' ? 0 : value)
                          targetProps.onChange(formData)
                        }}
                        errors={errorsAtPath(errors, 'form_data', key, batch.id.toString()).map(error => error.message)}
                        onKeyPress={onEnter(() => onFocus(i + 1))}
                    />
                    <Button
                      icon='plus'
                      onClick={() => this.onChangeNumberCollected(1, data, key, batch, targetProps.onChange)}
                      data-test-sub-batch-plus={batch.id}
                    />
                  </CollectedSpan>
                </td>
                <td data-test-sub-batch-production-order={batch.id}>
                  <PrintButtonModal data-test-print-button
                    key={key}
                    getInstructions={() => this.getInstructions(productionRequest, batch, operator)}
                    primary={false}
                    style={{ margin: '0 0 0 1rem !important' }}
                    copies={1}
                    alterButtons
                  />
                  {!productionRequest.productionOrder.isNew && productionRequest.productionOrder.getLabel()}
                  {superProductionRequestExactShopOrder && !superProductionRequestExactShopOrder.isNew && superProductionRequestExactShopOrder.getLabel()}
                  {superProductionRequestExactGlobeProductionOrder && !superProductionRequestExactGlobeProductionOrder.isNew && superProductionRequestExactGlobeProductionOrder.getLabel()}
                </td>
              </tr>
            )
          })
        }
      </FullWidthTable>
    )
  }
}
